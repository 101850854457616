/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AnswerRecord } from '../models/AnswerRecord';
import { ItemRating } from '../models/ItemRating';
import { HttpFile } from '../http/http';

export class ApiRoundItemData {
    'record': Array<AnswerRecord>;
    'failureCount': number;
    'successCount': number;
    'complete': boolean;
    'itemHistoryId': number;
    'difficultyHint'?: ItemRating;
    'testDefinition': number;
    'itemKey': string;
    'unitId': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "record",
            "baseName": "Record",
            "type": "Array<AnswerRecord>",
            "format": ""
        },
        {
            "name": "failureCount",
            "baseName": "FailureCount",
            "type": "number",
            "format": "double"
        },
        {
            "name": "successCount",
            "baseName": "SuccessCount",
            "type": "number",
            "format": "double"
        },
        {
            "name": "complete",
            "baseName": "Complete",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "itemHistoryId",
            "baseName": "ItemHistoryId",
            "type": "number",
            "format": "double"
        },
        {
            "name": "difficultyHint",
            "baseName": "DifficultyHint",
            "type": "ItemRating",
            "format": ""
        },
        {
            "name": "testDefinition",
            "baseName": "TestDefinition",
            "type": "number",
            "format": "double"
        },
        {
            "name": "itemKey",
            "baseName": "ItemKey",
            "type": "string",
            "format": ""
        },
        {
            "name": "unitId",
            "baseName": "UnitId",
            "type": "number",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return ApiRoundItemData.attributeTypeMap;
    }

    public constructor() {
    }
}



