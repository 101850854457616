import { useContext, useEffect, useState } from "react"
import { AppCallbacks, TextStyle } from "../App"
import { db } from "../Database/db"
import { ProcessedTextDisplay } from "../Displays/ProcessedTextDisplay"
import { ProcessedText } from "../Types/ProcessedText"
import { ProcessTextView } from "./ProcessTextView"
import { TextStructure } from "../Database/TextStore"
import { TextStructureDisplay } from "../Displays/TextStructureDisplay"
import { GenerateTextView } from "./GenerateTextView"
import { useQuery } from "@tanstack/react-query"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { ContentTile } from "../Components/ModeTile"
import { Collapse } from "../Components/Collapse"
import { TextEntry } from "../api"

export const ManageTextsView: React.FC<AppCallbacks> = props => {

    enum ViewState {
        Main,
        Process,
        Generate
    }

    const api = useContext(ApiContext)

    const [loaded, setLoaded] = useState(false)
    const [viewState, setViewState] = useState(ViewState.Main)

    const [texts, setTexts] = useState<TextStructure[]>([])

    const TextMenuConfig = [
        {
            Label: 'Generate',
            OnClick: () => { setViewState(ViewState.Generate) }
        },
        {
            Label: "Process Text",
            OnClick: () => { setViewState(ViewState.Process) }
        },
        {
            Label: "Exit",
            OnClick: () => { props.OnExit() }
        }
    ]

    useEffect(() => {
        if (loaded) {
            props.SetMenu(TextMenuConfig)
        }
    }, [loaded])

    const { isPending, error, data } = useQuery({
        queryKey: ['texts'],
        queryFn: () => api.GetTexts().then(UnwrapApiCall).then(r => { setLoaded(true); return r }),
        gcTime: 0,
        staleTime: Infinity
    })
    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        {viewState == ViewState.Main && <>
            {!loaded && <><TextStyle>Loading...</TextStyle></>}
            {loaded && <>
                <OrganiseTextsIntoCategoriesLoader texts={data} />
            </>}
        </>}
        {viewState == ViewState.Process && <ProcessTextView OnExit={() => { setViewState(ViewState.Main); props.SetMenu(TextMenuConfig) }} SetMenu={props.SetMenu} />}
        {viewState == ViewState.Generate && <GenerateTextView OnExit={() => { setViewState(ViewState.Main); props.SetMenu(TextMenuConfig) }} SetMenu={props.SetMenu} />}
    </>
}

export const OrganiseTextsIntoCategoriesLoader: React.FC<{ texts: TextEntry[] }> = props => {
    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['textcategorylinks'],
        queryFn: () => api.GetTextCategoryLinks().then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>


    console.log(data)
    const texts: Record<string, TextEntry> = {}
    const categories: Record<string, TextEntry[]> = {}

    props.texts.forEach(t => {
        texts[t.ref] = t
    })

    data.forEach(l => {
        if (categories[l.category] == undefined) {
            categories[l.category] = []
        }

        categories[l.category].push(texts[l.ref]!!)
    })

    console.log(categories)

    return <>
        <Collapse Label="default">
            {categories["default"].map(t => {
                return <TextStructureDisplay Label={t.label} Ref={t.ref!!} />
            })}
        </Collapse>
        {Object.keys(categories).filter(k => k != "default").map(k => {
            return <Collapse Collapsed={true} Label={k}>
                {categories[k].map(t => {
                    return <TextStructureDisplay Label={t.label} Ref={t.ref!!} />
                })}
            </Collapse>
        })}
    </>
}
