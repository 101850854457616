import { useContext, useState } from "react"
import { ItemData } from "../api"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { useQuery } from "@tanstack/react-query"
import { TextStyle } from "../App"
import { PlayTextAudio } from "./PlayTextAudio"
import { ContentTile } from "./ModeTile"
import { OptionsIcon } from "../Icons/OptionsIcon"
import { AnnotatedTextDisplay, OnSelectionPopup } from "../Displays/TextStructureDisplay"
import { BreakIcon } from "../Icons/BreakIcon"
import { Modal } from "./Modal"
import { LoadDictionaryQuery } from "../Views/DictionaryView"

export const ItemExamplesView: React.FC<{ Data: { Item: ItemData } }> = props => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: [`itemExamples`],
        queryFn: () => api.SentencesWithWord(props.Data.Item.item).then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <ContentTile>
        <ColumnLayout>
            {data.map((example, index) => (
                <ExampleSentenceDisplay key={index} text={example.text} sentenceRef={example.ref} />
            ))}
        </ColumnLayout>
    </ContentTile>
}

export const ExampleSentenceDisplay: React.FC<{ text: string, sentenceRef: string, segments?: string[], autoPlay?: boolean, autoProgress?: boolean, OnAudioFinish?: () => void }> = props => {

    const [displayPrimaryText, setDisplayPrimaryText] = useState(false)
    const [showQueryView, setShowQueryView] = useState(false)

    const [selectionObject, setSelectionObject] = useState<{ text: string, top: number, left: number } | null>(null)

    const OnSelection = () => {
        const selection = window.getSelection()
        document.onselectionchange = () => {
            setSelectionObject(null)
        }

        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            console.log(range)
            if (selection.toString() == "") {
                setSelectionObject(null)
            } else {
                console.log({
                    text: selection.toString(),
                    top: range.startContainer.parentElement?.offsetTop,
                    left: rect.left
                });
                setSelectionObject({
                    text: selection.toString(),
                    top: 36 + (range.startContainer.parentElement?.offsetTop ?? 0),
                    left: rect.left
                })

            }
        }
    }

    return <ColumnLayout style={{ paddingBottom: 24, borderBottom: "1px solid #00000020" }}>
        {selectionObject && <OnSelectionPopup Close={() => setSelectionObject(null)} Text={selectionObject.text} top={selectionObject.top} left={selectionObject.left} />}
        {props.segments && <AnnotatedTextDisplay Active={false} Text={props.text} Segments={props.segments} />}
        {!props.segments && <TextStyle align='left' onTouchEnd={OnSelection} onMouseUp={OnSelection} size={2}>{props.text}</TextStyle>}
        <RowLayout style={{ opacity: 0.3, alignItems: "center", justifyContent: "space-between" }}>

            <PlayTextAudio AutoPlay={props.autoPlay} AutoProgress={props.autoProgress} Text={props.text} OnAudioFinish={props.OnAudioFinish} />
            <div onClick={() => setShowQueryView(!showQueryView)}>
                <BreakIcon />
            </div>
            <div onClick={() => setDisplayPrimaryText(!displayPrimaryText)}>
                <OptionsIcon />
            </div>
        </RowLayout>
        {displayPrimaryText && <LoadPrimarySentenceTextDisplay sentenceRef={props.sentenceRef} />}
        {showQueryView && <Modal OnClose={() => setShowQueryView(false)}>
            <LoadDictionaryQuery query={props.text} />
        </Modal>}
    </ColumnLayout>

}

export const LoadPrimarySentenceTextDisplay: React.FC<{ sentenceRef: string }> = props => {
    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: [`exampleSentenceDisplay`, props.sentenceRef],
        queryFn: () => api.LoadSentencePrimaryText(props.sentenceRef).then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <TextStyle size={2}>{data}</TextStyle>
}   
