import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Message, MessageSenderEnum, MessageStateEnum, PartialTextStructure, ProcessTextRequest } from '../api';
import { Modal } from '../Components/Modal';
import { ContentTile, StyledButton } from '../Components/ModeTile';
import { OptionsIcon } from '../Icons/OptionsIcon';
import { ColumnLayout, RowLayout } from '../Styles/Styles';

import { useQuery } from '@tanstack/react-query';
import { TextHelperModal } from './TextHelperView';
import { ApiContext } from '../Contexts/Api/ApiContext';
import { ResponseType } from '../Contexts/Api/RootApiContext';
import { TextStyle } from '../App';
import { ParseTextIntoPartialChapter } from '../Utility/ParseTextIntoProcessTextRequest';
import { StoreProcessedTextStructure } from '../Utility/ProcessedTextFromSegmentedOutput';

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px - 48px - 24px - 24px - 24px - 50px - 24px - 24px - 2px);
  overflow-y: auto;
  width: calc(100% - 48px);
  background-color: #E6A4B4;
  gap: 24px;
  padding: 24px;
  border-radius: 24px;

  > :first-child {
    margin-top: auto !important;
    /* use !important to prevent breakage from child margin settings */
}
`

const MessageBubble = styled.div<{ position: 'start' | 'end' }>`
  width: 50%;
  padding: 24px;
  background-color: #F5EEE6;
  text-align: left;
  align-self: ${props => props.position};
  border-radius: 24px;
  font-family: Consolas;
  font-size: 0.9rem;
  display: flex;
  flex-direction: row;

`

const MessageInput = styled.input`
  height: 50px;
  width: calc(100% - 48px - 100px);
  border: none;
  background-color: #F5EEE6;
  font-family: Consolas;
  font-size: 0.9rem;
  text-align: right;
  padding-right: 24px;
  padding-left: 24px;
  border-radius: 24px;
`

const LoadingAnimation: React.FC = props => {
    return <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
}


const MessageBubbleWrapper: React.FC<{ msg: Message }> = props => {

    const msg = props.msg;

    const [showOptions, setShowOptions] = useState(false)
    return <>
        <MessageBubble position={msg.sender == MessageSenderEnum.User ? 'end' : 'start'}>
            <div style={{ flexBasis: '95%' }}>
                {msg.state == MessageStateEnum.Loading && <LoadingAnimation />}
                {msg.content && msg.content.split("\n").map(t => <div>{t}</div>)}

            </div>
            {msg.sender && msg.state == MessageStateEnum.Sent && msg.sender == MessageSenderEnum.Server && <div style={{ flexBasis: '5%', justifyContent: 'flex-end' }}>
                <div onClick={() => setShowOptions(true)}>
                    <OptionsIcon />
                </div>
            </div>}
            {showOptions && <TextHelperModal Ref={''} Text={msg.content!!} OnClose={() => { setShowOptions((false)) }} />}
        </MessageBubble>
    </>
}

const MessageManager: React.FC<{ Messages: Message[] }> = props => {
    const bottomRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        // 👇️ Scroll to the bottom every time messages change
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [props.Messages]);


    return <>
        <MessageContainer>
            {props.Messages.map(msg => {
                return <MessageBubbleWrapper msg={msg} />
            })}
            <div ref={bottomRef} />

        </MessageContainer>
    </>
}

const InputText: React.FC<{ Disable: boolean, OnSubmit: (msg: string) => any }> = props => {
    const [message, setMessage] = useState("")

    const submit = (e: any) => {
        if (e.key == "Enter") {
            props.OnSubmit(message)
            setMessage("")
        }
    }

    return <> 
        <RowLayout>
            <MessageInput disabled={props.Disable} value={message} onChange={(e) => { setMessage(e.target.value) }} onKeyDown={submit} />
            <StyledButton onClick={() => { props.OnSubmit(message); setMessage("") }}>Send</StyledButton>
        </RowLayout>
    </>
}

const BodyStyle = styled.div`
background-color: #E6A4B4bf;
font-size: 24px;
width: 100%;
`



export const ChatView : React.FC<{syncMessages?: (messages: Message[]) => void}> = (props) => {


    const api = useContext(ApiContext)

    const [messages, setMessages] = useState<Message[]>([])
    const [disableInput, setDisableInput] = useState(false)

    const [saveDialog, setSaveDialog] = useState(false)
    const [chatLabel, setChatLabel] = useState("")
    const [saved, setSaved] = useState(false)

    const sendMsg = (msg: string) => {
        messages.push({ sender: MessageSenderEnum.User, state: MessageStateEnum.Sent, content: msg })
        messages.push({ sender: MessageSenderEnum.Server, state: MessageStateEnum.Loading })
        setDisableInput(true)
        setMessages([...messages])

        api.AssistantChat({ messages: messages }).then(
            async (response) => {
                if (response.responseType == ResponseType.Success) {
                    var last = messages.pop()!!
                    last.content = response.object
                    last.state = MessageStateEnum.Sent
                    setMessages([...messages, last])
                    setDisableInput(false)
                    props.syncMessages?.([...messages, last])

                }
            }
        )

    }

    const saveChatAsTextStructure = () => {
        const text = messages.map(m => m.content).join("\n\n")
        const partialChapter = ParseTextIntoPartialChapter(text)
        partialChapter.fullText = text;
        const processTextRequest = new ProcessTextRequest()
        processTextRequest.text = new PartialTextStructure()
        processTextRequest.text.fullText = text;
        processTextRequest.text.chapters = [partialChapter]
        processTextRequest.label = chatLabel

        api.ProcessText(processTextRequest).then((response) => {
            if (response.responseType == ResponseType.Success) {
                const ProcessedTextStructure = response.object
                // StoreProcessedTextStructure(chatLabel, ProcessedTextStructure)
            }
        }).then(() => {
            setSaved(true)
        })

    }

    return (
        <BodyStyle>
            {saveDialog && <>
                <Modal OnClose={() => setSaveDialog(false)}>
                    <ContentTile>
                        <ColumnLayout>
                            <RowLayout>
                                <TextStyle>Label</TextStyle>
                                <input onChange={(e) => setChatLabel(e.target.value)} type="text" />
                            </RowLayout>
                            <StyledButton disabled={saved} onClick={() => saveChatAsTextStructure()}>Save</StyledButton>
                            {saved && <TextStyle>Saved!</TextStyle>}
                        </ColumnLayout>
                    </ContentTile>
                </Modal>
            </>}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 24, padding: 24 }}>
                <RowLayout>
                    <StyledButton onClick={() => setSaveDialog(true)}>Save chat</StyledButton>
                </RowLayout>
                <MessageManager Messages={messages} />
                <InputText Disable={disableInput} OnSubmit={sendMsg} />
            </div>
        </BodyStyle>
    );
}
