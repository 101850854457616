import { useContext, useState } from "react"
import { TextStyle } from "../App"
import { ExtraUnitsDefinitionType, SetMenu, State } from "../Loaders/LanguageDefinitionLoader"
import { UnitDefinition, UnitDefinitionMapType } from "../Config/UnitDefinitionMap"
import { AnswerState, ItemHistoryElement } from "../Database/HistoryState"
import { ColumnLayout, InnerTile } from "../Styles/Styles"
import { Data, OnExit } from "../Types/Interfaces"
import { SetNewHistoryFromRoundRecord } from "../Utility/SetHistoryFromRoundItemData"
import { LanguageDefinition } from "../Types/LanguageDefinition"
import { LanguageConfiguration, NextItemContainer } from "../Config/LanguageDefinitionConfiguration"
import { TextRefMap, TextRefType } from "../Database/TextStore"
import { ApiRoundItemData, ApiRoundRecord, ItemData, ItemHistory } from "../api"
import { AnswerRecord, NewRoundItemData, NewRoundView, RoundControllerParams, RoundRecord } from "./NewRoundView"
import { ItemTypeTestConfigurationMap } from "../Config/Language/Zh/Types"
import { UnitTestInterfaceConfiguration } from "../Config/Language/Zh/Config/UnitTestInterfaceConfig"
import { ToLowerCaseKeys } from "../Utility/ToLowerCaseKeys"
import { ApiContext } from "../Contexts/Api/ApiContext"

interface LearnViewState {
    UseSubUnit: boolean
    SubUnitId: number

    RetryRound: boolean
    RoundRecordId: number

}

export type NextItemContainer_ = {
    New: ItemHistory[],
    Review: ItemHistory[]
}

export type ItemDataRecord = Record<string, ItemData>

interface NewLearnModeData {
    // LanguageConfiguration: LanguageConfiguration<any>
    // ExtraLanguageDefinition: ExtraUnitsDefinitionType
    // TextRefMap: TextRefMap,
    NextUnits: NextItemContainer_
    ItemTypeTests: ItemTypeTestConfigurationMap
    ItemDataRecord: Record<string, ItemData>
    UnitTestInterfaces: UnitTestInterfaceConfiguration
    // RoundRecords: RoundRecord[],
    Params: RoundControllerParams,

    SendToServer: boolean,
}


enum LearnModeState {
    Review,
    Results
}

interface Retry {
    Retry: (RoundRecord: RoundRecord) => any
}

const ViewRoundItemData: React.FC<Data<{ UnitDefinition: UnitDefinition<any>, RoundItem: NewRoundItemData }>> = props => {
    const ri = props.Data.RoundItem

    // var item = props.Data.UnitDefinition.Items.find(i => props.Data.UnitDefinition.GetItemId(i) == ri.UnitItemKey)


    var successPercent = (ri.SuccessCount / ri.Record.length) * 100

    var totalTime = ri.Record.reduce((prev: number, cur: AnswerRecord) => prev + cur.TimeToAnswer, 0)
    var avgTime = totalTime / ri.Record.length

    return <InnerTile>
        {/* <TextStyle size={3}>{props.Data.UnitDefinition.GetItemDisplay(item)}</TextStyle> */}
        <TextStyle>{successPercent.toFixed(0)}% ({ri.SuccessCount}/{ri.FailureCount})</TextStyle>
        <TextStyle>avg time : {avgTime}ms</TextStyle>
    </InnerTile>
}

export const NewLearnView: React.FC<OnExit & SetMenu & Data<NewLearnModeData>> = props => {

    const api = useContext(ApiContext)

    const [pageState, setPageState] = useState(LearnModeState.Review)

    const [roundItemData, setRoundItemData] = useState<NewRoundItemData[]>([])
    // const [roundGuid, setRoundGuid] = useState<string | undefined>(undefined)
    const [roundRecord, setRoundRecord] = useState<RoundRecord | undefined>(undefined)

    const [setResultsMenu, setSetResultsMenu] = useState(false)

    const ResetMyState = () => {
        setPageState(LearnModeState.Review)
        setRoundItemData([])
        setSetResultsMenu(false)
    }

    const RetryMenu = [
        {
            Label: "Retry",
            OnClick: () => {
                ResetMyState()
                var mappedRoundData = roundItemData.map(r => {
                    r.Complete = false
                    r.SuccessCount = 0
                    r.FailureCount = 0
                    r.Record = []

                    return r
                })

                roundRecord!!.Items = mappedRoundData

                // props.Retry(roundRecord!!)
            }
        },
        {
            Label: "Continue",
            OnClick: () => props.OnExit()
        }
    ]

    if (!setResultsMenu && pageState == LearnModeState.Results) {
        props.SetMenu(RetryMenu)

        setSetResultsMenu(true)
    }
    var roundGuid = undefined



    return <>
        {pageState == LearnModeState.Review && <>
            <NewRoundView
                OnExit={props.OnExit}
                Data={{
                    NextUnits: props.Data.NextUnits,
                    ItemTypeTests: props.Data.ItemTypeTests,
                    UnitTestInterfaces: props.Data.UnitTestInterfaces,
                    ItemDataRecord: props.Data.ItemDataRecord,
                    Params: props.Data.Params,
                    // SetData: SetHistoryFromRoundRecord,
                    // SetData: SetNewHistoryFromRoundRecord,
                    SetData: (RoundRecord: RoundRecord) => { /* send to server */
                        if (props.Data.SendToServer) {
                            var apiRoundRecord : ApiRoundRecord = ToLowerCaseKeys(RoundRecord)
                            api.FinishRound(apiRoundRecord)
                        }
                    },
                    OnComplete: function (roundRecord: RoundRecord) {

                        setPageState(LearnModeState.Results)
                        setRoundItemData(roundRecord.Items)
                        //setRoundGuid(roundRecord.RoundGuid)
                        setRoundRecord(roundRecord)
                    },
                    OnClose: function () {
                        props.OnExit()
                    },
                }}
            />
        </>}
        {pageState == LearnModeState.Results &&

            <>
                <div style={{ textAlign: 'center', paddingBottom: 12, }}>
                    <ColumnLayout>
                        <TextStyle weight='bold' case='uppercase'>Results</TextStyle>
                        <TextStyle>Reviews: {roundItemData.length}</TextStyle>
                        {/* <TextStyle>Correct: {'fix me'}</TextStyle> */}
                        {
                            ((roundItemData: NewRoundItemData[]) => {
                                var noErrors = 0
                                var oneError = 0
                                var moreErrors = 0

                                var total = roundItemData.length

                                roundItemData.map(ri => {
                                    if (ri.Record.every(r => r.Record == AnswerState.Success)) {
                                        noErrors += 1
                                    }
                                    if (ri.Record.filter(r => r.Record == AnswerState.Failure).length == 1) {
                                        oneError += 1
                                    }
                                    if (ri.Record.filter(r => r.Record == AnswerState.Failure).length > 1) {
                                        moreErrors += 1
                                    }
                                })

                                var outcome = noErrors > oneError + moreErrors ? true : false

                                return <>
                                    <TextStyle weight='bold'>Outcome: {outcome ? "✅" : "❌"}</TextStyle>
                                    <TextStyle>✔ No errors: {noErrors} ({((noErrors / total) * 100).toFixed(2)}%)</TextStyle>
                                    <TextStyle>1️⃣ One error: {oneError} ({((oneError / total) * 100).toFixed(2)}%)</TextStyle>
                                    <TextStyle>❌ Errors: {moreErrors} ({((moreErrors / total) * 100).toFixed(2)}%)</TextStyle>
                                </>
                            })(roundItemData)
                        }
                        {/* <TextStyle>RoundGuid {roundGuid}</TextStyle>
                        <TextStyle>RoundRecord {roundRecord?.Id}</TextStyle> */}
                        {/* <TextStyle>RoundItems: {roundItemData.length}</TextStyle> */}
                        {
                            ((roundItemData: NewRoundItemData[]) => {
                                var total = 0
                                var entries = 0

                                roundItemData.map(ri => {
                                    total += ri.Record.reduce((acc, item) => { return acc + item.TimeToAnswer }, 0)
                                    entries += ri.Record.length
                                })

                                var avg = total / entries

                                return <>
                                    <TextStyle>Total time: {total / 1000}s</TextStyle>
                                    <TextStyle>Average time: {avg.toFixed(2)}ms</TextStyle>
                                </>
                            })(roundItemData)
                        }
                    </ColumnLayout>
                </div>
                <div style={{ flexDirection: 'row', flexGrow: 1, alignContent: 'center', width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 24 }}>

                    {/* {roundItemData.map(ri => {

                        return <ViewRoundItemData Data={{ UnitDefinition: props.Data.Unit, RoundItem: ri }} />

                    })} */}
                </div>
            </>}


    </>

}
