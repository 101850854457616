import React, { useContext, useEffect, useState } from 'react';
import { ColumnLayout, InnerTile, RowLayout } from '../Styles/Styles';
import { ContentTile, StyledButton } from '../Components/ModeTile';
import { TextStyle } from '../App';
import { SentenceSearchKey } from '../api/models/SentenceSearchKey';
import { Message, SentenceKeyLastSeen, SentenceSearchType, SentenceSelectionMethod, SentenceSelectionQuery } from '../api';
import { UnwrapApiCall } from '../Contexts/Api/DefaultApiContext';
import { useQuery } from '@tanstack/react-query';
import { ApiContext } from '../Contexts/Api/ApiContext';
import { Modal } from '../Components/Modal';
import { DictionaryQueryTile } from '../Displays/ProcessedTextDisplay';
import { AnnotatedTextDisplay } from '../Displays/TextStructureDisplay';
import { ChatView } from './ChatView';
import { DictionaryView } from './DictionaryView';
import { ActionRecord } from '../api/models/ActionRecord';
import { ActionRecordAction } from '../api/models/ActionRecordAction';
import { NotesView } from './NotesView';

export const WriteSentencesView: React.FC = () => {

    const [showItemData, setShowItemData] = useState(false)

    const [count, setCount] = useState(10)
    const [baseUnits, setBaseUnits] = useState<SentenceSearchKey>(SentenceSearchKey.New)
    const [lastSeen, setLastSeen] = useState<SentenceKeyLastSeen>(SentenceKeyLastSeen.Today)

    const [hideParameters, setHideParameters] = useState(false)
    const ShowParameters = () => {
        setHideParameters(false)
    }
    const Show = () => {
        setHideParameters(true)
        setShowItemData(true)
    }

    return (
        <ContentTile>
            {!hideParameters && <ColumnLayout>
                <RowLayout>
                    <TextStyle>Count</TextStyle>
                    <input type="number" value={count} onChange={(e) => setCount(e.target.value as unknown as number)} />
                </RowLayout>
                <RowLayout>
                    <TextStyle>Search Key</TextStyle>
                    <select value={baseUnits} onChange={(e) => setBaseUnits(e.target.value as unknown as SentenceSearchKey)}>
                        <option value={SentenceSearchKey.New}>New</option>
                        <option value={SentenceSearchKey.Reviewed}>Reviewed</option>
                        <option value={SentenceSearchKey.Lapsed}>Lapsed</option>
                        <option value={SentenceSearchKey.Custom}>Custom</option>
                    </select>
                </RowLayout>

                <RowLayout>
                    <TextStyle>Last Seen</TextStyle>
                    <select value={lastSeen} onChange={(e) => setLastSeen(e.target.value as unknown as SentenceKeyLastSeen)}>
                        <option value={SentenceKeyLastSeen.Today}>Today</option>
                        <option value={SentenceKeyLastSeen.Yesterday}>Yesterday</option>
                        <option value={SentenceKeyLastSeen.ThisPeriod}>This period</option>
                        <option value={SentenceKeyLastSeen.LastPeriod}>Last period</option>
                        <option value={SentenceKeyLastSeen.AnyTime}>Any time</option>
                    </select>
                </RowLayout>


                <StyledButton onClick={Show}>Search</StyledButton>
            </ColumnLayout>}

            {hideParameters && <RowLayout><StyledButton onClick={ShowParameters}>Show Parameters</StyledButton></RowLayout>}
            {showItemData && <LoadSearchKeysDisplay count={count} baseUnits={baseUnits} lastSeen={lastSeen} />}


        </ContentTile >
    );
};

const LoadSearchKeysDisplay: React.FC<{ count: number, baseUnits: SentenceSearchKey, lastSeen: SentenceKeyLastSeen }> = (props) => {
    const api = useContext(ApiContext)

    const [refreshToken, setRefreshToken] = useState(0)

    const { isPending, error, data } = useQuery({
        queryKey: ["search-keys", props.baseUnits, props.lastSeen, refreshToken],
        queryFn: () => {
            const query: SentenceSelectionQuery = {
                selectionMethod: SentenceSelectionMethod.Recent,
                count: 0,
                baseUnits: props.baseUnits,
                lastSeen: props.lastSeen,
                sentenceType: SentenceSearchType.AllKnown,
                keyInput: [],
                gradeQuery: 0
            }
            return api.SearchKeys(query).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ColumnLayout>
            <RowLayout>
                <WriteSentencesInterface count={props.count} keys={data} />
            </RowLayout>

            <RowLayout>
                <StyledButton onClick={() => { setRefreshToken(refreshToken + 1) }}>Refresh</StyledButton>
            </RowLayout>
        </ColumnLayout>
    </>
}

const WriteSentencesInterface: React.FC<{ count: number, keys: string[] }> = (props) => {

    const [selectedKeys, setSelectedKeys] = useState<string[]>([])

    const [current, setCurrent] = useState(0)

    useEffect(() => {
        // Create copy of keys array to avoid modifying original
        const keysCopy = [...props.keys]

        // Shuffle array using Fisher-Yates algorithm
        for (let i = keysCopy.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [keysCopy[i], keysCopy[j]] = [keysCopy[j], keysCopy[i]]
        }

        // Take first n elements where n is count
        setSelectedKeys(keysCopy.slice(0, props.count))
    }, [props.keys, props.count])

    console.log(selectedKeys)
    console.log(current)
    console.log(selectedKeys[current])

    return <>
        {selectedKeys.length > 0 && <>
            {current < selectedKeys.length && <WriteSentencesTile next={() => { setCurrent(current + 1) }} word={selectedKeys[current]} />}
            {current >= selectedKeys.length && <>
                <TextStyle>Finished</TextStyle>
            </>}
        </>}
    </>
}

interface WriteSentenceAttemptMetadata {
    index: number,
    sentence: string,
    difficulty?: number,
    correct?: boolean,
    intendedMeaning?: string
    help?: string
    chatRecords?: Message[][]
}

interface WriteSentenceActionMetadata {
    sentences: WriteSentenceAttemptMetadata[]
}

export const WriteSentencesTile: React.FC<{ next: () => void, word: string }> = (props) => {
    const api = useContext(ApiContext);

    const [sentence, setSentence] = useState('')
    const [submitted, setSubmitted] = useState(false)

    const [showHelpModal, setShowHelpModal] = useState(false)
    const [intendedMeaning, setIntendedMeaning] = useState('')
    const [submittedHelp, setSubmittedHelp] = useState(false)

    const [showDictionaryModal, setShowDictionaryModal] = useState(false)
    const [showChatModal, setShowChatModal] = useState(false)

    const [recordMetadata, setRecordMetadata] = useState<Record<string, WriteSentenceAttemptMetadata>>({})
    const [attemptIndex, setAttemptIndex] = useState(0)

    const [currentMessages, setCurrentMessages] = useState<Message[]>([])

    const [showNotesModal, setShowNotesModal] = useState(false)

    const [startTime, setStartTime] = useState(new Date())

    const updateSentence = (value: string) => {
        setSentence(value)
        setSubmitted(false)
        setSubmittedHelp(false)
        setIntendedMeaning('')
    }

    const clearHelpState = () => {
        setShowHelpModal(false)
    }

    const setSentenceCorrectForAttempt = (sentence: string, index: number, correct: boolean) => {
        console.log(sentence, index, correct)
        setRecordMetadata(prev => ({
            ...prev,
            [sentence]: {
                ...(prev[sentence] || {}),  // Use existing metadata if available
                sentence,
                index,
                correct
            }
        }));
    }

    const setSentenceDifficultyForAttempt = (sentence: string, index: number, difficulty: number) => {
        setRecordMetadata(prev => ({
            ...prev,
            [sentence]: {
                ...(prev[sentence] || {}),  // Preserve existing properties
                sentence,
                index,
                difficulty
            }
        }));
    }

    const setHelpDetailsForAttempt = (sentence: string, index: number, help: string, intendedMeaning?: string) => {
        setRecordMetadata(prev => ({
            ...prev,
            [sentence]: {
                ...(prev[sentence] || {}),  // Merge with existing metadata
                sentence,
                index,
                intendedMeaning,
                help
            }
        }));
    }

    const submitAttempt = () => {
        setAttemptIndex(attemptIndex + 1)
        setSubmitted(true)
    }

    const next = () => {

        let sentenceCount = Object.entries(recordMetadata).length

        if (sentenceCount > 0) {
            let metadata: WriteSentenceActionMetadata = {
                sentences: []
            }

            for (const [index, sentence] of Object.entries(recordMetadata)) {
                metadata.sentences.push({
                    index: sentence.index,
                    sentence: sentence.sentence,
                    difficulty: sentence.difficulty,
                    correct: sentence.correct,
                    intendedMeaning: sentence.intendedMeaning,
                    help: sentence.help,
                    chatRecords: sentence.chatRecords ?? []
                })
            }

            const finishedTime = new Date()

            const record: ActionRecord = {
                action: ActionRecordAction.WriteSentence,
                timeBegan: startTime,
                timeFinished: finishedTime,
                timeElapsed: (finishedTime.getTime() - startTime.getTime()) / 1000,
                count: 1,
                groupId: '',
                ref: props.word,
                metadata: metadata
            };


            console.log(record)
            api.ProcessActionRecords([record]).catch(error => {
                console.error('Error recording action:', error);
            }).then(resetState);

        } else {
            resetState()
        }


    }

    const resetState = () => {
        setSentence('')
        setSubmitted(false)
        setSubmittedHelp(false)
        setIntendedMeaning('')
        setAttemptIndex(0)
        setRecordMetadata({})
        props.next()
    }

    const setMessages = (messages: Message[]) => {
        console.log(messages)
        setCurrentMessages(messages)
    }

    const onCloseChat = () => {

        if (currentMessages.length == 0) {
            setCurrentMessages([])
            setShowChatModal(false)
            return
        }

        let newMetadata = { ...recordMetadata }
        let newSentenceMetadata = newMetadata[sentence]

        if (newSentenceMetadata == undefined) {
            newSentenceMetadata = {
                index: attemptIndex,
                sentence: sentence,
                chatRecords: []
            }
        }

        if (newSentenceMetadata.chatRecords == undefined) {
            newSentenceMetadata.chatRecords = []
        }

        newSentenceMetadata.chatRecords.push(currentMessages)

        newMetadata[sentence] = newSentenceMetadata

        setRecordMetadata(newMetadata)

        setCurrentMessages([])
        setShowChatModal(false)
    }

    return <>
        {showDictionaryModal && <Modal OnClose={() => { setShowDictionaryModal(false) }}>
            <ContentTile>
                <DictionaryView query={props.word} />
            </ContentTile>
        </Modal>}
        {showChatModal && <Modal OnClose={onCloseChat}>
            <ContentTile>
                <ChatView key={props.word} syncMessages={setMessages} />
            </ContentTile>
        </Modal>}
        {showNotesModal && <Modal OnClose={() => { setShowNotesModal(false) }}>
            <ContentTile>
                <NotesView wordKey={props.word} />
            </ContentTile>
        </Modal>}
        {showHelpModal && <Modal OnClose={() => { clearHelpState() }}>
            <ContentTile>
                <ColumnLayout>
                    <TextStyle size={1.5}>{sentence}</TextStyle>
                    <TextStyle>Intended Meaning:</TextStyle>
                    <textarea value={intendedMeaning} onChange={(e) => setIntendedMeaning(e.target.value)} style={{ fontSize: '1.5rem', width: '100%', height: '60px' }} />
                    <StyledButton onClick={() => { setSubmittedHelp(true) }}>Submit</StyledButton>
                </ColumnLayout>
                {submittedHelp && <ColumnLayout>
                    <SentenceHelpLoader setHelpDetails={setHelpDetailsForAttempt} sentence={sentence} index={attemptIndex} intention={intendedMeaning} />
                </ColumnLayout>}
            </ContentTile>
        </Modal>
        }
        <ContentTile>

            <ColumnLayout>
                <RowLayout>

                    <AnnotatedTextDisplay Active={true} Text={props.word} Segments={[props.word]} />
                </RowLayout>
                <ColumnLayout>
                    <textarea
                        value={sentence}
                        rows={2}
                        onChange={(e) => updateSentence(e.target.value)}
                        style={{ fontSize: '1.5rem', width: '100%', height: '60px' }}
                    />
                    <StyledButton onClick={submitAttempt}>Evaluate</StyledButton>
                </ColumnLayout>

                <ColumnLayout>
                    {submitted && <>
                        <EvaluateSentenceLoader word={props.word} sentence={sentence} index={attemptIndex} setSentenceCorrect={setSentenceCorrectForAttempt} />
                        <CalculateSentenceDifficultyLoader sentence={sentence} index={attemptIndex} setSentenceDifficulty={setSentenceDifficultyForAttempt} />
                    </>}
                    <RowLayout style={{ justifyContent: 'space-evenly' }}>
                        <StyledButton onClick={() => { setShowDictionaryModal(true) }}>Dictionary</StyledButton>
                        <StyledButton onClick={() => { setShowHelpModal(true) }}>Review</StyledButton>
                        <StyledButton onClick={() => { setShowChatModal(true) }}>Chat</StyledButton>
                        <StyledButton onClick={() => { setShowNotesModal(true) }}>Notes</StyledButton>
                    </RowLayout>
                </ColumnLayout>
                <StyledButton onClick={next}>Next</StyledButton>
            </ColumnLayout>
        </ContentTile>
    </>
}

const EvaluateSentenceLoader: React.FC<{ word: string, sentence: string, index: number, setSentenceCorrect: (sentence: string, index: number, correct: boolean) => void }> = (props) => {

    const api = useContext(ApiContext)

    const [refreshToken, setRefreshToken] = useState(0)

    const { isPending, error, data } = useQuery({
        queryKey: ["grade-sentence-construction", props.word, props.sentence, refreshToken],
        queryFn: () => {
            return api.GradeSentenceConstruction(props.word, props.sentence).then(UnwrapApiCall)
        },
        gcTime: 5,
        staleTime: 5
    })

    useEffect(() => {
        if (data) {
            props.setSentenceCorrect(props.sentence, props.index, data == 1);
        }
    }, [data]);

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>



    return <>
        <ColumnLayout style={{ alignItems: 'center' }}>
            {data != 1 && <TextStyle size={1}>🔴 Incorrect</TextStyle>}
            {data == 1 && <TextStyle size={1}>🟢 Correct</TextStyle>}
        </ColumnLayout>
    </>
}



const SentenceHelpLoader: React.FC<{ sentence: string, index: number, intention: string, setHelpDetails: (sentence: string, index: number, help: string, intendedMeaning?: string) => void }> = (props) => {

    const api = useContext(ApiContext)

    const [refreshToken, setRefreshToken] = useState(0)

    const { isPending, error, data } = useQuery({
        queryKey: ["sentence-construction-help", props.sentence, props.intention, refreshToken],
        queryFn: () => {
            return api.SentenceConstructionHelp(props.sentence, props.intention).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    useEffect(() => {
        if (data) {
            let intention = props.intention == "" ? undefined : props.intention
            props.setHelpDetails(props.sentence, props.index, data, intention);
        }
    }, [data]);

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ColumnLayout style={{ alignItems: 'center' }}>
            <textarea rows={10} value={data} style={{ padding: 12, fontFamily: 'serif', fontSize: '1.5rem', width: '100%', height: '100%', resize: 'vertical', overflow: 'auto' }} />
        </ColumnLayout>
    </>
}


const CalculateSentenceDifficultyLoader: React.FC<{ sentence: string, index: number, setSentenceDifficulty: (sentence: string, index: number, difficulty: number) => void }> = (props) => {

    const api = useContext(ApiContext)

    const [refreshToken, setRefreshToken] = useState(0)

    const { isPending, error, data } = useQuery({
        queryKey: ["calculate-sentence-difficulty", props.sentence, refreshToken],
        queryFn: () => {
            return api.CalculateSentenceDifficulty(props.sentence).then(UnwrapApiCall).then(result => {
                if (result != undefined) {
                    props.setSentenceDifficulty(props.sentence, props.index, result);
                }
                return result
            })
        },
        gcTime: 0,
        staleTime: Infinity
    })

    // useEffect(() => {
    //     if (data) {
    //         props.setSentenceDifficulty(props.sentence, props.index, data);
    //     }
    // }, [data]);

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>


    return <>
        <RowLayout>
            <TextStyle size={1}>Difficulty: </TextStyle>
            <TextStyle weight="bold" size={1}>{data}</TextStyle>
        </RowLayout>
    </>
}