import React, { useContext, useState } from 'react';
import { useQuery, useMutation, QueryClient } from '@tanstack/react-query';
import { DefaultApiContext, UnwrapApiCall } from '../Contexts/Api/DefaultApiContext';
import { ApiContext } from '../Contexts/Api/ApiContext';
import { ContentTile, StyledButton } from '../Components/ModeTile';
import { ColumnLayout } from '../Styles/Styles';
import { TextStyle } from '../App';
import { NoteRequest } from '../api/models/NoteRequest';

export const NotesView: React.FC<{ wordKey: string }> = (props) => {

    const [note, setNote] = useState('');
    const [refreshToken, setRefreshToken] = useState(0);

    const api = useContext(ApiContext);

    const submitNote = () => {
        const request = new NoteRequest();
        request.key = props.wordKey;
        request.note = note;
        api.AddNote(request).then(UnwrapApiCall).then(() => {
            setRefreshToken(refreshToken + 1);
        })
    }
    return <>
        <ContentTile>
            {props.wordKey != undefined && <>
                <ColumnLayout>
                    <TextStyle size={2}>{props.wordKey}</TextStyle>
                    <hr />
                    <textarea value={note} onChange={(e) => setNote(e.target.value)} />
                    <StyledButton onClick={() => {
                        submitNote();
                    }}>Add</StyledButton>
                    <hr />
                    <NoteLoader wordKey={props.wordKey} refreshToken={refreshToken} />
                </ColumnLayout></>}
        </ContentTile>
    </>
}


const NoteLoader: React.FC<{ wordKey: string, refreshToken: number }> = (props) => {
    const api = useContext(ApiContext);
    const { isPending, error, data } = useQuery({
        queryKey: ['notes', props.wordKey, props.refreshToken],
        queryFn: () => api.GetNotes(props.wordKey).then(UnwrapApiCall)
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        {data.map((note) => (
            <ContentTile>
                <TextStyle key={note.id}>{note.note}</TextStyle>
            </ContentTile>
        ))}
    </>
}


