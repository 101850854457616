import { AppCallbacks, TextStyle } from "../App"
import React, { useContext, useEffect, useState } from "react"
import { SubUnitDefinition } from "../Config/UnitDefinitionMap"
import { ItemHistoryElement } from "../Database/HistoryState"
import { Data } from "../Types/Interfaces"
import { RoundControllerParams, RoundRecord } from "../Views/RoundView"
import { ContentTile } from "../Components/ModeTile"
import { DefaultUnitControllerParams, SettingsState } from "../Database/SettingsState"

import { db } from "../Database/db"
import { LanguageInstanceView } from "../Views/LanguageInstanceView"
import { LanguageConfiguration, UpdateUnitState } from "../Config/LanguageDefinitionConfiguration"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ResponseType } from "../Contexts/Api/RootApiContext"
import { LanguageUnitDefinition } from "../Types/LanguageDefinition"
import { TextRefMap } from "../Database/TextStore"

export interface SetMenu {
    SetMenu: (...a: any) => any
}

export interface SetPreamble {
    SetPreamble: (...a: any) => any
}

export interface ParentMenuConfig {
    ParentMenuConfig: {
        Label: string,
        OnClick: () => any
    }[]
}

export interface Reload {
    Reload: (mode: UnitControllerMode) => any
}

export type ExtraUnitsDefinitionType = { [keys: string] : LanguageUnitDefinition<any> }

export interface LanguageDefinitionControllerProps {
    LanguageDefinitionConfiguration: LanguageConfiguration<any>
    ExtraUnitsDefinition : ExtraUnitsDefinitionType
    TextRefMap: TextRefMap
    Params: RoundControllerParams
    RoundRecords: RoundRecord[]
}

export interface State<T> {
    State: T
}

export interface UnitControllerState {

    ControllerMode: UnitControllerMode
    Locked: boolean
    Loaded: boolean

    UseSubUnit: boolean
    SubUnitId: number

    RetryRound: boolean
    RoundRecordId: number
}

export interface UnitLoaderCallbacks {
    SetBySubUnit: (Defn: SubUnitDefinition<any>) => any
    SetByRoundRecord: (RoundRecord: RoundRecord) => any
    SetMode: (mode: UnitControllerMode) => any
    ClearRoundRecord: () => any
    ClearSubUnit: () => any
}

export enum UnitControllerMode {
    Home,
    Display,
    SubUnits,
    Learn,
    Options,
    ViewRoundRecords,
    DebugDev,
    ProcessText,
    Stats,
    Texts,
    Chat,
    InfiniteRead,
    Mistakes,
    ReviewLearning,
    Grades,
    HistorySearch
}

// export const LanguageDefinitionLoader2: React.FC<Data<LanguageConfiguration<any>> & AppCallbacks> = props => {

//     // const unit = props.Data

//     const [loaded, setLoaded] = useState(false)

//     const [history, setHistory] = useState<ItemHistoryElement[]>([])
//     const [historyLoaded, setHistoryLoaded] = useState(false)
//     const [roundRecords, setRoundRecords] = useState<RoundRecord[]>([])
//     const [unitParams, setUnitParams] = useState(DefaultUnitControllerParams(props.Data))

//     const [locked, setLocked] = useState(false)

//     useEffect(() => {

//         let active = true

//         async function Load() {
//             await (db.ItemHistory.toArray().then((history) => { setHistoryLoaded(true); setHistory(history); }))
//         }
        
//         async function LoadParams() {
//             setUnitParams((await (new SettingsState()).Get(props.Data.Id)).Params)
//         }

//         if (!loaded && active) {
//             Load().then(() => UpdateUnitState(props.Data)).then(LoadParams).finally(() => { setLoaded(true) })
//         }

//         return () => {
//             active = false
//         }
//     }, [props, loaded])


//     const [mode, setMode] = useState(UnitControllerMode.Home)

//     const Reload = (ControllerMode: UnitControllerMode) => {
//         setLoaded(false)
//         setMode(ControllerMode)
//     }

//     const [useSubUnitContext, setUseSubUnitContext] = useState(false)
//     const [useSubUnitId, setUseSubUnitId] = useState(-1)

//     const [useRoundRecordContext, setUseRoundRecordContext] = useState(false)
//     const [useRoundRecordId, setUseRoundRecordId] = useState(-1)

//     const SetSubUnitContext = (Defn: SubUnitDefinition<any>) => {
//         setUseSubUnitContext(true)
//         setUseSubUnitId(Defn.Id)
//         Reload(UnitControllerMode.Home)
//     }

//     const ClearSubUnitContext = () => {
//         setUseSubUnitContext(false)
//         setUseSubUnitId(-1)
//         Reload(UnitControllerMode.Home)
//     }

//     const SetRoundRecordContext = (RoundRecord: RoundRecord) => {
//         Reload(UnitControllerMode.Learn)

//         setUseRoundRecordContext(true)
//         setUseRoundRecordId(RoundRecord.Id!!)
//     }

//     const ClearRoundRecordContext = () => {
//         Reload(UnitControllerMode.Learn)

//         setUseSubUnitContext(false)
//         setUseSubUnitId(-1)

//         setUseRoundRecordContext(false)
//         setUseRoundRecordId(-1)

//     }

//     return <>

//         {loaded && history != undefined && <>
//             <LanguageDefinitionView
//                 Data={{
//                     LanguageDefinitionConfiguration: props.Data,
//                     Params: unitParams,
//                     RoundRecords: roundRecords,
//                 }}
//                 State={{
//                     ControllerMode: mode,
//                     Locked: locked,
//                     Loaded: loaded,
//                     UseSubUnit: useSubUnitContext,
//                     SubUnitId: useSubUnitContext ? useSubUnitId : -1,
//                     RetryRound: useRoundRecordContext,
//                     RoundRecordId: useRoundRecordContext ? useRoundRecordId : -1
//                 }}
//                 SetMode={setMode}
//                 SetBySubUnit={SetSubUnitContext}
//                 SetByRoundRecord={SetRoundRecordContext}
//                 ClearRoundRecord={ClearRoundRecordContext}
//                 ClearSubUnit={ClearSubUnitContext}
//                 Reload={Reload}
//                 OnEnter={props.OnEnter}
//                 SetMenu={props.SetMenu}
//                 SetPreamble={props.SetPreamble}
//                 OnExit={props.OnExit}
//             />

//         </>}

//         {(!loaded || history === undefined) && <ContentTile>
//             {/* <TextStyle size={8} weight='bold'>{props.Data.DisplayLabel}</TextStyle>
//             <TextStyle size={3} weight='bold' case='uppercase'>{props.Data.NameLabel}</TextStyle> */}

//             <TextStyle>Loading...</TextStyle>
//         </ContentTile>}
//     </>
// }
