import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import styled, { css } from 'styled-components';
import { ContentTile, StyledButton } from './Components/ModeTile';
import { UnitDefinitionMap } from './Config/UnitDefinitionMap';
import { HistoryState } from './Database/HistoryState';
import { NotificationIcon } from './Icons/NotificationIcon';
import { CloseIcon } from './Icons/CloseIcon';
import { EventDefn, MessageRecordState } from './Database/MessageRecord';
import { MessagesLoader } from './Loaders/MessagesLoader';
import { ColumnLayout, ControllerModeContainer, InnerTile, RowLayout } from './Styles/Styles';
import { GlobalSettingsView } from './Views/GlobalSettingsView';
import { GlobalDebugView } from './Views/GlobalDebugView';
import { DictionaryView } from './Views/DictionaryView';
import { db } from './Database/db';
import { ZhLanguageConfiguration, ZhSelectNextItems } from './Config/LanguageDefinitionConfiguration';
import { ProcessTextView } from './Views/ProcessTextView';
import { ManageTextsView } from './Views/ManageTextsView';
import { RootApiContext } from './Contexts/Api/RootApiContext';
import { DefaultApiContext, UnwrapApiCall } from './Contexts/Api/DefaultApiContext';
import { ApiContext } from './Contexts/Api/ApiContext';
import { ChatHomeView } from './Views/ChatHomeView';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { LanguageInstanceLoader } from './Loaders/LanguageInstanceLoader';
import { GradeView } from './Views/GradeView';
import { StatsView } from './Views/StatsView';
import { LibraryDashboardTile, ScoreCardView, SentenceDashboardTile, StudyDashboardTile } from './Views/StudyDashboardTile';
import { ItemDataView } from './Views/ItemDataView';
import { SentencePageQueryView } from './Views/SentencePageQueryView';
import { BreakdownTextView } from './Views/BreakdownTextView';
import { HistorySearchView } from './Views/HistorySearchView';
import { WriteSentencesView } from './Views/WriteSentencesView';
import { SentenceHomeView } from './Views/SentenceHomeView';
import { LibraryView } from './Views/LibraryView';
import { ManageNotesView } from './Views/ManageNotesView';

interface TextProps {
  size?: number,
  weight?: 'bold' | 'normal'
  colour?: string,
  case?: 'uppercase' | 'normal'
  _style?: 'italic' | 'normal'
  align?: 'left' | 'right' | 'center'
}
export const TextStyle = styled.span<TextProps>`
font-size: ${props => props.size ? props.size : 1}rem;
font-weight: ${props => props.weight ? props.weight : 'normal'};
display: block;
color: ${props => props.colour ? props.colour : 'black'};
font-style: ${props => props._style ? props._style : 'normal'};
font-family: Noto Serif SC, serif;
font-variant: ${props => props.case == 'uppercase' ? "small-caps" : "normal"};
text-align: ${props => props.align ? props.align : 'center'};
`

enum AppMode {
  Home,
  Unit
}

export const AppStyle = styled.div`
  background-color: white;
  display: flex;

  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
`
const NavBar = styled.div`
  width: 100vw;
  background-color: white;
  height: 100px;
  align-self: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 3px 3px 6px #eadfd5;
  position: fixed;
  bottom: -66px;
  bottom: 0px;
  /*https://www.stevefenton.co.uk/blog/2022/12/mobile-position-sticky-issue/  */
  z-index: 10;

  box-shadow: 3px -3px 6px #eadfd5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

`

export const MainColumn = styled.div<{ Blur?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  z-index: 5;

  ${props => props.Blur ? css`

  @keyframes image_blur {
    0% { filter: blur(0px);}
    100% { filter: blur(5px);}

}
animation: image_blur 0.25s;

filter: blur(5px);

  ` : ""}


`

export const TileContainer = styled.div`
display: flex;
flex-direction: row;
gap: 12px;
padding: 24px;
align-items: center;
justify-content: center;
flex-wrap: wrap;

@media (width <  400px) {
  padding-left: 6px;
  padding-right: 6px;
  width: calc(100vw - 12px);

}

@media (width > 400px) {
  padding-left: 24px;
  padding-right: 24px;
  width: calc(100vw - 48px);

}
`

enum AppState {
  Unlock,
  Home,
  PerUnit,
  DisplayUnit,
  GlobalSettings,
  Dictionary,
  DevDebug,
  Study,
  Text,
  Chat,
  SentenceData,
  BreakdownText,
  Notes
}


export interface MenuConfig {
  Label: string,
  OnClick: () => any
}

const NotificationPaneBackground = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: #0000001a;
  position: fixed;
  top: 0;
  z-index: 5;

`



export interface AppCallbacks {
  OnEnter: () => any
  OnExit: () => any
  SetPreamble: (...a: any) => any
  SetMenu: (...a: any) => any
  SendEvent: (event: EventDefn) => any
}

const ApiContextObject: RootApiContext = new DefaultApiContext()
const queryClient = new QueryClient()

const UnlockApp: React.FC<{ OnUnlock: () => any }> = props => {
  const api = useContext(ApiContext)

  const [key, setKey] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const Unlock = () => {
    setLoading(true)
    setError(false)
    api.CheckKey(key).then(UnwrapApiCall).then((unlock) => {
      if (unlock) {
        localStorage.setItem("key", key);
        props.OnUnlock()
      } else {
        setError(true)
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (localStorage.getItem("key") != undefined) {
      props.OnUnlock()
    }
  }, [props])

  return <>
    <ContentTile>
      <ColumnLayout>
        <RowLayout>
          <TextStyle>Key</TextStyle>
          <input onChange={(e) => setKey(e.target.value)} type="text" />
        </RowLayout>
        {loading && <TextStyle>Loading...</TextStyle>}
        {error && <TextStyle colour='red'>Error</TextStyle>}
        <StyledButton onClick={Unlock}>Unlock</StyledButton>
      </ColumnLayout>
    </ContentTile>
  </>
}

function App() {

  const [appState, setAppState] = useState(AppState.Unlock)

  const [unitControllerId, setUnitControllerId] = useState(-1)

  const [preambleText, setPreambleText] = useState<string | undefined>("Home")

  const ReturnHome = () => {
    setAppState(AppState.Home)
    setMenuConfig(HomeMenuConfig)
  }

  const OptionsMenuConfig: MenuConfig[] = [
    {
      Label: "Exit",
      OnClick: () => {
        setPreambleText("Home")
        setAppState(AppState.Home)
        setMenuConfig(HomeMenuConfig)

      }
    }
  ]

  const HomeMenuConfig: MenuConfig[] = [
    {
      Label: "Breakdown Text",
      OnClick: () => {
        setPreambleText("Breakdown Text")

        setMenuConfig(OptionsMenuConfig)
        setAppState(AppState.BreakdownText)
      }
    },
    {
      Label: "Chat",
      OnClick: () => {
        setPreambleText("Chat")

        setMenuConfig(OptionsMenuConfig)
        setAppState(AppState.Chat)
      }
    },
    {
      Label: "Notes",
      OnClick: () => {
        setPreambleText("Notes")

        setMenuConfig(OptionsMenuConfig)
        setAppState(AppState.Notes)
      }
    },
    {
      Label: "Dictionary",
      OnClick: () => {
        setPreambleText("Dictionary")

        setMenuConfig(OptionsMenuConfig)
        setAppState(AppState.Dictionary)
      }
    },
    {
      Label: "DevDebug",
      OnClick: () => {
        setPreambleText("Global DevDebug")

        setMenuConfig(OptionsMenuConfig)
        setAppState(AppState.DevDebug)
      }
    },

    {
      Label: "Options",
      OnClick: () => {
        setPreambleText("Options")

        setMenuConfig(OptionsMenuConfig)
        setAppState(AppState.GlobalSettings)
      }
    }
  ]

  const ReceiveEvent = (event: EventDefn) => {
    console.log(event);

    (new MessageRecordState()).AddEvent(event)
  }


  const [menuConfig, setMenuConfig] = useState(HomeMenuConfig)

  const [showNotificationPane, setShowNotificationPane] = useState(false)

  const [showAlert, setShowAlert] = useState(false)

  const [settings, setSettings] = useState<{ ApiServer: string } | undefined>(undefined)


  useEffect(() => {
    if (settings == undefined)
      fetch("/config.json", { headers: { 'Conten-Type': 'application/json' } }).then(async (response) => {
        setSettings(await response.json())
      })
  }, [settings])

  ApiContextObject.applySettings(settings)



  return (
    <>
      {settings == undefined && <><TextStyle>Loading...</TextStyle></>}
      {settings != undefined && <>
        <ApiContext.Provider value={ApiContextObject}>
          <QueryClientProvider client={queryClient}>
            {appState == AppState.Unlock && <>
              <AppStyle style={{ justifyContent: 'center', alignItems: 'center' }}>
                <UnlockApp OnUnlock={() => {
                  setAppState(AppState.Home)
                }} />
              </AppStyle>
            </>}
            {appState != AppState.Unlock &&
              <>
                <AppStyle>
                  <NavBar>
                    {/* <div style={{ paddingLeft: 24, marginLeft: 0 }}>
          <TextStyle size={1} weight='bold' case='uppercase'>No name</TextStyle>
        </div> */}
                    <div style={{ marginLeft: 0, marginRight: 'auto', paddingLeft: 24 }}>
                      {/* <div onClick={() => setShowNotificationPane(!showNotificationPane)}>
                  {showNotificationPane && <>
                    <CloseIcon />
                  </>}
                  {!showNotificationPane && <>
                    <NotificationIcon />
                  </>}
                </div> */}
                    </div>

                    <div style={{ paddingLeft: 24, marginRight: 24, marginLeft: 'auto', gap: 12, display: 'flex' }}>
                      {menuConfig.map((mc, i) => {
                        return <StyledButton key={i} onClick={mc.OnClick}><TextStyle size={1} case='uppercase'>{mc.Label}</TextStyle></StyledButton>
                      })}
                    </div>

                  </NavBar>
                  <MainColumn Blur={showNotificationPane}>

                    {/* <div style={{ display: 'flex', flexDirection: 'row', height: 52, padding: 24, paddingBottom: 24 }}>
                {preambleText != undefined && <TextStyle size={2} weight='bold'>{preambleText}</TextStyle>}
              </div> */}
                    <TileContainer>
                      {(appState == AppState.Home) && <>
                        <RowLayout>
                          <InnerTile>
                            <ScoreCardView />
                          </InnerTile>
                          <InnerTile onClick={() => { setAppState(AppState.Study); setMenuConfig(OptionsMenuConfig); setPreambleText("Study") }}>
                            <StudyDashboardTile />
                          </InnerTile>
                          <InnerTile onClick={() => { setAppState(AppState.SentenceData); setMenuConfig(OptionsMenuConfig); setPreambleText("Sentence Data") }}>
                            <TextStyle>Sentences</TextStyle>
                            <SentenceDashboardTile />
                          </InnerTile>
                          <InnerTile onClick={() => { setAppState(AppState.Text); setMenuConfig(OptionsMenuConfig); setPreambleText("Library") }}>
                            <TextStyle>Library</TextStyle>
                            <LibraryDashboardTile />
                          </InnerTile>





                        </RowLayout>

                      </>}
                      {appState == AppState.Text && <LibraryView
                        SetPreamble={setPreambleText}
                        SetMenu={setMenuConfig}
                        OnEnter={() => { setAppState(AppState.Text); setMenuConfig(OptionsMenuConfig); setPreambleText("Texts") }}
                        OnExit={() => { setAppState(AppState.Home); setMenuConfig(HomeMenuConfig); setPreambleText("Available units") }}
                        SendEvent={ReceiveEvent}
                      />}
                      {appState == AppState.BreakdownText && <BreakdownTextView
                        SetMenu={setMenuConfig}
                        OnExit={() => { setAppState(AppState.Home); setMenuConfig(HomeMenuConfig); setPreambleText("Available units") }}
                      />}
                      {appState == AppState.Chat && <ChatHomeView
                        SetPreamble={setPreambleText}
                        SetMenu={setMenuConfig}
                        OnEnter={() => { setAppState(AppState.Chat); setMenuConfig(OptionsMenuConfig); setPreambleText("Texts") }}
                        OnExit={() => { setAppState(AppState.Home); setMenuConfig(HomeMenuConfig); setPreambleText("Available units") }}
                        SendEvent={ReceiveEvent}
                      />}
                      {(appState == AppState.Study) && <LanguageInstanceLoader
                        Data={ZhLanguageConfiguration}
                        SetPreamble={setPreambleText}
                        SetMenu={setMenuConfig}
                        OnEnter={() => { setAppState(AppState.Study); setMenuConfig(OptionsMenuConfig); setPreambleText("Study") }}
                        OnExit={() => { setAppState(AppState.Home); setMenuConfig(HomeMenuConfig); setPreambleText("Available units") }}
                        SendEvent={ReceiveEvent}
                      />}
                      {appState == AppState.DevDebug && <>
                        <GlobalDebugView
                          SetPreamble={setPreambleText}
                          SetMenu={setMenuConfig}
                          OnEnter={() => { }}
                          OnExit={() => { }}
                          SendEvent={ReceiveEvent}

                        />
                      </>}


                      {appState == AppState.SentenceData && <>
                        <SentenceHomeView />
                      </>}
                      {appState == AppState.Dictionary && <>
                        <DictionaryView />
                      </>}
                      {appState == AppState.GlobalSettings && <>
                        <GlobalSettingsView />
                      </>}
                      {appState == AppState.Notes && <>
                        <ManageNotesView />
                      </>}


                    </TileContainer>
                  </MainColumn>
                </AppStyle >
                {showNotificationPane && <>
                  <NotificationPaneBackground onClick={() => setShowNotificationPane(false)}>
                    <MessagesLoader />
                  </NotificationPaneBackground>
                </>}
              </>}
          </QueryClientProvider >
        </ApiContext.Provider>
      </>}
    </>
  );
}

export default App;
