import { useEffect, useState } from "react"
import { TextStyle } from "../App"
import { UnitDefinitionMapType } from "../Config/UnitDefinitionMap"
import { AnswerState, ItemHistoryElement, LanguageFundamentalType } from "../Database/HistoryState"
import { OnExit, Data } from "../Types/Interfaces"
import { DisplayItem } from "../Components/DisplayItem"
import { v4 as uuidv4 } from 'uuid';
import { LanguageDefinition } from "../Types/LanguageDefinition"
import { LanguageConfiguration, NextItemContainer } from "../Config/LanguageDefinitionConfiguration"
import { ExtraUnitsDefinitionType } from "../Loaders/LanguageDefinitionLoader"
import { TextRefMap, TextRefType } from "../Database/TextStore"
import { NextItemContainer_ } from "./NewLearnView"
import { ItemTypeTestConfigurationMap } from "../Config/Language/Zh/Types"
import { AnswerRecordRecordEnum, ItemData, ItemHistory, ItemRating } from "../api"
import { NewDisplayItem } from "../Components/NewDisplayItem"
import { UnitTestInterfaceConfiguration } from "../Config/Language/Zh/Config/UnitTestInterfaceConfig"

export interface RoundControllerParams {
    IntroduceNew: boolean
    IntroduceCount: number

    RoundSize: number

    PromoteOnNth: number
    FailOnNth: number

    ShowUntilCorrect: boolean
    ShuffleOnIncorrect: boolean

    SaveResults: boolean

    TimeLimit: number

    OrderRound?: (Item: any[]) => any[]

    TestDefinitions: number[]
}

interface NewRoundControllerProps {
    Params: RoundControllerParams

    // LanguageConfiguration: LanguageConfiguration<any>
    // ExtraLanguageDefinition: ExtraUnitsDefinitionType
    // TextRefMap: TextRefMap

    NextUnits: NextItemContainer_,
    ItemDataRecord: Record<string, ItemData>
    ItemTypeTests: ItemTypeTestConfigurationMap
    UnitTestInterfaces: UnitTestInterfaceConfiguration

    SetData: (RoundRecord: RoundRecord) => any

    OnComplete: (RoundRecord: RoundRecord) => any
    OnClose: () => any,

}

export interface AnswerRecord {
    TimeAnswered: Date
    TimeToAnswer: number
    Record: AnswerState
}

export interface NewRoundItemData {
    ItemHistoryId: number
    UnitId: number
    ItemKey: string
    TestDefinition: number
    LearningState: number
    Complete: boolean
    SuccessCount: number
    FailureCount: number
    DifficultyHint?: ItemRating
    Record: AnswerRecord[]
}

export interface GenericRoundItemData {
    UnitId: number
    ItemKey: string
    Complete: boolean
    SuccessCount: number
    FailureCount: number
    Record: AnswerRecord[]
}

export interface GenericRoundRecord {
    Id?: number

    RoundType: number

    TimeBegan: Date
    TimeFinished: Date
    TimeElapsed: number

    RoundGuid: string
    Items: GenericRoundItemData[]

}

export interface RoundRecord {
    Id?: number

    TimeBegan: Date
    TimeFinished: Date
    TimeElapsed: number

    RoundGuid: string
    Items: NewRoundItemData[]
    // Params: RoundControllerParams

}

export const NewRoundView: React.FC<OnExit & Data<NewRoundControllerProps>> = props => {

    const HistoryElementToRoundItem: (x: ItemHistory) => NewRoundItemData = (x: ItemHistory) => ({
        ItemHistoryId: x.id!!,
        UnitId: x.unitId,
        ItemKey: x.key,
        LearningState: x.learningState,
        TestDefinition: x.testDefinitionId,
        Complete: false,
        SuccessCount: 0,
        FailureCount: 0,
        Record: [] as AnswerRecord[]
    })


    console.log(props.Data.NextUnits)

    const toIntroduce = props.Data.NextUnits.New.filter(v => { var it = v.itemTypeIds[0]; return v.testDefinitionId == props.Data.ItemTypeTests[it].PrimaryTestDefinitionId })

    const introduceItems = toIntroduce.slice(0, props.Data.Params.IntroduceCount).map(HistoryElementToRoundItem)

    const newTests = props.Data.NextUnits.New.filter(v => { var it = v.itemTypeIds[0]; return v.testDefinitionId != props.Data.ItemTypeTests[it].PrimaryTestDefinitionId })
    const reviewTests = props.Data.NextUnits.Review

    const Shuffle: (a: any[]) => any[] = array => array.map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)


    const toDisplayRange = [...newTests, ...reviewTests].map(HistoryElementToRoundItem)

    const sortedPool: NewRoundItemData[] = Shuffle(props.Data.Params.OrderRound!!(toDisplayRange))

    const slicedPool = sortedPool

    const [introduceWhich, setIntroduceWhich] = useState(0)
    const [allIntroduced, setAllIntroduced] = useState(false)
    const [checkedIntroduceSetting, setCheckedIntroduceSetting] = useState(false)

    const [displayWhich, setDisplayWhich] = useState(0)

    const [roundPool, setRoundPool] = useState<NewRoundItemData[]>([])
    const [completed, setCompleted] = useState<NewRoundItemData[]>([])

    const [itemsRemaining, setItemsRemaining] = useState(0)

    const OnIntroduced = () => {

        setIntroduceWhich(introduceWhich + 1)

        if (introduceWhich == introduceItems.length - 1) {
            setAllIntroduced(true)

            // if (props.Data.RoundItems != undefined) {
            //     setRoundPool(Shuffle(props.Data.RoundItems))
            //     setItemsRemaining(props.Data.RoundItems.length)

            // } else {
            if (slicedPool.length == 0) {
                setRoundPool(Shuffle(introduceItems))
                setItemsRemaining(introduceItems.length)
            } else {
                var round: NewRoundItemData[] = Shuffle([...introduceItems, ...slicedPool])
                setRoundPool(round)
                setItemsRemaining(round.length)

            }

            // }


        }
    }

    const OnItemResult = (wasCorrect: boolean, timeElapsed: number, rating?: ItemRating) => {

        const item = roundPool[displayWhich]


        // sentence is correct if wasCorrect is true OR rating is not 'Again'
        const correct = wasCorrect || (rating && rating != 'Again')

        if (rating) {
            item.DifficultyHint = rating
        }

        if (correct) {
            item.SuccessCount += 1
            item.Record.push({
                Record: AnswerState.Success,
                TimeToAnswer: timeElapsed,
                TimeAnswered: new Date()
            })
        } else {
            item.FailureCount += 1
            item.SuccessCount = 0
            item.Record.push({
                Record: AnswerState.Failure,
                TimeToAnswer: timeElapsed,
                TimeAnswered: new Date()
            })
        }

        if (roundPool[displayWhich].SuccessCount == props.Data.Params.PromoteOnNth) {
            item.Complete = true

            setItemsRemaining(itemsRemaining - 1)
        }

        if (!props.Data.Params.ShowUntilCorrect && roundPool[displayWhich].FailureCount == props.Data.Params.FailOnNth) {
            item.Complete = true

            setItemsRemaining(itemsRemaining - 1)
        }

        if (displayWhich == roundPool.length - 1) {
            CheckRoundState()
        } else if (!wasCorrect && props.Data.Params.ShuffleOnIncorrect) {
            CheckRoundState()
        } else {
            setDisplayWhich(displayWhich + 1)
        }

    }

    const CheckRoundState = async () => {

        if (roundPool.every(item => item.Complete)) {
            const fullList = [...completed, ...roundPool]

            roundRecord.TimeFinished = new Date()
            roundRecord.TimeElapsed = roundRecord.TimeFinished.getTime() - roundRecord.TimeBegan.getTime()
            roundRecord.Items = fullList

            var Id = await props.Data.SetData(roundRecord)
            roundRecord.Id = Id
            props.Data.OnComplete(roundRecord)
        } else {

            var _completed = [...roundPool.filter(item => item.Complete), ...completed]
            setCompleted(_completed)

            var incomplete = roundPool.filter(item => !item.Complete)
            var shuffled = Shuffle(incomplete)
            setRoundPool([...shuffled])
            setDisplayWhich(0)
        }

    }

    const ResetState = () => {
        setIntroduceWhich(0)
        setAllIntroduced(false)
        setCheckedIntroduceSetting(false)
        setDisplayWhich(0)
        setRoundPool([])
        setCompleted([])
        setItemsRemaining(0)

        setRoundRecord({
            TimeBegan: new Date(),
            TimeFinished: new Date(),
            TimeElapsed: 0,
            RoundGuid: /*props.Data.RoundGuid ? props.Data.RoundGuid :*/ uuidv4(),
            Items: /*props.Data.RoundItems ? props.Data.RoundItems :*/[...introduceItems, ...slicedPool],
            // Params: props.Data.Params
        })
    }

    // useEffect(ResetState, [props.Data.RoundItems])

    const [roundRecord, setRoundRecord] = useState<RoundRecord>({
        TimeBegan: new Date(),
        TimeFinished: new Date(),
        TimeElapsed: 0,
        RoundGuid: /*props.Data.RoundGuid ? props.Data.RoundGuid :*/ uuidv4(),
        Items: /*props.Data.RoundItems ? props.Data.RoundItems :*/[...introduceItems, ...slicedPool],
        // Params: props.Data.Params
    })

    if ((introduceItems.length == 0 || !props.Data.Params.IntroduceNew) && !checkedIntroduceSetting) {
        setAllIntroduced(true)
        setCheckedIntroduceSetting(true)

        // if (props.Data.RoundItems != undefined) {
        //     setRoundPool(Shuffle(props.Data.RoundItems))
        //     setItemsRemaining(props.Data.RoundItems.length)

        // } else {
        var round = Shuffle([...slicedPool])
        setRoundPool(round)
        setItemsRemaining(round.length)

        // }

    }


    return (<>
        <>
            {!allIntroduced && introduceItems.length > 0 && <>
                <TextStyle size={1.5} weight='bold'>New新</TextStyle>
                <TextStyle>{introduceWhich + 1}/{introduceItems.length}</TextStyle>

                <NewDisplayItem key={introduceItems[introduceWhich].ItemHistoryId} Data={{
                    // LanguageConfiguration: props.Data.LanguageConfiguration,
                    // ExtraUnitsDefinition: props.Data.ExtraLanguageDefinition,
                    // TextRefMap: props.Data.TextRefMap,
                    ItemTypeTests: props.Data.ItemTypeTests,
                    UnitTestInterfaces: props.Data.UnitTestInterfaces,
                    ItemDataRecord: props.Data.ItemDataRecord,
                    RoundItem: introduceItems[introduceWhich],
                    Introduce: true,
                    TimeLimit: props.Data.Params.TimeLimit,
                    OnIntroduced: OnIntroduced,
                    OnAnswer: () => { }
                }} />
            </>
            }
            {roundPool.length > 0 && allIntroduced && <>
                <div style={{ opacity: 0.5, display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                    <TextStyle size={1.5} weight='bold'>Review试验</TextStyle>

                    <TextStyle align='center'   >Remaining: {itemsRemaining}</TextStyle>
                </div>
                <NewDisplayItem key={roundPool[displayWhich].ItemHistoryId} Data={{
                    // LanguageConfiguration: props.Data.LanguageConfiguration,
                    // ExtraUnitsDefinition: props.Data.ExtraLanguageDefinition,
                    // TextRefMap: props.Data.TextRefMap,
                    ItemTypeTests: props.Data.ItemTypeTests,
                    UnitTestInterfaces: props.Data.UnitTestInterfaces,
                    ItemDataRecord: props.Data.ItemDataRecord,
                    RoundItem: roundPool[displayWhich],
                    Introduce: false,
                    TimeLimit: props.Data.Params.TimeLimit,
                    OnIntroduced: OnIntroduced,
                    OnAnswer: OnItemResult
                }} />
            </>}

            {roundPool.length == 0 && allIntroduced && <>
                <TextStyle>There are no items to review.</TextStyle>
            </>}
        </>
    </>)
}