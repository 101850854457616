import { SentenceDashboardTile } from "./StudyDashboardTile";
import { ButtonSubDisplay, RowLayout, ColumnLayout, InnerTile } from "../Styles/Styles";

import { TextStyle } from "../App";

import { ContentTile, StyledButton } from "../Components/ModeTile";
import { ButtonDisplay } from "../Styles/Styles";
import { WriteSentencesView } from "./WriteSentencesView";
import { SentencePageQueryView } from "./SentencePageQueryView";
import React, { useContext, useState } from "react";
import { SentenceSearchType } from "../api/models/SentenceSearchType";
import { SentenceKeyLastSeen } from "../api/models/SentenceKeyLastSeen";
import { SentenceSearchKey } from "../api/models/SentenceSearchKey";
import { SentenceSelectionQuery } from "../api/models/SentenceSelectionQuery";
import { useQuery } from "@tanstack/react-query";
import { ActionRecord, ActionRecordAction, GetActionRecordRequest, KeyRecord, SentenceSelectionMethod } from "../api";
import { ApiContext } from "../Contexts/Api/ApiContext";
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext";
import { Paginator } from "../Components/Paginator";
import { DictionaryView, LoadDictionaryQuery } from "./DictionaryView";
import { Modal } from "../Components/Modal";
import dayjs from "dayjs";
import { PlayTextAudio } from "../Components/PlayTextAudio";
import { ExampleSentenceDisplay, LoadPrimarySentenceTextDisplay } from "../Components/ItemExamplesView";
import { OnSelectionPopup } from "../Displays/TextStructureDisplay";
import { BreakIcon } from "../Icons/BreakIcon";
import { OptionsIcon } from "../Icons/OptionsIcon";

export const SentenceHomeView: React.FC = (props) => {

    enum AppState {
        Home,
        ReadSentences,
        WriteSentences,
        ViewKeys,
        HistorySentences
    }
    const [state, setState] = useState(AppState.Home)
    return <>
        <ContentTile style={{ justifyContent: 'center' }}>
            {state == AppState.Home && <>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextStyle size={2} weight='bold'>Sentences</TextStyle>


                    <SentenceDashboardTile />
                    <hr />

                    <ButtonDisplay>
                        <StyledButton onClick={() => { setState(AppState.ReadSentences) }}><TextStyle case='uppercase' size={2} weight='bold'>Read</TextStyle></StyledButton>
                        <StyledButton onClick={() => { setState(AppState.HistorySentences) }}><TextStyle case='uppercase' size={2} weight='bold'>History</TextStyle></StyledButton>
                        <StyledButton onClick={() => { setState(AppState.WriteSentences) }}><TextStyle case='uppercase' size={2} weight='bold'>Write</TextStyle></StyledButton>
                        <StyledButton onClick={() => { setState(AppState.ViewKeys) }}><TextStyle case='uppercase' size={2} weight='bold'>Key Records</TextStyle></StyledButton>

                        <ButtonSubDisplay>
                            {/* <StyledButton onClick={() => TakeFocus(UnitControllerMode.Display)}><TextStyle case='uppercase' size={2} weight='bold'>View</TextStyle></StyledButton> */}
                            {/* <StyledButton onClick={() => TakeFocus(UnitControllerMode.ViewRoundRecords)}><TextStyle case='uppercase' size={2} weight='bold'>History</TextStyle></StyledButton> */}
                            {/* {Unit.SubUnits.length > 0 && <StyledButton onClick={() => TakeFocus(UnitControllerMode.SubUnits)}><TextStyle case='uppercase' size={2} weight='bold'>Sub-Units</TextStyle></StyledButton>} */}
                        </ButtonSubDisplay>
                        <ButtonSubDisplay>
                            {/* <StyledButton onClick={() => { }}><TextStyle case='uppercase' size={2} weight='bold'>Texts</TextStyle></StyledButton> */}
                            {/* <StyledButton onClick={() => { }}><TextStyle case='uppercase' size={2} weight='bold'>Chat</TextStyle></StyledButton> */}
                        </ButtonSubDisplay>
                        <ButtonSubDisplay>

                        </ButtonSubDisplay>
                        <ButtonSubDisplay>
                            {/* <StyledButton onClick={() => TakeFocus(UnitControllerMode.DebugDev)}><TextStyle case='uppercase' size={2} weight='bold'>Debug/Dev</TextStyle></StyledButton> */}
                        </ButtonSubDisplay>
                    </ButtonDisplay>




                </div>
            </>}
            {state == AppState.ReadSentences && <>
                <SentencePageQueryView />
            </>}
            {state == AppState.HistorySentences && <>
                <SentenceHistoryView />
            </>}
            {state == AppState.WriteSentences && <>
                <WriteSentencesView />
            </>}
            {state == AppState.ViewKeys && <>
                <SentenceKeysView />
            </>}
        </ContentTile >
    </>
}

const SentenceHistoryView: React.FC = (props) => {
    const [startDate, setStartDate] = useState(new Date(((new Date()).toDateString())))
    const [endDate, setEndDate] = useState(new Date(((new Date()).toDateString())))

    const [showHistory, setShowHistory] = useState(false)

    return <>
        <ContentTile>
            <ColumnLayout>
                <RowLayout>
                    <TextStyle>Start Date</TextStyle>
                    <input type='date' value={dayjs(startDate).format('YYYY-MM-DD')} onChange={e => setStartDate(new Date(e.target.value))} />
                </RowLayout>
                <RowLayout>
                    <TextStyle>End Date</TextStyle>
                    <input type='date' value={dayjs(endDate).format('YYYY-MM-DD')} onChange={e => setEndDate(new Date(e.target.value))} />
                </RowLayout>
                <StyledButton onClick={() => setShowHistory(true)}>Search</StyledButton>
            </ColumnLayout>
        </ContentTile>
        {showHistory && <SentenceHistoryLoader startDate={startDate} endDate={endDate} />}
    </>
}

const SentenceHistoryLoader: React.FC<{ startDate: Date, endDate: Date }> = props => {


    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ["actionRecords", props.startDate, props.endDate],
        queryFn: () => {
            const query: GetActionRecordRequest = {
                startDate: props.startDate,
                endDate: props.endDate,
                type: ActionRecordAction.ReadSentence
            }
            return api.GetActionRecords(query).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <SentenceHistoryDisplay data={data} />
    </>
}

const SentenceHistoryDisplay: React.FC<{ data: ActionRecord[] }> = props => {
    return <>
        <Paginator pageSize={20}>
            {props.data.map(record => <>
                <ContentTile>
                    <ExampleSentenceDisplay text={record.metadata?.text} sentenceRef={record.metadata?.ref} />
                </ContentTile>
            </>)}
        </Paginator>
    </>
}

const SentenceKeysView: React.FC = (props) => {

    const [baseUnits, setBaseUnits] = useState(SentenceSearchKey.New)
    const [lastSeen, setLastSeen] = useState(SentenceKeyLastSeen.Today)
    const [sentenceType, setSentenceType] = useState(SentenceSearchType.AllKnown)


    const [showKeyRecords, setShowKeyRecords] = useState(false)

    const Show = () => {
        setShowKeyRecords(true)
    }

    return <>
        <ColumnLayout>

            <RowLayout>
                <TextStyle>Search Key</TextStyle>
                <select value={baseUnits} onChange={(e) => setBaseUnits(e.target.value as unknown as SentenceSearchKey)}>
                    <option value={SentenceSearchKey.New}>New</option>
                    <option value={SentenceSearchKey.Reviewed}>Reviewed</option>
                    <option value={SentenceSearchKey.Lapsed}>Lapsed</option>
                    <option value={SentenceSearchKey.Custom}>Custom</option>
                </select>
            </RowLayout>

            <RowLayout>
                <TextStyle>Last Seen</TextStyle>
                <select value={lastSeen} onChange={(e) => setLastSeen(e.target.value as unknown as SentenceKeyLastSeen)}>
                    <option value={SentenceKeyLastSeen.Today}>Today</option>
                    <option value={SentenceKeyLastSeen.Yesterday}>Yesterday</option>
                    <option value={SentenceKeyLastSeen.ThisPeriod}>This period</option>
                    <option value={SentenceKeyLastSeen.LastPeriod}>Last period</option>
                    <option value={SentenceKeyLastSeen.AnyTime}>Any time</option>
                </select>
            </RowLayout>
            <RowLayout>
                <TextStyle>Sentence Type</TextStyle>
                <select value={sentenceType} onChange={(e) => setSentenceType(e.target.value as unknown as SentenceSearchType)}>
                    <option value={SentenceSearchType.AllKnown}>All known</option>
                    <option value={SentenceSearchType.Nplusone}>N+1</option>
                    <option value={SentenceSearchType.Nplus2}>N+2</option>
                    <option value={SentenceSearchType.Unrestricted}>Unrestricted</option>
                </select>
            </RowLayout>

            <StyledButton onClick={Show}>Search</StyledButton>
        </ColumnLayout>
        <hr />
        {showKeyRecords && <><KeyRecordLoader baseUnits={baseUnits} lastSeen={lastSeen} sentenceType={sentenceType} /></>}
    </>
}


const KeyRecordLoader: React.FC<{ baseUnits: SentenceSearchKey, lastSeen: SentenceKeyLastSeen, sentenceType: SentenceSearchType }> = props => {
    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ["keyRecords", props.baseUnits, props.lastSeen, props.sentenceType],
        queryFn: () => {
            const query: SentenceSelectionQuery = {
                selectionMethod: SentenceSelectionMethod.Known,
                count: 0,
                baseUnits: props.baseUnits,
                lastSeen: props.lastSeen,
                sentenceType: props.sentenceType,
                keyInput: [],
                gradeQuery: 0
            }
            return api.KeyRecords(query).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    const seenCount = data.reduce((acc, curr) => acc + (curr.data != undefined ? 1 : 0), 0)
    const readCounts = Array.from(new Set(data.filter(x => x.data != undefined).map(x => x.data!!.readPrompt))).sort((a, b) => b - a)
    const highestRead = readCounts[0]

    return <>

        <RowLayout>
            <ColumnLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <TextStyle weight='bold'>Seen</TextStyle>
                    <TextStyle>{seenCount}</TextStyle>
                    <TextStyle weight='bold'>Total</TextStyle>
                    <TextStyle>{data.length}</TextStyle>
                    <TextStyle>({Math.round(seenCount / data.length * 100)}%)</TextStyle>
                </RowLayout>
                <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>

                </RowLayout>
            </ColumnLayout>
            <Paginator>
                {data.map(record => <KeyRecordDisplay record={record} highestRead={highestRead} />)}
            </Paginator>
        </RowLayout>
    </>
}

const KeyRecordDisplay: React.FC<{ record: KeyRecord, highestRead: number }> = props => {

    const [showQuery, setShowQuery] = useState(false)

    const baseGreen = "#008000"

    const opacity = props.record.data != undefined ? (props.record.data.readPrompt / props.highestRead) : 0
    const opacityMod = Math.round(opacity * 255).toString(16).padStart(2, '0')


    return <>
        {showQuery && <Modal OnClose={() => setShowQuery(false)}><LoadDictionaryQuery query={props.record.key} /></Modal>}
        <InnerTile style={{ borderBottom: `3px solid ${baseGreen}${opacityMod}` }} Small={true} onClick={() => setShowQuery(true)}>
            <TextStyle size={2} weight="bold" colour={props.record.data != undefined ? 'black' : '#0000003f'}>{props.record.key}</TextStyle>
        </InnerTile>
    </>
}
