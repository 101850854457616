import { ResponseContext, RequestContext, HttpFile, HttpInfo } from '../http/http';
import { Configuration} from '../configuration'
import { Observable, of, from } from '../rxjsStub';
import {mergeMap, map} from  '../rxjsStub';
import { ActionRecord } from '../models/ActionRecord';
import { ActionRecordAction } from '../models/ActionRecordAction';
import { AnswerRecord } from '../models/AnswerRecord';
import { ApiRoundItemData } from '../models/ApiRoundItemData';
import { ApiRoundRecord } from '../models/ApiRoundRecord';
import { AssistValidateRequest } from '../models/AssistValidateRequest';
import { BasicStatsQuery } from '../models/BasicStatsQuery';
import { BasicStatsResponse } from '../models/BasicStatsResponse';
import { BreakdownTextRequest } from '../models/BreakdownTextRequest';
import { CalculateSentenceDifficultyRequest } from '../models/CalculateSentenceDifficultyRequest';
import { DailyGoal } from '../models/DailyGoal';
import { DailyScoreRecord } from '../models/DailyScoreRecord';
import { DashboardResponse } from '../models/DashboardResponse';
import { DictionaryEntry } from '../models/DictionaryEntry';
import { DictionaryEntryDefinition } from '../models/DictionaryEntryDefinition';
import { DictionaryEntryDefinitionExamples } from '../models/DictionaryEntryDefinitionExamples';
import { DictionaryEntryType } from '../models/DictionaryEntryType';
import { DictionaryQueryResult } from '../models/DictionaryQueryResult';
import { EntryExtraData } from '../models/EntryExtraData';
import { EvaluateMeaningRequest } from '../models/EvaluateMeaningRequest';
import { EvaluateTranslationRequest2 } from '../models/EvaluateTranslationRequest2';
import { GenerateAudioRequest } from '../models/GenerateAudioRequest';
import { GenerateAudioResponse } from '../models/GenerateAudioResponse';
import { GenerateTextRequest } from '../models/GenerateTextRequest';
import { GeneratedTextStyle } from '../models/GeneratedTextStyle';
import { GetActionRecordRequest } from '../models/GetActionRecordRequest';
import { GetNotesRequest } from '../models/GetNotesRequest';
import { GetSentencesWithWord200ResponseInner } from '../models/GetSentencesWithWord200ResponseInner';
import { GraadeSentenceConstructionRequest } from '../models/GraadeSentenceConstructionRequest';
import { ItemData } from '../models/ItemData';
import { ItemHistory } from '../models/ItemHistory';
import { KeyContainer } from '../models/KeyContainer';
import { KeyRecord } from '../models/KeyRecord';
import { KeyRecordData } from '../models/KeyRecordData';
import { KeyRecordStats } from '../models/KeyRecordStats';
import { LanguageTextPair } from '../models/LanguageTextPair';
import { LibraryDashboardResponse } from '../models/LibraryDashboardResponse';
import { LookupQuery } from '../models/LookupQuery';
import { Message } from '../models/Message';
import { Messages } from '../models/Messages';
import { Note } from '../models/Note';
import { NoteRequest } from '../models/NoteRequest';
import { PairedItem } from '../models/PairedItem';
import { PartialChapter } from '../models/PartialChapter';
import { PartialParagraph } from '../models/PartialParagraph';
import { PartialSentence } from '../models/PartialSentence';
import { PartialTextContainer } from '../models/PartialTextContainer';
import { PartialTextContainerStructure } from '../models/PartialTextContainerStructure';
import { PartialTextNode } from '../models/PartialTextNode';
import { PartialTextStructure } from '../models/PartialTextStructure';
import { PartialTextStructureType } from '../models/PartialTextStructureType';
import { PickRoundRecordExcludeKeyofRoundRecordItems } from '../models/PickRoundRecordExcludeKeyofRoundRecordItems';
import { ProcessTextRequest } from '../models/ProcessTextRequest';
import { ProcessTextRequest2 } from '../models/ProcessTextRequest2';
import { ProcessTextResponse } from '../models/ProcessTextResponse';
import { ProcessedChapter } from '../models/ProcessedChapter';
import { ProcessedParagraph } from '../models/ProcessedParagraph';
import { ProcessedSentence } from '../models/ProcessedSentence';
import { ProcessedTextStructure } from '../models/ProcessedTextStructure';
import { ProcessedTextStructure2 } from '../models/ProcessedTextStructure2';
import { ProcessedTextStructure2Structure } from '../models/ProcessedTextStructure2Structure';
import { ProgressStats } from '../models/ProgressStats';
import { Query } from '../models/Query';
import { RecordSentenceKeyLastSeenKeyRecordStats } from '../models/RecordSentenceKeyLastSeenKeyRecordStats';
import { RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats } from '../models/RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats';
import { RegisterAccount } from '../models/RegisterAccount';
import { RoundItemData } from '../models/RoundItemData';
import { Sample } from '../models/Sample';
import { ScoreCard } from '../models/ScoreCard';
import { SegmentSentenceRequest } from '../models/SegmentSentenceRequest';
import { SegmentationOutput } from '../models/SegmentationOutput';
import { SegmentedText } from '../models/SegmentedText';
import { SelectItemConfiguration } from '../models/SelectItemConfiguration';
import { SelectLearningParameters } from '../models/SelectLearningParameters';
import { SelectMistakesParameters } from '../models/SelectMistakesParameters';
import { SelectReviewByOption } from '../models/SelectReviewByOption';
import { SelectUnitParameters } from '../models/SelectUnitParameters';
import { SelectedItems } from '../models/SelectedItems';
import { SentenceConstructionHelpRequest } from '../models/SentenceConstructionHelpRequest';
import { SentenceKeyLastSeen } from '../models/SentenceKeyLastSeen';
import { SentencePageQueryResponse } from '../models/SentencePageQueryResponse';
import { SentenceResult } from '../models/SentenceResult';
import { SentenceSearchKey } from '../models/SentenceSearchKey';
import { SentenceSearchType } from '../models/SentenceSearchType';
import { SentenceSegmentationResult } from '../models/SentenceSegmentationResult';
import { SentenceSelectionMethod } from '../models/SentenceSelectionMethod';
import { SentenceSelectionQuery } from '../models/SentenceSelectionQuery';
import { SentenceSelectionResult } from '../models/SentenceSelectionResult';
import { StatisticsQueryNewItemSummaryType } from '../models/StatisticsQueryNewItemSummaryType';
import { StatisticsQueryRoundSummaryType } from '../models/StatisticsQueryRoundSummaryType';
import { TextCategoryLink } from '../models/TextCategoryLink';
import { TextDifficulty } from '../models/TextDifficulty';
import { TextEntry } from '../models/TextEntry';
import { TextLength } from '../models/TextLength';
import { TextPair } from '../models/TextPair';
import { TextProgressSummary } from '../models/TextProgressSummary';
import { TextPromptType } from '../models/TextPromptType';
import { TextType } from '../models/TextType';
import { TranslateResponse } from '../models/TranslateResponse';
import { TranslateResponseCost } from '../models/TranslateResponseCost';
import { TranslateResponseOutput } from '../models/TranslateResponseOutput';
import { TranslateSentenceRequest } from '../models/TranslateSentenceRequest';
import { UnifiedLookupQueryResponse } from '../models/UnifiedLookupQueryResponse';
import { UnifiedLookupQueryUnit } from '../models/UnifiedLookupQueryUnit';
import { UserGradeState } from '../models/UserGradeState';

import { DefaultApiRequestFactory, DefaultApiResponseProcessor} from "../apis/DefaultApi";
export class ObservableDefaultApi {
    private requestFactory: DefaultApiRequestFactory;
    private responseProcessor: DefaultApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: DefaultApiRequestFactory,
        responseProcessor?: DefaultApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new DefaultApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new DefaultApiResponseProcessor();
    }

    /**
     * @param noteRequest 
     */
    public addNoteWithHttpInfo(noteRequest: NoteRequest, _options?: Configuration): Observable<HttpInfo<boolean>> {
        const requestContextPromise = this.requestFactory.addNote(noteRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.addNoteWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param noteRequest 
     */
    public addNote(noteRequest: NoteRequest, _options?: Configuration): Observable<boolean> {
        return this.addNoteWithHttpInfo(noteRequest, _options).pipe(map((apiResponse: HttpInfo<boolean>) => apiResponse.data));
    }

    /**
     */
    public allNotesWithHttpInfo(_options?: Configuration): Observable<HttpInfo<Array<Note>>> {
        const requestContextPromise = this.requestFactory.allNotes(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.allNotesWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public allNotes(_options?: Configuration): Observable<Array<Note>> {
        return this.allNotesWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<Array<Note>>) => apiResponse.data));
    }

    /**
     * @param query 
     */
    public assistExamplesWithHttpInfo(query: Query, _options?: Configuration): Observable<HttpInfo<TranslateResponse>> {
        const requestContextPromise = this.requestFactory.assistExamples(query, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistExamplesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param query 
     */
    public assistExamples(query: Query, _options?: Configuration): Observable<TranslateResponse> {
        return this.assistExamplesWithHttpInfo(query, _options).pipe(map((apiResponse: HttpInfo<TranslateResponse>) => apiResponse.data));
    }

    /**
     * @param query 
     */
    public assistExplainWithHttpInfo(query: Query, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.assistExplain(query, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistExplainWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param query 
     */
    public assistExplain(query: Query, _options?: Configuration): Observable<string> {
        return this.assistExplainWithHttpInfo(query, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

    /**
     * @param graadeSentenceConstructionRequest 
     */
    public assistGradeSentenceConstructionWithHttpInfo(graadeSentenceConstructionRequest: GraadeSentenceConstructionRequest, _options?: Configuration): Observable<HttpInfo<any>> {
        const requestContextPromise = this.requestFactory.assistGradeSentenceConstruction(graadeSentenceConstructionRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistGradeSentenceConstructionWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param graadeSentenceConstructionRequest 
     */
    public assistGradeSentenceConstruction(graadeSentenceConstructionRequest: GraadeSentenceConstructionRequest, _options?: Configuration): Observable<any> {
        return this.assistGradeSentenceConstructionWithHttpInfo(graadeSentenceConstructionRequest, _options).pipe(map((apiResponse: HttpInfo<any>) => apiResponse.data));
    }

    /**
     * @param sentenceConstructionHelpRequest 
     */
    public assistSentenceConstructionHelpWithHttpInfo(sentenceConstructionHelpRequest: SentenceConstructionHelpRequest, _options?: Configuration): Observable<HttpInfo<any>> {
        const requestContextPromise = this.requestFactory.assistSentenceConstructionHelp(sentenceConstructionHelpRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistSentenceConstructionHelpWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param sentenceConstructionHelpRequest 
     */
    public assistSentenceConstructionHelp(sentenceConstructionHelpRequest: SentenceConstructionHelpRequest, _options?: Configuration): Observable<any> {
        return this.assistSentenceConstructionHelpWithHttpInfo(sentenceConstructionHelpRequest, _options).pipe(map((apiResponse: HttpInfo<any>) => apiResponse.data));
    }

    /**
     * @param query 
     */
    public assistTranslateWithHttpInfo(query: Query, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.assistTranslate(query, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistTranslateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param query 
     */
    public assistTranslate(query: Query, _options?: Configuration): Observable<string> {
        return this.assistTranslateWithHttpInfo(query, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

    /**
     * @param translateSentenceRequest 
     */
    public assistTranslateSentenceWithHttpInfo(translateSentenceRequest: TranslateSentenceRequest, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.assistTranslateSentence(translateSentenceRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistTranslateSentenceWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param translateSentenceRequest 
     */
    public assistTranslateSentence(translateSentenceRequest: TranslateSentenceRequest, _options?: Configuration): Observable<string> {
        return this.assistTranslateSentenceWithHttpInfo(translateSentenceRequest, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

    /**
     * @param assistValidateRequest 
     */
    public assistValidateWithHttpInfo(assistValidateRequest: AssistValidateRequest, _options?: Configuration): Observable<HttpInfo<TranslateResponse>> {
        const requestContextPromise = this.requestFactory.assistValidate(assistValidateRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistValidateWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param assistValidateRequest 
     */
    public assistValidate(assistValidateRequest: AssistValidateRequest, _options?: Configuration): Observable<TranslateResponse> {
        return this.assistValidateWithHttpInfo(assistValidateRequest, _options).pipe(map((apiResponse: HttpInfo<TranslateResponse>) => apiResponse.data));
    }

    /**
     * @param messages 
     */
    public assistantChatWithHttpInfo(messages: Messages, _options?: Configuration): Observable<HttpInfo<string>> {
        const requestContextPromise = this.requestFactory.assistantChat(messages, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.assistantChatWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param messages 
     */
    public assistantChat(messages: Messages, _options?: Configuration): Observable<string> {
        return this.assistantChatWithHttpInfo(messages, _options).pipe(map((apiResponse: HttpInfo<string>) => apiResponse.data));
    }

    /**
     * @param basicStatsQuery 
     */
    public basicStatsWithHttpInfo(basicStatsQuery: BasicStatsQuery, _options?: Configuration): Observable<HttpInfo<BasicStatsResponse>> {
        const requestContextPromise = this.requestFactory.basicStats(basicStatsQuery, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.basicStatsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param basicStatsQuery 
     */
    public basicStats(basicStatsQuery: BasicStatsQuery, _options?: Configuration): Observable<BasicStatsResponse> {
        return this.basicStatsWithHttpInfo(basicStatsQuery, _options).pipe(map((apiResponse: HttpInfo<BasicStatsResponse>) => apiResponse.data));
    }

    /**
     * @param breakdownTextRequest 
     */
    public breakdownTextWithHttpInfo(breakdownTextRequest: BreakdownTextRequest, _options?: Configuration): Observable<HttpInfo<SentenceResult>> {
        const requestContextPromise = this.requestFactory.breakdownText(breakdownTextRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.breakdownTextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param breakdownTextRequest 
     */
    public breakdownText(breakdownTextRequest: BreakdownTextRequest, _options?: Configuration): Observable<SentenceResult> {
        return this.breakdownTextWithHttpInfo(breakdownTextRequest, _options).pipe(map((apiResponse: HttpInfo<SentenceResult>) => apiResponse.data));
    }

    /**
     */
    public calculateScoringWithHttpInfo(_options?: Configuration): Observable<HttpInfo<ScoreCard>> {
        const requestContextPromise = this.requestFactory.calculateScoring(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.calculateScoringWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public calculateScoring(_options?: Configuration): Observable<ScoreCard> {
        return this.calculateScoringWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<ScoreCard>) => apiResponse.data));
    }

    /**
     * @param calculateSentenceDifficultyRequest 
     */
    public calculateSentenceDifficultyWithHttpInfo(calculateSentenceDifficultyRequest: CalculateSentenceDifficultyRequest, _options?: Configuration): Observable<HttpInfo<number>> {
        const requestContextPromise = this.requestFactory.calculateSentenceDifficulty(calculateSentenceDifficultyRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.calculateSentenceDifficultyWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param calculateSentenceDifficultyRequest 
     */
    public calculateSentenceDifficulty(calculateSentenceDifficultyRequest: CalculateSentenceDifficultyRequest, _options?: Configuration): Observable<number> {
        return this.calculateSentenceDifficultyWithHttpInfo(calculateSentenceDifficultyRequest, _options).pipe(map((apiResponse: HttpInfo<number>) => apiResponse.data));
    }

    /**
     * @param ref 
     */
    public calculateTextProgressWithHttpInfo(ref: string, _options?: Configuration): Observable<HttpInfo<TextProgressSummary>> {
        const requestContextPromise = this.requestFactory.calculateTextProgress(ref, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.calculateTextProgressWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param ref 
     */
    public calculateTextProgress(ref: string, _options?: Configuration): Observable<TextProgressSummary> {
        return this.calculateTextProgressWithHttpInfo(ref, _options).pipe(map((apiResponse: HttpInfo<TextProgressSummary>) => apiResponse.data));
    }

    /**
     * @param keyContainer 
     */
    public checkKeyWithHttpInfo(keyContainer: KeyContainer, _options?: Configuration): Observable<HttpInfo<boolean>> {
        const requestContextPromise = this.requestFactory.checkKey(keyContainer, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.checkKeyWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param keyContainer 
     */
    public checkKey(keyContainer: KeyContainer, _options?: Configuration): Observable<boolean> {
        return this.checkKeyWithHttpInfo(keyContainer, _options).pipe(map((apiResponse: HttpInfo<boolean>) => apiResponse.data));
    }

    /**
     */
    public dashboardWithHttpInfo(_options?: Configuration): Observable<HttpInfo<DashboardResponse>> {
        const requestContextPromise = this.requestFactory.dashboard(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.dashboardWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public dashboard(_options?: Configuration): Observable<DashboardResponse> {
        return this.dashboardWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<DashboardResponse>) => apiResponse.data));
    }

    /**
     * @param ref 
     */
    public deleteTextWithHttpInfo(ref: string, _options?: Configuration): Observable<HttpInfo<void>> {
        const requestContextPromise = this.requestFactory.deleteText(ref, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteTextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param ref 
     */
    public deleteText(ref: string, _options?: Configuration): Observable<void> {
        return this.deleteTextWithHttpInfo(ref, _options).pipe(map((apiResponse: HttpInfo<void>) => apiResponse.data));
    }

    /**
     * @param image 
     */
    public evaluateImageWithHttpInfo(image: HttpFile, _options?: Configuration): Observable<HttpInfo<SentenceSegmentationResult>> {
        const requestContextPromise = this.requestFactory.evaluateImage(image, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.evaluateImageWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param image 
     */
    public evaluateImage(image: HttpFile, _options?: Configuration): Observable<SentenceSegmentationResult> {
        return this.evaluateImageWithHttpInfo(image, _options).pipe(map((apiResponse: HttpInfo<SentenceSegmentationResult>) => apiResponse.data));
    }

    /**
     * @param evaluateMeaningRequest 
     */
    public evaluateMeaningWithHttpInfo(evaluateMeaningRequest: EvaluateMeaningRequest, _options?: Configuration): Observable<HttpInfo<number>> {
        const requestContextPromise = this.requestFactory.evaluateMeaning(evaluateMeaningRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.evaluateMeaningWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param evaluateMeaningRequest 
     */
    public evaluateMeaning(evaluateMeaningRequest: EvaluateMeaningRequest, _options?: Configuration): Observable<number> {
        return this.evaluateMeaningWithHttpInfo(evaluateMeaningRequest, _options).pipe(map((apiResponse: HttpInfo<number>) => apiResponse.data));
    }

    /**
     * @param reference 
     * @param audio 
     */
    public evaluatePronunciationWithHttpInfo(reference: string, audio: HttpFile, _options?: Configuration): Observable<HttpInfo<any>> {
        const requestContextPromise = this.requestFactory.evaluatePronunciation(reference, audio, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.evaluatePronunciationWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param reference 
     * @param audio 
     */
    public evaluatePronunciation(reference: string, audio: HttpFile, _options?: Configuration): Observable<any> {
        return this.evaluatePronunciationWithHttpInfo(reference, audio, _options).pipe(map((apiResponse: HttpInfo<any>) => apiResponse.data));
    }

    /**
     * @param evaluateTranslationRequest2 
     */
    public evaluateTranslation2WithHttpInfo(evaluateTranslationRequest2: EvaluateTranslationRequest2, _options?: Configuration): Observable<HttpInfo<number>> {
        const requestContextPromise = this.requestFactory.evaluateTranslation2(evaluateTranslationRequest2, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.evaluateTranslation2WithHttpInfo(rsp)));
            }));
    }

    /**
     * @param evaluateTranslationRequest2 
     */
    public evaluateTranslation2(evaluateTranslationRequest2: EvaluateTranslationRequest2, _options?: Configuration): Observable<number> {
        return this.evaluateTranslation2WithHttpInfo(evaluateTranslationRequest2, _options).pipe(map((apiResponse: HttpInfo<number>) => apiResponse.data));
    }

    /**
     * @param apiRoundRecord 
     */
    public finishRoundWithHttpInfo(apiRoundRecord: ApiRoundRecord, _options?: Configuration): Observable<HttpInfo<any>> {
        const requestContextPromise = this.requestFactory.finishRound(apiRoundRecord, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.finishRoundWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param apiRoundRecord 
     */
    public finishRound(apiRoundRecord: ApiRoundRecord, _options?: Configuration): Observable<any> {
        return this.finishRoundWithHttpInfo(apiRoundRecord, _options).pipe(map((apiResponse: HttpInfo<any>) => apiResponse.data));
    }

    /**
     * @param generateAudioRequest 
     */
    public generateAudioWithHttpInfo(generateAudioRequest: GenerateAudioRequest, _options?: Configuration): Observable<HttpInfo<GenerateAudioResponse>> {
        const requestContextPromise = this.requestFactory.generateAudio(generateAudioRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.generateAudioWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param generateAudioRequest 
     */
    public generateAudio(generateAudioRequest: GenerateAudioRequest, _options?: Configuration): Observable<GenerateAudioResponse> {
        return this.generateAudioWithHttpInfo(generateAudioRequest, _options).pipe(map((apiResponse: HttpInfo<GenerateAudioResponse>) => apiResponse.data));
    }

    /**
     * @param generateTextRequest 
     */
    public generateTextWithHttpInfo(generateTextRequest: GenerateTextRequest, _options?: Configuration): Observable<HttpInfo<ProcessedTextStructure>> {
        const requestContextPromise = this.requestFactory.generateText(generateTextRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.generateTextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param generateTextRequest 
     */
    public generateText(generateTextRequest: GenerateTextRequest, _options?: Configuration): Observable<ProcessedTextStructure> {
        return this.generateTextWithHttpInfo(generateTextRequest, _options).pipe(map((apiResponse: HttpInfo<ProcessedTextStructure>) => apiResponse.data));
    }

    /**
     * @param getActionRecordRequest 
     */
    public getActionRecordsWithHttpInfo(getActionRecordRequest: GetActionRecordRequest, _options?: Configuration): Observable<HttpInfo<Array<ActionRecord>>> {
        const requestContextPromise = this.requestFactory.getActionRecords(getActionRecordRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getActionRecordsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param getActionRecordRequest 
     */
    public getActionRecords(getActionRecordRequest: GetActionRecordRequest, _options?: Configuration): Observable<Array<ActionRecord>> {
        return this.getActionRecordsWithHttpInfo(getActionRecordRequest, _options).pipe(map((apiResponse: HttpInfo<Array<ActionRecord>>) => apiResponse.data));
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getKeyRecordsWithHttpInfo(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Observable<HttpInfo<Array<KeyRecord>>> {
        const requestContextPromise = this.requestFactory.getKeyRecords(sentenceSelectionQuery, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getKeyRecordsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getKeyRecords(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Observable<Array<KeyRecord>> {
        return this.getKeyRecordsWithHttpInfo(sentenceSelectionQuery, _options).pipe(map((apiResponse: HttpInfo<Array<KeyRecord>>) => apiResponse.data));
    }

    /**
     * @param selectMistakesParameters 
     */
    public getMistakesWithHttpInfo(selectMistakesParameters: SelectMistakesParameters, _options?: Configuration): Observable<HttpInfo<SelectedItems>> {
        const requestContextPromise = this.requestFactory.getMistakes(selectMistakesParameters, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getMistakesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param selectMistakesParameters 
     */
    public getMistakes(selectMistakesParameters: SelectMistakesParameters, _options?: Configuration): Observable<SelectedItems> {
        return this.getMistakesWithHttpInfo(selectMistakesParameters, _options).pipe(map((apiResponse: HttpInfo<SelectedItems>) => apiResponse.data));
    }

    /**
     * @param getNotesRequest 
     */
    public getNotesWithHttpInfo(getNotesRequest: GetNotesRequest, _options?: Configuration): Observable<HttpInfo<Array<Note>>> {
        const requestContextPromise = this.requestFactory.getNotes(getNotesRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getNotesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param getNotesRequest 
     */
    public getNotes(getNotesRequest: GetNotesRequest, _options?: Configuration): Observable<Array<Note>> {
        return this.getNotesWithHttpInfo(getNotesRequest, _options).pipe(map((apiResponse: HttpInfo<Array<Note>>) => apiResponse.data));
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getSearchKeysWithHttpInfo(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Observable<HttpInfo<Array<string>>> {
        const requestContextPromise = this.requestFactory.getSearchKeys(sentenceSelectionQuery, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getSearchKeysWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getSearchKeys(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Observable<Array<string>> {
        return this.getSearchKeysWithHttpInfo(sentenceSelectionQuery, _options).pipe(map((apiResponse: HttpInfo<Array<string>>) => apiResponse.data));
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getSentencesBySelectionWithHttpInfo(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Observable<HttpInfo<SentenceSelectionResult>> {
        const requestContextPromise = this.requestFactory.getSentencesBySelection(sentenceSelectionQuery, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getSentencesBySelectionWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param sentenceSelectionQuery 
     */
    public getSentencesBySelection(sentenceSelectionQuery: SentenceSelectionQuery, _options?: Configuration): Observable<SentenceSelectionResult> {
        return this.getSentencesBySelectionWithHttpInfo(sentenceSelectionQuery, _options).pipe(map((apiResponse: HttpInfo<SentenceSelectionResult>) => apiResponse.data));
    }

    /**
     * @param count 
     * @param page 
     */
    public getSentencesByUnitOrderWithHttpInfo(count: number, page: number, _options?: Configuration): Observable<HttpInfo<SentencePageQueryResponse>> {
        const requestContextPromise = this.requestFactory.getSentencesByUnitOrder(count, page, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getSentencesByUnitOrderWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param count 
     * @param page 
     */
    public getSentencesByUnitOrder(count: number, page: number, _options?: Configuration): Observable<SentencePageQueryResponse> {
        return this.getSentencesByUnitOrderWithHttpInfo(count, page, _options).pipe(map((apiResponse: HttpInfo<SentencePageQueryResponse>) => apiResponse.data));
    }

    /**
     * @param word 
     */
    public getSentencesWithWordWithHttpInfo(word: string, _options?: Configuration): Observable<HttpInfo<Array<GetSentencesWithWord200ResponseInner>>> {
        const requestContextPromise = this.requestFactory.getSentencesWithWord(word, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getSentencesWithWordWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param word 
     */
    public getSentencesWithWord(word: string, _options?: Configuration): Observable<Array<GetSentencesWithWord200ResponseInner>> {
        return this.getSentencesWithWordWithHttpInfo(word, _options).pipe(map((apiResponse: HttpInfo<Array<GetSentencesWithWord200ResponseInner>>) => apiResponse.data));
    }

    /**
     * @param ref 
     */
    public getTextWithHttpInfo(ref: string, _options?: Configuration): Observable<HttpInfo<ProcessedTextStructure>> {
        const requestContextPromise = this.requestFactory.getText(ref, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getTextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param ref 
     */
    public getText(ref: string, _options?: Configuration): Observable<ProcessedTextStructure> {
        return this.getTextWithHttpInfo(ref, _options).pipe(map((apiResponse: HttpInfo<ProcessedTextStructure>) => apiResponse.data));
    }

    /**
     */
    public getTextCategoriesWithHttpInfo(_options?: Configuration): Observable<HttpInfo<Array<string>>> {
        const requestContextPromise = this.requestFactory.getTextCategories(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getTextCategoriesWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public getTextCategories(_options?: Configuration): Observable<Array<string>> {
        return this.getTextCategoriesWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<Array<string>>) => apiResponse.data));
    }

    /**
     */
    public getTextCategoryLinksWithHttpInfo(_options?: Configuration): Observable<HttpInfo<Array<TextCategoryLink>>> {
        const requestContextPromise = this.requestFactory.getTextCategoryLinks(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getTextCategoryLinksWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public getTextCategoryLinks(_options?: Configuration): Observable<Array<TextCategoryLink>> {
        return this.getTextCategoryLinksWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<Array<TextCategoryLink>>) => apiResponse.data));
    }

    /**
     */
    public getTextsWithHttpInfo(_options?: Configuration): Observable<HttpInfo<Array<TextEntry>>> {
        const requestContextPromise = this.requestFactory.getTexts(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getTextsWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public getTexts(_options?: Configuration): Observable<Array<TextEntry>> {
        return this.getTextsWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<Array<TextEntry>>) => apiResponse.data));
    }

    /**
     * @param ref 
     */
    public gradeTextWithHttpInfo(ref: string, _options?: Configuration): Observable<HttpInfo<{ [key: string]: number; }>> {
        const requestContextPromise = this.requestFactory.gradeText(ref, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.gradeTextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param ref 
     */
    public gradeText(ref: string, _options?: Configuration): Observable<{ [key: string]: number; }> {
        return this.gradeTextWithHttpInfo(ref, _options).pipe(map((apiResponse: HttpInfo<{ [key: string]: number; }>) => apiResponse.data));
    }

    /**
     * @param unitId 
     * @param itemType 
     * @param grade 
     */
    public itemViewByGradeWithHttpInfo(unitId: number, itemType: number, grade: number, _options?: Configuration): Observable<HttpInfo<{ [key: string]: PairedItem; }>> {
        const requestContextPromise = this.requestFactory.itemViewByGrade(unitId, itemType, grade, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.itemViewByGradeWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param unitId 
     * @param itemType 
     * @param grade 
     */
    public itemViewByGrade(unitId: number, itemType: number, grade: number, _options?: Configuration): Observable<{ [key: string]: PairedItem; }> {
        return this.itemViewByGradeWithHttpInfo(unitId, itemType, grade, _options).pipe(map((apiResponse: HttpInfo<{ [key: string]: PairedItem; }>) => apiResponse.data));
    }

    /**
     */
    public libraryDashboardWithHttpInfo(_options?: Configuration): Observable<HttpInfo<LibraryDashboardResponse>> {
        const requestContextPromise = this.requestFactory.libraryDashboard(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.libraryDashboardWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public libraryDashboard(_options?: Configuration): Observable<LibraryDashboardResponse> {
        return this.libraryDashboardWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<LibraryDashboardResponse>) => apiResponse.data));
    }

    /**
     * @param lookupQuery 
     */
    public lookupQueryWithHttpInfo(lookupQuery: LookupQuery, _options?: Configuration): Observable<HttpInfo<DictionaryQueryResult>> {
        const requestContextPromise = this.requestFactory.lookupQuery(lookupQuery, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.lookupQueryWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param lookupQuery 
     */
    public lookupQuery(lookupQuery: LookupQuery, _options?: Configuration): Observable<DictionaryQueryResult> {
        return this.lookupQueryWithHttpInfo(lookupQuery, _options).pipe(map((apiResponse: HttpInfo<DictionaryQueryResult>) => apiResponse.data));
    }

    /**
     * @param actionRecord 
     */
    public processActionRecordWithHttpInfo(actionRecord: Array<ActionRecord>, _options?: Configuration): Observable<HttpInfo<boolean>> {
        const requestContextPromise = this.requestFactory.processActionRecord(actionRecord, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.processActionRecordWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param actionRecord 
     */
    public processActionRecord(actionRecord: Array<ActionRecord>, _options?: Configuration): Observable<boolean> {
        return this.processActionRecordWithHttpInfo(actionRecord, _options).pipe(map((apiResponse: HttpInfo<boolean>) => apiResponse.data));
    }

    /**
     * @param processTextRequest 
     */
    public processTextWithHttpInfo(processTextRequest: ProcessTextRequest, _options?: Configuration): Observable<HttpInfo<ProcessTextResponse>> {
        const requestContextPromise = this.requestFactory.processText(processTextRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.processTextWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param processTextRequest 
     */
    public processText(processTextRequest: ProcessTextRequest, _options?: Configuration): Observable<ProcessTextResponse> {
        return this.processTextWithHttpInfo(processTextRequest, _options).pipe(map((apiResponse: HttpInfo<ProcessTextResponse>) => apiResponse.data));
    }

    /**
     * @param processTextRequest2 
     */
    public processText2WithHttpInfo(processTextRequest2: ProcessTextRequest2, _options?: Configuration): Observable<HttpInfo<ProcessedTextStructure2>> {
        const requestContextPromise = this.requestFactory.processText2(processTextRequest2, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.processText2WithHttpInfo(rsp)));
            }));
    }

    /**
     * @param processTextRequest2 
     */
    public processText2(processTextRequest2: ProcessTextRequest2, _options?: Configuration): Observable<ProcessedTextStructure2> {
        return this.processText2WithHttpInfo(processTextRequest2, _options).pipe(map((apiResponse: HttpInfo<ProcessedTextStructure2>) => apiResponse.data));
    }

    /**
     * @param registerAccount 
     */
    public registerWithHttpInfo(registerAccount: RegisterAccount, _options?: Configuration): Observable<HttpInfo<boolean>> {
        const requestContextPromise = this.requestFactory.register(registerAccount, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.registerWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param registerAccount 
     */
    public register(registerAccount: RegisterAccount, _options?: Configuration): Observable<boolean> {
        return this.registerWithHttpInfo(registerAccount, _options).pipe(map((apiResponse: HttpInfo<boolean>) => apiResponse.data));
    }

    /**
     */
    public reviewsDueTodayWithHttpInfo(_options?: Configuration): Observable<HttpInfo<{ [key: string]: number; }>> {
        const requestContextPromise = this.requestFactory.reviewsDueToday(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.reviewsDueTodayWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public reviewsDueToday(_options?: Configuration): Observable<{ [key: string]: number; }> {
        return this.reviewsDueTodayWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<{ [key: string]: number; }>) => apiResponse.data));
    }

    /**
     * @param segmentSentenceRequest 
     */
    public segmentSentencesWithHttpInfo(segmentSentenceRequest: SegmentSentenceRequest, _options?: Configuration): Observable<HttpInfo<SentenceSegmentationResult>> {
        const requestContextPromise = this.requestFactory.segmentSentences(segmentSentenceRequest, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.segmentSentencesWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param segmentSentenceRequest 
     */
    public segmentSentences(segmentSentenceRequest: SegmentSentenceRequest, _options?: Configuration): Observable<SentenceSegmentationResult> {
        return this.segmentSentencesWithHttpInfo(segmentSentenceRequest, _options).pipe(map((apiResponse: HttpInfo<SentenceSegmentationResult>) => apiResponse.data));
    }

    /**
     * @param selectLearningParameters 
     */
    public selectCurrentLearningWithHttpInfo(selectLearningParameters: SelectLearningParameters, _options?: Configuration): Observable<HttpInfo<SelectedItems>> {
        const requestContextPromise = this.requestFactory.selectCurrentLearning(selectLearningParameters, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.selectCurrentLearningWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param selectLearningParameters 
     */
    public selectCurrentLearning(selectLearningParameters: SelectLearningParameters, _options?: Configuration): Observable<SelectedItems> {
        return this.selectCurrentLearningWithHttpInfo(selectLearningParameters, _options).pipe(map((apiResponse: HttpInfo<SelectedItems>) => apiResponse.data));
    }

    /**
     * @param selectUnitParameters 
     */
    public selectUnitsWithHttpInfo(selectUnitParameters: SelectUnitParameters, _options?: Configuration): Observable<HttpInfo<SelectedItems>> {
        const requestContextPromise = this.requestFactory.selectUnits(selectUnitParameters, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.selectUnitsWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param selectUnitParameters 
     */
    public selectUnits(selectUnitParameters: SelectUnitParameters, _options?: Configuration): Observable<SelectedItems> {
        return this.selectUnitsWithHttpInfo(selectUnitParameters, _options).pipe(map((apiResponse: HttpInfo<SelectedItems>) => apiResponse.data));
    }

    /**
     */
    public sentenceKeyRecordStatsWithHttpInfo(_options?: Configuration): Observable<HttpInfo<RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats>> {
        const requestContextPromise = this.requestFactory.sentenceKeyRecordStats(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.sentenceKeyRecordStatsWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public sentenceKeyRecordStats(_options?: Configuration): Observable<RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats> {
        return this.sentenceKeyRecordStatsWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats>) => apiResponse.data));
    }

    /**
     * @param lookupQuery 
     */
    public unifiedLookupWithHttpInfo(lookupQuery: LookupQuery, _options?: Configuration): Observable<HttpInfo<UnifiedLookupQueryResponse>> {
        const requestContextPromise = this.requestFactory.unifiedLookup(lookupQuery, _options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.unifiedLookupWithHttpInfo(rsp)));
            }));
    }

    /**
     * @param lookupQuery 
     */
    public unifiedLookup(lookupQuery: LookupQuery, _options?: Configuration): Observable<UnifiedLookupQueryResponse> {
        return this.unifiedLookupWithHttpInfo(lookupQuery, _options).pipe(map((apiResponse: HttpInfo<UnifiedLookupQueryResponse>) => apiResponse.data));
    }

    /**
     */
    public viewGradesWithHttpInfo(_options?: Configuration): Observable<HttpInfo<Array<UserGradeState>>> {
        const requestContextPromise = this.requestFactory.viewGrades(_options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.viewGradesWithHttpInfo(rsp)));
            }));
    }

    /**
     */
    public viewGrades(_options?: Configuration): Observable<Array<UserGradeState>> {
        return this.viewGradesWithHttpInfo(_options).pipe(map((apiResponse: HttpInfo<Array<UserGradeState>>) => apiResponse.data));
    }

}
