import { useContext, useState } from "react"
import { TextStyle } from "../App"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { ZhLanguageItemTypes, ZhLanguageItemTypesText, ZhLanguageUnitTypes, ZhLanguageUnitTypesText } from "../Config/Language/Zh/Types"
import { ColumnLayout, InnerTile, RowLayout } from "../Styles/Styles"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { Paginator } from "../Components/Paginator"
import { ItemData, ItemHistory, SentenceKeyLastSeen, SentenceSearchType, SentenceSearchKey, SentenceSelectionMethod, SentenceSelectionQuery } from "../api"
import { Content } from "antd/es/layout/layout"
import { useQuery } from "@tanstack/react-query"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { Modal } from "../Components/Modal"
import { ZhUnitTestInterfaceConfig } from "../Config/Language/Zh/Config/UnitTestInterfaceConfig"
import { PracticeItemView } from "./PracticeView"
import dayjs from "dayjs"
import { ExampleSentenceDisplay, ItemExamplesView, LoadPrimarySentenceTextDisplay } from "../Components/ItemExamplesView"
import { AnnotatedTextDisplay } from "../Displays/TextStructureDisplay"
import { PlayTextAudio } from "../Components/PlayTextAudio"
import { OptionsIcon } from "../Icons/OptionsIcon"
import { SentenceReaderView } from "./SentenceReaderView"
import { Row } from "antd"

export const SentencePageQueryView = () => {

    const [page, setPage] = useState(1)
    const [count, setCount] = useState(10)
    const [grade, setGrade] = useState<number>(0)
    const [showItemData, setShowItemData] = useState(false)
    const [selectionMethod, setSelectionMethod] = useState<SentenceSelectionMethod>(SentenceSelectionMethod.Recent)

    const [baseUnits, setBaseUnits] = useState<SentenceSearchKey>(SentenceSearchKey.New)
    const [lastSeen, setLastSeen] = useState<SentenceKeyLastSeen>(SentenceKeyLastSeen.Today)
    const [sentenceType, setSentenceType] = useState<SentenceSearchType>(SentenceSearchType.AllKnown)

    const [autoPlay, setAutoPlay] = useState(false)
    const [autoProgress, setAutoProgress] = useState(false)

    const [hideParameters, setHideParameters] = useState(false)

    const Show = () => {
        setHideParameters(true)
        setShowItemData(true)
    }

    const ShowParameters = () => {
        setHideParameters(false)
    }

    return <ContentTile>

        {!hideParameters && <ColumnLayout>
            <RowLayout>
                <TextStyle>Count</TextStyle>
                <input type="number" value={count} onChange={(e) => setCount(e.target.value as unknown as number)} />
            </RowLayout>
            <RowLayout>
                <TextStyle>Grade</TextStyle>
                <select value={grade} onChange={(e) => setGrade(Number.parseInt(e.target.value as unknown as string))}>
                    <option value="0">Current</option>
                    <option value="-1">All</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                </select>
            </RowLayout>
            <RowLayout>
                <TextStyle>Search Key</TextStyle>
                <select value={baseUnits} onChange={(e) => setBaseUnits(e.target.value as unknown as SentenceSearchKey)}>
                    <option value={SentenceSearchKey.New}>New</option>
                    <option value={SentenceSearchKey.Reviewed}>Reviewed</option>
                    <option value={SentenceSearchKey.Lapsed}>Lapsed</option>
                    <option value={SentenceSearchKey.Custom}>Custom</option>
                </select>
            </RowLayout>

            <RowLayout>
                <TextStyle>Last Seen</TextStyle>
                <select value={lastSeen} onChange={(e) => setLastSeen(e.target.value as unknown as SentenceKeyLastSeen)}>
                    <option value={SentenceKeyLastSeen.Today}>Today</option>
                    <option value={SentenceKeyLastSeen.Yesterday}>Yesterday</option>
                    <option value={SentenceKeyLastSeen.ThisPeriod}>This period</option>
                    <option value={SentenceKeyLastSeen.LastPeriod}>Last period</option>
                    <option value={SentenceKeyLastSeen.AnyTime}>Any time</option>
                </select>
            </RowLayout>
            <RowLayout>
                <TextStyle>Sentence Type</TextStyle>
                <select value={sentenceType} onChange={(e) => setSentenceType(e.target.value as unknown as SentenceSearchType)}>
                    <option value={SentenceSearchType.AllKnown}>All known</option>
                    <option value={SentenceSearchType.Nplusone}>N+1</option>
                    <option value={SentenceSearchType.Nplus2}>N+2</option>
                    <option value={SentenceSearchType.Unrestricted}>Unrestricted</option>
                </select>
            </RowLayout>
            <RowLayout>
                <TextStyle>Auto Play</TextStyle>
                <input type="checkbox" checked={autoPlay} onChange={(e) => setAutoPlay(e.target.checked)} />
            </RowLayout>
            <RowLayout>
                <TextStyle>Auto Progress</TextStyle>
                <input type="checkbox" checked={autoProgress} onChange={(e) => setAutoProgress(e.target.checked)} />
            </RowLayout>

            {/* <RowLayout>
                <select value={selectionMethod} onChange={(e) => setSelectionMethod(e.target.value as unknown as SentenceSelectionMethod)}>
                    <option value={SentenceSelectionMethod.Recent}>Recent</option>
                    <option value={SentenceSelectionMethod.NewTodayKnown}>New Today Known</option>
                    <option value={SentenceSelectionMethod.Known}>Known</option>
                    <option value={SentenceSelectionMethod.Nplusone}>N+1</option>
                </select>
            </RowLayout> */}
            <StyledButton onClick={Show}>Search</StyledButton>
        </ColumnLayout>}
        {hideParameters && <RowLayout><StyledButton onClick={ShowParameters}>Show Parameters</StyledButton></RowLayout>}
        {showItemData && <LoadSentenceSelectionDisplay grade={grade} autoPlay={autoPlay} autoProgress={autoProgress} selectionMethod={selectionMethod} count={count} baseUnits={baseUnits} lastSeen={lastSeen} sentenceType={sentenceType} />}


    </ContentTile>
}

const LoadSentenceSelectionDisplay: React.FC<{ autoPlay: boolean, autoProgress: boolean, selectionMethod: SentenceSelectionMethod, count: number, baseUnits: SentenceSearchKey, lastSeen: SentenceKeyLastSeen, sentenceType: SentenceSearchType, grade: number }> = props => {
    const api = useContext(ApiContext)

    const [refreshToken, setRefreshToken] = useState(0)

    const Reload = () => {
        setRefreshToken(refreshToken + 1)
    }

    const { isPending, error, data } = useQuery({
        queryKey: ["sentenceSelection", props.selectionMethod, props.count, refreshToken],
        queryFn: () => {
            const query: SentenceSelectionQuery = {
                selectionMethod: props.selectionMethod,
                count: Number.parseInt(props.count.toString()),
                baseUnits: props.baseUnits,
                lastSeen: props.lastSeen,
                sentenceType: props.sentenceType,
                keyInput: [],
                gradeQuery: props.grade
            }
            console.log(query)
            return api.SentenceSelection(query).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ColumnLayout>
            <RowLayout style={{ padding: 24 }}>
                <TextStyle>Showing</TextStyle>
                <TextStyle weight="bold">{props.count}</TextStyle>
                <TextStyle>of</TextStyle>
                <TextStyle weight="bold">{data.total}</TextStyle>
                <TextStyle>sentences</TextStyle>
            </RowLayout>
            {data.sentences.length > 0 && <SentenceReaderView Data={{ Sentences: data.sentences, AutoPlay: props.autoPlay, AutoProgress: props.autoProgress }} Reload={Reload} OnExit={() => { setRefreshToken(refreshToken + 1) }} />}
            {/* {data.map((sentence) => {
                return <ExampleSentenceDisplay key={sentence.ref} text={sentence.text} sentenceRef={sentence.ref} segments={sentence.segments} />
            })} */}
            <RowLayout>
                <StyledButton onClick={Reload}>Reload</StyledButton>
            </RowLayout>

        </ColumnLayout>
    </>
}
