import { useContext } from "react"
import { DashboardResponse } from "../api"
import { TextStyle } from "../App"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { useQuery } from "@tanstack/react-query"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { ZhLanguageTestTypes } from "../Config/Language/Zh/Types"



export const ScoreCardView: React.FC = (props) => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['score-card'],
        queryFn: () => api.ScoreCard().then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity

    })


    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error?.message}</>
    if (data == undefined) return <>An error has occurred: { }</>


    return <>
        <ColumnLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
            <TextStyle size={1.5}>Score Card</TextStyle>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Today</TextStyle>
                <TextStyle>{data.todayScore.toFixed(2)}</TextStyle>
                <TextStyle weight='bold'>Period</TextStyle>
                <TextStyle>{data.periodScore.toFixed(2)}</TextStyle>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Daily Goals</TextStyle>
                <TextStyle>{data.dailyGoalMet ? '🟢' : '🔴'}</TextStyle>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle size={0.75} weight='bold'>New</TextStyle>
                <TextStyle size={0.75}>{data.dailyGoalProgress.newItem} / {data.dailyGoals.newItem}</TextStyle>
                <TextStyle size={0.75} weight='bold'>Review</TextStyle>
                <TextStyle size={0.75}>{data.dailyGoalProgress.review} / {data.dailyGoals.review}</TextStyle>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle size={0.75} weight='bold'>Sentences</TextStyle>
                <TextStyle size={0.75}>{data.dailyGoalProgress.sentenceRead} / {data.dailyGoals.sentenceRead}</TextStyle>
                <TextStyle size={0.75} weight='bold'>New</TextStyle>
                <TextStyle size={0.75}>{data.dailyGoalProgress.newItemsRead!!} / {data.dailyGoalProgress.newItem}</TextStyle>
            </RowLayout>

        </ColumnLayout>
    </>



}

export const StudyDashboardTile: React.FC = (props) => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['dashboard'],
        queryFn: () => api.Dashboard().then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity

    })


    const { isPending: isPendingReviewsDueToday, error: errorReviewsDueToday, data: dataReviewsDueToday } = useQuery({
        queryKey: ['reviews-due-today'],
        queryFn: () => api.ReviewsDueToday().then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity

    })
    if (isPending || isPendingReviewsDueToday) return <>Loading...</>
    if (error || errorReviewsDueToday) return <>An error has occurred: {error?.message ?? errorReviewsDueToday?.message}</>
    if (data == undefined || dataReviewsDueToday == undefined) return <>An error has occurred: { }</>




    return <>
        <ColumnLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Due</TextStyle>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <ColumnLayout style={{ width: 'auto' }}>
                    <TextStyle weight='bold' size={0.6}>Meaning</TextStyle>
                    <TextStyle size={0.8}>{dataReviewsDueToday[ZhLanguageTestTypes.GlyphToMeaning]}</TextStyle>
                </ColumnLayout>
                <ColumnLayout style={{ width: 'auto' }}>
                    <TextStyle weight='bold' size={0.6}>Writing</TextStyle>
                    <TextStyle size={0.8}>{dataReviewsDueToday[ZhLanguageTestTypes.MeaningReadingToWrittenGlyph]}</TextStyle>
                </ColumnLayout>
                <ColumnLayout style={{ width: 'auto' }}>
                    <TextStyle weight='bold' size={0.6}>Speaking</TextStyle>
                    <TextStyle size={0.8}>{dataReviewsDueToday[ZhLanguageTestTypes.GlyphToSpokenReading]}</TextStyle>
                </ColumnLayout>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Minutes studied</TextStyle>
                <TextStyle>{data.minutesStudiedToday}</TextStyle>
            </RowLayout>
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                {data.studiedTodayCheck && <TextStyle>🟢</TextStyle>}
                {!data.studiedTodayCheck && data.studiedYesterdayCheck && <TextStyle>🟡</TextStyle>}
                {!data.studiedTodayCheck && !data.studiedYesterdayCheck && <TextStyle>🔴</TextStyle>}
            </RowLayout>
            {data.studiedYesterdayCheck &&
                <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <TextStyle weight='bold'>Current Streak</TextStyle>
                    <TextStyle>{data.currentStreak}</TextStyle>
                </RowLayout>
            }
            <RowLayout style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextStyle weight='bold'>Longest Streak</TextStyle>
                <TextStyle>{data.longestStreak}</TextStyle>
            </RowLayout>
        </ColumnLayout>
    </>



}

export const SentenceDashboardTile: React.FC = (props) => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['sentence-key-record-stats'],
        queryFn: () => api.SentenceKeyRecordStats().then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity

    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error?.message}</>
    if (data == undefined) return <>An error has occurred: { }</>



    return <>
        <ColumnLayout style={{ justifyContent: 'start', alignItems: 'start', gap: 4 }}>
            <TextStyle weight="bold" size={0.75} style={{ fontStyle: 'italic' }}>Today</TextStyle>
            <RowLayout style={{ gap: 6 }}>
                <TextStyle size={0.75} style={{ fontStyle: 'italic' }}>New</TextStyle>
                <TextStyle weight="bold" size={0.75}>Seen</TextStyle>
                <TextStyle size={0.75}>{data["_new"]["today"].seen}</TextStyle>
                <TextStyle weight="bold" size={0.75}>Total</TextStyle>
                <TextStyle size={0.75}>{data["_new"]["today"].total}</TextStyle>
                {data["_new"]["today"].total > 0 && <TextStyle style={{ fontStyle: 'italic' }} size={0.75}>({((data["_new"]["today"].seen / data["_new"]["today"].total) * 100).toFixed(1)}%)</TextStyle>}
            </RowLayout>
            <RowLayout style={{ gap: 6 }}>
                <TextStyle size={0.75} style={{ fontStyle: 'italic' }}>Reviewed</TextStyle>
                <TextStyle weight="bold" size={0.75}>Seen</TextStyle>
                <TextStyle size={0.75}>{data["reviewed"]["today"].seen}</TextStyle>
                <TextStyle weight="bold" size={0.75}>Total</TextStyle>
                <TextStyle size={0.75}>{data["reviewed"]["today"].total}</TextStyle>
                {data["reviewed"]["today"].total > 0 && <TextStyle style={{ fontStyle: 'italic' }} size={0.75}>({((data["reviewed"]["today"].seen / data["reviewed"]["today"].total) * 100).toFixed(1)}%)</TextStyle>}
            </RowLayout>
            <TextStyle weight="bold" size={0.75} style={{ fontStyle: 'italic' }}>Yesterday</TextStyle>
            <RowLayout style={{ gap: 6 }}>
                <TextStyle size={0.75} style={{ fontStyle: 'italic' }}>New</TextStyle>
                <TextStyle weight="bold" size={0.75}>Seen</TextStyle>
                <TextStyle size={0.75}>{data["_new"]["yesterday"].seen}</TextStyle>
                <TextStyle weight="bold" size={0.75}>Total</TextStyle>
                <TextStyle size={0.75}>{data["_new"]["yesterday"].total}</TextStyle>
                {data["_new"]["yesterday"].total > 0 && <TextStyle style={{ fontStyle: 'italic' }} size={0.75}>({((data["_new"]["yesterday"].seen / data["_new"]["yesterday"].total) * 100).toFixed(1)}%)</TextStyle>}
            </RowLayout>
            <RowLayout style={{ gap: 6 }}>
                <TextStyle size={0.75} style={{ fontStyle: 'italic' }}>Reviewed</TextStyle>
                <TextStyle weight="bold" size={0.75}>Seen</TextStyle>
                <TextStyle size={0.75}>{data["reviewed"]["yesterday"].seen}</TextStyle>
                <TextStyle weight="bold" size={0.75}>Total</TextStyle>
                <TextStyle size={0.75}>{data["reviewed"]["yesterday"].total}</TextStyle>
                {data["reviewed"]["yesterday"].total > 0 && <TextStyle style={{ fontStyle: 'italic' }} size={0.75}>({((data["reviewed"]["yesterday"].seen / data["reviewed"]["yesterday"].total) * 100).toFixed(1)}%)</TextStyle>}
            </RowLayout>
            <TextStyle weight="bold" size={0.75} style={{ fontStyle: 'italic' }}>This Period</TextStyle>
            <RowLayout style={{ gap: 6 }}>
                <TextStyle size={0.75} style={{ fontStyle: 'italic' }}>New</TextStyle>
                <TextStyle weight="bold" size={0.75}>Seen</TextStyle>
                <TextStyle size={0.75}>{data["_new"]["thisPeriod"].seen}</TextStyle>
                <TextStyle weight="bold" size={0.75}>Total</TextStyle>
                <TextStyle size={0.75}>{data["_new"]["thisPeriod"].total}</TextStyle>
                {data["_new"]["thisPeriod"].total > 0 && <TextStyle style={{ fontStyle: 'italic' }} size={0.75}>({((data["_new"]["thisPeriod"].seen / data["_new"]["thisPeriod"].total) * 100).toFixed(1)}%)</TextStyle>}
            </RowLayout>
            <RowLayout style={{ gap: 6 }}>
                <TextStyle size={0.75} style={{ fontStyle: 'italic' }}>Reviewed</TextStyle>
                <TextStyle weight="bold" size={0.75}>Seen</TextStyle>
                <TextStyle size={0.75}>{data["reviewed"]["thisPeriod"].seen}</TextStyle>
                <TextStyle weight="bold" size={0.75}>Total</TextStyle>
                <TextStyle size={0.75}>{data["reviewed"]["thisPeriod"].total}</TextStyle>
                {data["reviewed"]["thisPeriod"].total > 0 && <TextStyle style={{ fontStyle: 'italic' }} size={0.75}>({((data["reviewed"]["thisPeriod"].seen / data["reviewed"]["thisPeriod"].total) * 100).toFixed(1)}%)</TextStyle>}
            </RowLayout>
            <TextStyle weight="bold" size={0.75} style={{ fontStyle: 'italic' }}>Last Period</TextStyle>
            <RowLayout style={{ gap: 6 }}>
                <TextStyle size={0.75} style={{ fontStyle: 'italic' }}>New</TextStyle>
                <TextStyle weight="bold" size={0.75}>Seen</TextStyle>
                <TextStyle size={0.75}>{data["_new"]["lastPeriod"].seen}</TextStyle>
                <TextStyle weight="bold" size={0.75}>Total</TextStyle>
                <TextStyle size={0.75}>{data["_new"]["lastPeriod"].total}</TextStyle>
                {data["_new"]["lastPeriod"].total > 0 && <TextStyle style={{ fontStyle: 'italic' }} size={0.75}>({((data["_new"]["lastPeriod"].seen / data["_new"]["lastPeriod"].total) * 100).toFixed(1)}%)</TextStyle>}
            </RowLayout>
            <RowLayout style={{ gap: 6 }}>
                <TextStyle size={0.75} style={{ fontStyle: 'italic' }}>Reviewed</TextStyle>
                <TextStyle weight="bold" size={0.75}>Seen</TextStyle>
                <TextStyle size={0.75}>{data["reviewed"]["lastPeriod"].seen}</TextStyle>
                <TextStyle weight="bold" size={0.75}>Total</TextStyle>
                <TextStyle size={0.75}>{data["reviewed"]["lastPeriod"].total}</TextStyle>
                {data["reviewed"]["lastPeriod"].total > 0 && <TextStyle style={{ fontStyle: 'italic' }} size={0.75}>({((data["reviewed"]["lastPeriod"].seen / data["reviewed"]["lastPeriod"].total) * 100).toFixed(1)}%)</TextStyle>}
            </RowLayout>
        </ColumnLayout>
    </>
}

export const LibraryDashboardTile: React.FC = (props) => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['library-dashboard'],
        queryFn: () => api.LibraryDashboard().then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error?.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ColumnLayout style={{ justifyContent: 'start', alignItems: 'start', gap: 4 }}>

            <TextStyle weight="bold" size={0.75} style={{ fontStyle: 'italic' }}>Texts</TextStyle>
            <RowLayout style={{ gap: 6 }}>
                <TextStyle size={0.75} style={{ fontStyle: 'italic' }}>Uploaded</TextStyle>
                <TextStyle size={0.75} weight="bold" style={{ fontStyle: 'italic' }}>Read</TextStyle>
                <TextStyle size={0.75}>{data.readUploaded}</TextStyle>
                <TextStyle size={0.75} weight="bold" style={{ fontStyle: 'italic' }}>Total</TextStyle>
                <TextStyle size={0.75}>{data.totalUploaded}</TextStyle>
            </RowLayout>
            <RowLayout style={{ gap: 6 }}>
                <TextStyle size={0.75} style={{ fontStyle: 'italic' }}>Generated</TextStyle>
                <TextStyle size={0.75} weight="bold" style={{ fontStyle: 'italic' }}>Read</TextStyle>
                <TextStyle size={0.75}>{data.readGenerated}</TextStyle>
                <TextStyle size={0.75} weight="bold" style={{ fontStyle: 'italic' }}>Total</TextStyle>
                <TextStyle size={0.75}>{data.totalGenerated}</TextStyle>
            </RowLayout>
        </ColumnLayout>
    </>
}   