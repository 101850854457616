/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ActionRecordAction } from '../models/ActionRecordAction';
import { HttpFile } from '../http/http';

export class GetActionRecordRequest {
    'startDate': Date;
    'endDate': Date;
    'type'?: ActionRecordAction;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "startDate",
            "baseName": "startDate",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endDate",
            "baseName": "endDate",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "ActionRecordAction",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return GetActionRecordRequest.attributeTypeMap;
    }

    public constructor() {
    }
}



