import React, { useContext, useState } from 'react';
import { useQuery, useMutation, QueryClient } from '@tanstack/react-query';
import { DefaultApiContext, UnwrapApiCall } from '../Contexts/Api/DefaultApiContext';
import { ApiContext } from '../Contexts/Api/ApiContext';
import { ContentTile, StyledButton } from '../Components/ModeTile';
import { ColumnLayout, RowLayout } from '../Styles/Styles';
import { TextStyle } from '../App';
import { NoteRequest } from '../api/models/NoteRequest';
import { Modal } from '../Components/Modal';
import { NotesView } from './NotesView';

export const ManageNotesView: React.FC = (props) => {

    const [noteKey, setNoteKey] = useState("");

    const [showAddNote, setShowAddNote] = useState(false);

    const api = useContext(ApiContext);

    const { isPending, error, data } = useQuery({
        queryKey: ['notes'],
        queryFn: () => api.AllNotes().then(UnwrapApiCall)
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>

        <ColumnLayout>

            {showAddNote && <>
                <Modal OnClose={() => { setShowAddNote(false) }}>
                    <NotesView wordKey={noteKey} />
                </Modal>
            </>}
            <RowLayout style={{ justifyContent: "space-between" }}>
                <TextStyle size={2}>Notes</TextStyle>
            </RowLayout>
            <RowLayout style={{}}>
                <input type="text" placeholder="Key" onChange={(e) => { setNoteKey(e.target.value) }} />
                <StyledButton onClick={() => { setShowAddNote(true) }}>Add Note</StyledButton>
            </RowLayout>

            {data.map((note) => (<ContentTile>
                <ColumnLayout>
                    <RowLayout style={{ justifyContent: "space-between", opacity: 0.5 }}>
                        <TextStyle size={1}>{note.key}</TextStyle>
                        <TextStyle size={1}>{note.createdAt.toLocaleString()}</TextStyle>
                    </RowLayout>
                    <RowLayout>
                        <TextStyle key={note.id}>{note.note}</TextStyle>
                    </RowLayout>
                </ColumnLayout>
            </ContentTile>
            ))}
        </ColumnLayout>
    </>
}





