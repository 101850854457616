import { AppCallbacks, TextStyle } from "../App"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { ButtonDisplay } from "../Styles/Styles"
import { useEffect, useState } from "react"
import { ManageTextsView } from "./ManageTextsView"
import { EventDefn } from "../Database/MessageRecord"
import { UnitControllerMode } from "../Loaders/LanguageDefinitionLoader"
import { InfiniteReadView } from "./InfiniteReadView"
import { LibraryDashboardTile } from "./StudyDashboardTile"
import { ProcessTextView } from "./ProcessTextView"
import { ItemDataView } from "./ItemDataView"

enum LibraryMode {
    Home,
    ManageTexts,
    InfiniteRead,
    ProcessText,
    ItemData,
}

export const LibraryView: React.FC<AppCallbacks> = (props) => {
    const [mode, setMode] = useState<LibraryMode>(LibraryMode.Home)


    const LibraryMenuConfig = [{
        Label: "Back",
        OnClick: () => { setMode(LibraryMode.Home) }
    },
    {
        Label: "Exit",
        OnClick: () => { props.OnExit() }
    }]

    useEffect(() => {
        props.SetMenu(LibraryMenuConfig)
    }, [mode, props, LibraryMenuConfig])

    return <>
        <ContentTile>
            {mode === LibraryMode.Home && <>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextStyle align='left' size={4} weight={'bold'}>Library</TextStyle>

                    <LibraryDashboardTile />
                    <ButtonDisplay>
                        <StyledButton onClick={() => setMode(LibraryMode.ManageTexts)}>
                            <TextStyle case='uppercase' size={2} weight='bold'>Saved Texts</TextStyle>
                        </StyledButton>

                        <StyledButton onClick={() => setMode(LibraryMode.ProcessText)}><TextStyle case='uppercase' size={2} weight='bold'>Upload Text</TextStyle></StyledButton>
                        <StyledButton onClick={() => setMode(LibraryMode.InfiniteRead)}><TextStyle case='uppercase' size={2} weight='bold'>Infinite Read</TextStyle></StyledButton>
                        <StyledButton onClick={() => setMode(LibraryMode.ItemData)}><TextStyle case='uppercase' size={2} weight='bold'>Item Data</TextStyle></StyledButton>
                    </ButtonDisplay>
                </div>
            </>}

            {mode === LibraryMode.ManageTexts && <>
                <ManageTextsView OnExit={() => setMode(LibraryMode.Home)} OnEnter={() => { }} SetPreamble={() => { }} SetMenu={() => { }} SendEvent={() => { }} />
            </>}

            {mode == LibraryMode.InfiniteRead && <>
                <InfiniteReadView />
            </>}
            {mode == LibraryMode.ProcessText && <>
                <ProcessTextView
                    SetMenu={() => { }}
                    OnExit={() => { setMode(LibraryMode.Home) }}
                />
            </>}
            {mode == LibraryMode.ItemData && <>
                <ItemDataView />
            </>}
        </ContentTile>
    </>
}
