import { useContext, useState } from "react"
import { TextStyle } from "../App"
import { AnswerState } from "../Database/HistoryState"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { Data, OnClose, OnComplete, OnExit, SetData } from "../Types/Interfaces"
import { AnswerRecord, GenericRoundItemData, GenericRoundRecord, NewRoundItemData, RoundRecord } from "./NewRoundView"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ExampleSentenceDisplay } from "../Components/ItemExamplesView"
import { StyledButton } from "../Components/ModeTile"
import { ActionRecord, ActionRecordAction, SentenceResult } from "../api"

enum LearnModeState {
    Review,
    Results
}

interface SentenceReaderViewData {
    AutoPlay: boolean
    AutoProgress: boolean
    Sentences: SentenceResult[]
}

interface SentenceRoundItemDisplayProps {
    AutoPlay: boolean
    AutoProgress: boolean
    Sentence: SentenceResult
    Item: GenericRoundItemData
}

export const SentenceRoundItemDisplay: React.FC<SetData & Data<SentenceRoundItemDisplayProps>> = props => {

    const startTime = new Date()

    const OnNext = () => {
        const endTime = new Date()
        const timeElapsed = endTime.getTime() - startTime.getTime()

        const record: AnswerRecord = {
            Record: AnswerState.Success,
            TimeAnswered: endTime,
            TimeToAnswer: timeElapsed
        }

        props.SetData(record)
    }

    const OnAudioFinish = () => {
        setTimeout(() => {
            OnNext()
        }, 500)

    }

    return <>
        <ColumnLayout>
            <ExampleSentenceDisplay autoPlay={props.Data.AutoPlay} autoProgress={props.Data.AutoProgress} OnAudioFinish={OnAudioFinish} key={props.Data.Sentence.ref} text={props.Data.Sentence.text} sentenceRef={props.Data.Sentence.ref} segments={props.Data.Sentence.segments} />
            <StyledButton onClick={OnNext}>Next</StyledButton>
        </ColumnLayout>
    </>
}

export const SentenceReaderRoundView: React.FC<OnExit & SetData & OnComplete & OnClose & Data<SentenceReaderViewData>> = props => {
    const SentenceResultToRoundItem: (x: SentenceResult) => GenericRoundItemData = (x: SentenceResult) => ({
        UnitId: 3,
        ItemKey: x.ref,
        Complete: false,
        SuccessCount: 0,
        FailureCount: 0,
        Record: [] as AnswerRecord[]
    })

    const [startTime, setStartTime] = useState(new Date())

    const roundItems = props.Data.Sentences.map(SentenceResultToRoundItem)
    const [roundItemMap, setRoundItemMap] = useState<Record<string, GenericRoundItemData>>({})

    roundItems.forEach(ri => {
        if (roundItemMap[ri.ItemKey] == undefined) {
            roundItemMap[ri.ItemKey] = ri
        }
    })

    const [displayWhich, setDisplayWhich] = useState(0)

    const OnNext = (record: AnswerRecord) => {

        roundItemMap[props.Data.Sentences[displayWhich].ref].Record.push(record)

        if (displayWhich < props.Data.Sentences.length - 1) {
            setDisplayWhich(displayWhich + 1)
        } else {
            const roundItemData = Object.values(roundItemMap)
            const endTime = new Date()
            const roundRecord: GenericRoundRecord = {
                Items: roundItemData,
                TimeBegan: startTime,
                TimeFinished: endTime,
                TimeElapsed: endTime.getTime() - startTime.getTime(),
                RoundGuid: "",
                RoundType: 0
            }
            props.OnComplete(roundRecord)
        }
    }


    return <>

        <ColumnLayout>
            <RowLayout>
                <TextStyle>{displayWhich + 1}/{props.Data.Sentences.length}</TextStyle>
            </RowLayout>
            <SentenceRoundItemDisplay
                Data={{
                    AutoPlay: props.Data.AutoPlay,
                    AutoProgress: props.Data.AutoProgress,
                    Sentence: props.Data.Sentences[displayWhich],
                    Item: roundItemMap[props.Data.Sentences[displayWhich].ref]
                }}
                SetData={OnNext}
            />

        </ColumnLayout>
    </>
}
export const SentenceReaderView: React.FC<OnExit & Data<SentenceReaderViewData> & { Reload: () => void }> = props => {


    type Stats = {
        totalTime: number,
        avgTime: number,
        avgCharactersPerSecond: number,
        avgSegmentsPerSecond: number
    }

    const api = useContext(ApiContext)
    const [pageState, setPageState] = useState(LearnModeState.Review)
    const [roundRecord, setRoundRecord] = useState<GenericRoundRecord | undefined>(undefined)
    const [stats, setStats] = useState<Stats | undefined>(undefined)
    const CalculateStats = (roundItemData: GenericRoundItemData[]) => {
        var total = 0
        var entries = 0

        roundItemData.map(ri => {
            total += ri.Record.reduce((acc, item) => { return acc + item.TimeToAnswer }, 0)
            entries += ri.Record.length
        })

        var avg = total / entries

        const totalCharacters = props.Data.Sentences.reduce((acc, item) => { return acc + item.segments.reduce((acc, segment) => { return acc + segment.length }, 0) }, 0)
        const totalSegments = props.Data.Sentences.reduce((acc, item) => { return acc + item.segments.length }, 0)
        const avgCharactersPerSecond = totalCharacters / (total / 1000);
        const avgSegmentsPerSecond = totalSegments / (total / 1000);

        return {
            totalTime: total,
            avgTime: avg,
            avgCharactersPerSecond: avgCharactersPerSecond,
            avgSegmentsPerSecond: avgSegmentsPerSecond
        }
    }

    return <>
        {pageState == LearnModeState.Review && <>
            <SentenceReaderRoundView
                OnExit={props.OnExit}
                SetData={(RoundRecord: GenericRoundRecord) => { /* send to server */


                }}
                OnComplete={(roundRecord: GenericRoundRecord) => {
                    console.log(roundRecord)

                    const stats = CalculateStats(roundRecord.Items)
                    setStats(stats)

                    const uuid = crypto.randomUUID()

                    const actionRecords: ActionRecord[] = []
                    roundRecord.Items.forEach(ri => {
                        const sentence = props.Data.Sentences.find(x => x.ref == ri.ItemKey)
                        actionRecords.push(...ri.Record.map(r => ({
                            action: ActionRecordAction.ReadSentence,
                            ref: ri.ItemKey,
                            groupId: uuid,
                            count: 1,
                            timeBegan: r.TimeAnswered,
                            timeFinished: r.TimeAnswered,
                            timeElapsed: r.TimeToAnswer,
                            metadata: { key: sentence?.key, text: sentence?.text }
                        })))
                    })

                    actionRecords.push({
                        action: ActionRecordAction.LoadSentenceSelection,
                        ref: uuid,
                        groupId: uuid,
                        count: roundRecord.Items.length,
                        timeBegan: roundRecord.TimeBegan,
                        timeFinished: roundRecord.TimeFinished,
                        timeElapsed: roundRecord.TimeElapsed,
                        metadata: stats
                    })

                    api.ProcessActionRecords(actionRecords).then(result => {
                        if (result) {
                            if (props.Data.AutoProgress) {
                                props.Reload()
                            } else {
                                setPageState(LearnModeState.Results)
                                setRoundRecord(roundRecord)
                            }
                        }
                    })

                }}
                OnClose={() => {
                    props.OnExit()
                }}

                Data={{
                    AutoPlay: props.Data.AutoPlay,
                    AutoProgress: props.Data.AutoProgress,
                    Sentences: props.Data.Sentences,
                }}
            />
        </>}
        {pageState == LearnModeState.Results &&

            <>
                <div style={{ textAlign: 'center', paddingBottom: 12, }}>
                    <ColumnLayout>
                        <TextStyle weight='bold' case='uppercase'>Results</TextStyle>
                        <TextStyle>Sentences: {roundRecord?.Items.length}</TextStyle>

                        {
                            ((roundItemData: GenericRoundItemData[]) => {
                                var total = 0
                                var entries = 0

                                roundItemData.map(ri => {
                                    total += ri.Record.reduce((acc, item) => { return acc + item.TimeToAnswer }, 0)
                                    entries += ri.Record.length
                                })

                                var avg = total / entries

                                const totalCharacters = props.Data.Sentences.reduce((acc, item) => { return acc + item.segments.reduce((acc, segment) => { return acc + segment.length }, 0) }, 0)
                                const totalSegments = props.Data.Sentences.reduce((acc, item) => { return acc + item.segments.length }, 0)
                                const avgCharactersPerSecond = totalCharacters / (total / 1000);
                                const avgSegmentsPerSecond = totalSegments / (total / 1000);

                                return <>
                                    <TextStyle>Total time: {total / 1000}s</TextStyle>
                                    <TextStyle> Character read rate: {avgCharactersPerSecond.toFixed(2)}/s</TextStyle>
                                    <TextStyle> Segment read rate: {avgSegmentsPerSecond.toFixed(2)}/s</TextStyle>
                                </>
                            })(roundRecord!!.Items ?? [])
                        }
                    </ColumnLayout>
                </div>
                <div style={{ flexDirection: 'row', flexGrow: 1, alignContent: 'center', width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 24 }}>

                    {/* {roundItemData.map(ri => {

                        return <ViewRoundItemData Data={{ UnitDefinition: props.Data.Unit, RoundItem: ri }} />

                    })} */}
                </div>
            </>}


    </>

}
