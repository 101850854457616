import { useState } from "react"
import { TextStyle } from "../App"
import { ColumnLayout, RowLayout } from "../Styles/Styles"
import { SentenceKeyLastSeen } from "../api"
import { ReviewMistakesLoader } from "../Loaders/ReviewMistakesLoader"
import { RoundControllerParams } from "./NewRoundView"
import { Data, OnExit } from "../Types/Interfaces"
import { SetMenu } from "../Loaders/LanguageDefinitionLoader"
import { LanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { StyledButton } from "../Components/ModeTile"

export const ReviewMistakesView: React.FC<
    Data<{ Params: RoundControllerParams, LanguageDefinitionConfiguration: LanguageConfiguration<any> }> &
    OnExit &
    SetMenu
> = (props) => {
        enum ViewState {
            Options,
            Review
        }

        const [viewState, setViewState] = useState(ViewState.Options)
        const [seen, setSeen] = useState<SentenceKeyLastSeen>(SentenceKeyLastSeen.Yesterday)
        const [includeMeaning, setIncludeMeaning] = useState(true)
        const [includeWriting, setIncludeWriting] = useState(true)
        const [includeSpeaking, setIncludeSpeaking] = useState(true)


        const UpdateParameters = () => {

        }

        return (
            <ColumnLayout>
                {viewState == ViewState.Options && <>
                    <RowLayout>
                        <TextStyle weight="bold">Seen</TextStyle>
                        <select
                            value={seen}
                            onChange={(e) => setSeen(e.target.value as SentenceKeyLastSeen)}
                            className="p-2 border rounded"
                        >
                            {Object.values(SentenceKeyLastSeen).map(value => (
                                <option key={value} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </RowLayout>
                    <RowLayout>
                        <TextStyle weight="bold">Tests</TextStyle>
                    </RowLayout>
                    <RowLayout>
                        <input type="checkbox" checked={includeMeaning} onChange={(e) => setIncludeMeaning(e.target.checked)} />
                        <label>Include Meaning</label>
                    </RowLayout>
                    <RowLayout>
                        <input type="checkbox" checked={includeWriting} onChange={(e) => setIncludeWriting(e.target.checked)} />
                        <label>Include Writing</label>
                    </RowLayout>
                    <RowLayout>
                        <input type="checkbox" checked={includeSpeaking} onChange={(e) => setIncludeSpeaking(e.target.checked)} />
                        <label>Include Speaking</label>
                    </RowLayout>
                    <RowLayout>
                        <StyledButton onClick={()=>{setViewState(ViewState.Review)}}>
                            Review
                        </StyledButton>
                    </RowLayout>
                </>}
                {viewState === ViewState.Review && <>
                    <ReviewMistakesLoader
                        Data={{... props.Data.Params}}
                        SetMenu={props.SetMenu}
                        OnExit={props.OnExit}
                        parameters={{
                            seen: seen,
                            testDefinitions: (function () {
                                const testDefinitions = []

                                if (includeMeaning) {
                                    testDefinitions.push(0)
                                }
                                if (includeWriting) {
                                    testDefinitions.push(1)
                                }
                                if (includeSpeaking) {
                                    testDefinitions.push(2)
                                }

                                return testDefinitions
                            })()
                        }}
                    />
                </>}
            </ColumnLayout>
        )
    }