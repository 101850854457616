import { useContext, useState } from "react"
import { TextStyle } from "../App"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { ZhLanguageItemTypes, ZhLanguageItemTypesText, ZhLanguageUnitTypes, ZhLanguageUnitTypesText } from "../Config/Language/Zh/Types"
import { ColumnLayout, InnerTile, RowLayout } from "../Styles/Styles"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { Paginator } from "../Components/Paginator"
import { ItemData, ItemHistory } from "../api"
import { Content } from "antd/es/layout/layout"
import { useQuery } from "@tanstack/react-query"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { Modal } from "../Components/Modal"
import { ZhUnitTestInterfaceConfig } from "../Config/Language/Zh/Config/UnitTestInterfaceConfig"
import { PracticeItemView } from "./PracticeView"
import dayjs from "dayjs"
import { ItemExamplesView } from "../Components/ItemExamplesView"

export const ItemDataView = () => {

    const [unit, setUnit] = useState(ZhLanguageUnitTypes.Base)
    const [itemType, setItemType] = useState(ZhLanguageItemTypes.Radical)
    const [grade, setGrade] = useState(1)
    const [filter, setFilter] = useState("")

    const [showItemData, setShowItemData] = useState(false)
    const Show = () => {
        setShowItemData(true)
    }

    return <>
        <ColumnLayout>
            <RowLayout>
                <TextStyle>Unit</TextStyle>

                <select value={unit} onChange={(e) => setUnit(e.target.value as unknown as ZhLanguageUnitTypes)}>
                    {Object.entries(ZhLanguageUnitTypes).filter(([key, value]) => {
                        return ZhLanguageUnitTypesText[key as unknown as keyof typeof ZhLanguageUnitTypesText] != undefined
                    }).map(([key, value]) => {
                        return <option value={key}>{value}</option>
                    })}
                </select>

            </RowLayout>
            <RowLayout>
                <TextStyle>Item Type</TextStyle>
                <select value={itemType} onChange={(e) => setItemType(e.target.value as unknown as ZhLanguageItemTypes)}>
                    {Object.entries(ZhLanguageItemTypes).filter(([key, value]) => {
                        return ZhLanguageItemTypesText[key as unknown as keyof typeof ZhLanguageItemTypesText] != undefined
                    }).map(([key, value]) => {
                        return <option value={key}>{value}</option>
                    })}
                </select>
            </RowLayout>
            <RowLayout>
                <TextStyle>Grade</TextStyle>
                <input type="number" value={grade} onChange={(e) => setGrade(e.target.value as unknown as number)} />
            </RowLayout>
            <RowLayout>
                <TextStyle>Filter</TextStyle>
                <input type="text" value={filter} onChange={(e) => setFilter(e.target.value)} />
            </RowLayout>
            <StyledButton onClick={Show}>Search</StyledButton>
        </ColumnLayout>
        {showItemData && <LoadItemDataDisplay filter={filter} unit={unit} itemType={itemType} grade={grade} />}
    </>


}

const LoadItemDataDisplay: React.FC<{ filter: string, unit: number, itemType: number, grade: number }> = props => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ["itemData", props.unit, props.itemType, props.grade],
        queryFn: () => {
            return api.ViewItemByGrade({ unitId: props.unit, itemType: props.itemType, grade: props.grade }).then(UnwrapApiCall)
        },
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>


    const keys = Object.keys(data)

    return <>
        <Paginator>
            {keys.filter(k => props.filter == "" || k == props.filter).map((key) => {
                return <ItemDataDisplay unitId={props.unit} itemData={data[key].data} itemHistorys={data[key].history} />
            })}
        </Paginator>
    </>
}

export const ItemDataDisplay: React.FC<{ unitId: number, itemData: ItemData, itemHistorys: ItemHistory[] }> = props => {

    const isIntroduced = props.itemHistorys.some(h => h.introduced == true)

    const [showData, setShowData] = useState(false)
    const [practiceView, setPracticeView] = useState(false)
    const [examplesView, setExamplesView] = useState(false)
    return <>

        {showData && <>
            {practiceView && <>
                <Modal OnClose={() => { setPracticeView(false) }}>

                    <PracticeItemView Data={{ Item: props.itemData, TestDefinitions: ZhUnitTestInterfaceConfig[props.unitId] }} />
                </Modal>
            </>}
            {examplesView && <>
                <Modal OnClose={() => { setExamplesView(false) }}>
                    <ItemExamplesView Data={{ Item: props.itemData }} />
                </Modal>
            </>}
            {!practiceView && !examplesView && <>
                <Modal OnClose={() => setShowData(false)}>
                    <ContentTile>
                        <ColumnLayout>
                            <RowLayout style={{ alignItems: 'center' }}>
                                <TextStyle size={3} weight="bold">{props.itemData.item}</TextStyle>
                                <TextStyle size={1} weight="bold">{props.itemData.properties["DisplayReading"]}</TextStyle>
                                <TextStyle size={1} weight="bold">{props.itemData.properties["Reading"]}</TextStyle>
                            </RowLayout>

                            <TextStyle size={1} weight="bold">{props.itemData.properties["PrimaryDefinition"]}</TextStyle>
                            <RowLayout>
                                {Object.keys(props.itemData.metadata).map(k => {
                                    return <RowLayout style={{ width: 'fit-content' }}>
                                        <TextStyle size={1} weight="bold">{k}</TextStyle>
                                        <TextStyle size={1}>{props.itemData.metadata[k]}</TextStyle>
                                    </RowLayout>
                                })}
                            </RowLayout>


                            <RowLayout>
                                {props.itemHistorys.map(ih => {
                                    return <div>

                                        <TextStyle size={1} weight="bold">{ZhUnitTestInterfaceConfig[props.unitId][ih.testDefinitionId].Label}</TextStyle>
                                        <TextStyle size={1}>Last Seen: {dayjs(ih.lastSeen).toString()}</TextStyle>
                                        <TextStyle size={1}>Due: {dayjs(ih.due).toString()}</TextStyle>
                                        <TextStyle size={1}>Difficulty: {ih.difficulty}</TextStyle>
                                        <TextStyle size={1}>Stability: {ih.stability}</TextStyle>
                                        <TextStyle size={1}>Lapses: {ih.lapses}</TextStyle>
                                        <TextStyle size={1}>Reps: {ih.reps}</TextStyle>
                                        <TextStyle size={1}>Learning State: {ih.learningState}</TextStyle>

                                    </div>
                                })}
                            </RowLayout>


                            <RowLayout>
                                <StyledButton onClick={() => setExamplesView(true)}>Examples</StyledButton>
                                <StyledButton onClick={() => setPracticeView(true)}>Practice</StyledButton>
                            </RowLayout>
                        </ColumnLayout>
                    </ContentTile>
                </Modal>
            </>}
        </>}
        <InnerTile onClick={() => setShowData(!showData)} Small={true}>
            <RowLayout>
                <TextStyle colour={isIntroduced ? 'black' : '#0000003f'} size={2} weight="bold">{props.itemData.item}</TextStyle>
            </RowLayout>
        </InnerTile>

    </>
}
