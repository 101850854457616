import { useContext, useEffect, useRef, useState } from "react"
import { Chapter, Paragraph, Sentence, TextRefMap, TextRefType, TextStructure } from "../Database/TextStore"
import { ContentTile, StyledButton } from "../Components/ModeTile"
import { db } from "../Database/db"
import { ColumnLayout, InnerTile, RowLayout } from "../Styles/Styles"
import { TextStudyView, TextBreakdownDisplay, DictionaryQueryTile } from "./ProcessedTextDisplay"
import { Collapse } from "../Components/Collapse"
import { TextStyle } from "../App"
import { TextHelperModal } from "../Views/TextHelperView"
import { NextItemContainer, OrderSelectedUnits, UpdateUnitState, ZhLanguageConfiguration } from "../Config/LanguageDefinitionConfiguration"
import { RoundControllerParams } from "../Views/RoundView"
import { Modal } from "../Components/Modal"
import { ApiContext } from "../Contexts/Api/ApiContext"
import { ResponseType } from "../Contexts/Api/RootApiContext"
import { ProcessedText } from "../Types/ProcessedText"
import { ItemHistoryFromProcessedText, ItemHistoryFromTextRefStructure, ResolveSegments } from "../Utility/ProcessedTextFromSegmentedOutput"
import { LearnView } from "../Views/LearnView"
import { PlayTextAudio } from "../Components/PlayTextAudio"
import { useQuery } from "@tanstack/react-query"
import { UnwrapApiCall } from "../Contexts/Api/DefaultApiContext"
import { ZhLanguageUnitTypes } from "../Config/Language/Zh/Types"
import { SelectItemParametersView } from "../Views/SelectItemParametersView"
import { ZhLanguageDefinition } from "../Config/Language/Zh/Definition"
import { SelectUnitParameters } from "../api"
import { LearnViewLoader } from "../Loaders/LearnViewLoader"
import { DefaultUnitControllerParams } from "../Database/SettingsState"
import { OptionsIcon } from "../Icons/OptionsIcon"
import { isPropertyAccessExpression } from "typescript"
import { DictionaryModal, LoadDictionaryQuery } from "../Views/DictionaryView"
import { NavigateIcon } from "../Icons/NavigateIcon"
import { BreakIcon } from "../Icons/BreakIcon"
import { CloseIcon } from "../Icons/CloseIcon"

export const SentenceDisplay: React.FC<{ Sentence: Sentence }> = props => {
    const [showTextHelper, setShowTextHelper] = useState(false)
    const [showStudyView, setShowStudyView] = useState(false)



    return <>
        <Collapse Label={props.Sentence.Text} Collapsed={true}>
            <ColumnLayout>
                <RowLayout>
                    <StyledButton onClick={() => { setShowStudyView(true) }}>Study</StyledButton>
                    <StyledButton onClick={() => { setShowTextHelper(true) }}>TextHelper</StyledButton>
                    <PlayTextAudio Text={props.Sentence.Text} />

                </RowLayout>
                <TextStyle>{props.Sentence.PrimaryMeaning}</TextStyle>
                <RowLayout>

                    {props.Sentence.Segments.map((s, i) => {

                        return <DictionaryQueryTile key={i} Text={s} />

                    })}
                </RowLayout>
            </ColumnLayout>
            {showTextHelper && <TextHelperModal OnClose={() => setShowTextHelper(false)} Ref={props.Sentence.Ref} Text={props.Sentence.Text} />}
            {showStudyView && <TextStructureStudyView Mode={'SentenceBreakdown'} TextRef={props.Sentence.Ref} OnClose={() => setShowStudyView(false)} />}
        </Collapse>
    </>
}


export const SentenceReader: React.FC<{ Paragraph: Paragraph, OnExit: any }> = props => {

    const sentences = props.Paragraph.Sentences

    const [position, setPosition] = useState(0)

    const Next = () => {
        if (position != sentences.length) {
            setPosition(position + 1)
        }
    }

    const Prev = () => {
        if (position > 0) {
            setPosition(position - 1)
        }
    }

    return <>
        <ColumnLayout>
            <TextStyle size={2.5}>{sentences[position].Text}</TextStyle>
            <PlayTextAudio Text={sentences[position].Text} />
            <RowLayout>

                {sentences[position].Segments.map((s, i) => {

                    return <DictionaryQueryTile key={i} Text={s} />

                })}
            </RowLayout>
        </ColumnLayout>
        <RowLayout>

            {position > 0 &&
                <StyledButton onClick={Prev}>Previous</StyledButton>
            }
            {position < sentences.length - 1 &&
                <StyledButton onClick={Next}>Next</StyledButton>
            }
            <StyledButton onClick={props.OnExit}>Finish</StyledButton>
        </RowLayout>
    </>
}

export const AssistedReadView: React.FC<{ Close: any, Structure: Chapter[] }> = props => {

    type ReadViewMode = 'Paragraph' | 'Sentence'
    const paragraphs = props.Structure.map(c => c.Paragraphs).flat()

    const [position, setPosition] = useState(0)

    const [mode, setMode] = useState<ReadViewMode>('Paragraph')

    const Prev = () => {
        if (position > 0) {
            setPosition(position - 1)
        }
    }
    const NextParagraph = () => {
        if (position != paragraphs.length) {
            setPosition(position + 1)
        }
    }

    const SentenceMode = () => {
        setMode('Sentence')
    }

    return <>
        <ContentTile>
            <ColumnLayout>
                {mode == 'Paragraph' && <>
                    < TextStyle size={2}>{paragraphs[position].Sentences.map(s => s.Text).join("")}</TextStyle>
                    {position > 0 &&
                        <StyledButton onClick={Prev}>Previous</StyledButton>
                    }
                    {position < paragraphs.length - 1 &&
                        <StyledButton onClick={NextParagraph}>Next</StyledButton>
                    }
                    <StyledButton onClick={SentenceMode}>By sentence</StyledButton>
                    {position == paragraphs.length - 1 && <StyledButton onClick={props.Close}>Finish</StyledButton>}
                </>}
                {mode == 'Sentence' && <SentenceReader Paragraph={paragraphs[position]} OnExit={() => setMode('Paragraph')} />}
            </ColumnLayout >
        </ContentTile >
    </>
}

export const TextStructureListenView: React.FC<{ TextRef: string, Close: any, Structure: Chapter[], AutoProgress?: boolean }> = props => {

    const paragraphs = props.Structure.map(c => c.Paragraphs).flat()

    const [activeParagraph, setActiveParagraph] = useState(0)
    const [activeSentence, setActiveSentence] = useState(0)

    const [showReaderOptionsModal, setShowReaderOptionsModal] = useState(false)
    const [showTextOptionsModal, setShowTextOptionsModal] = useState(false)
    const activeSentenceRef = useRef<HTMLDivElement>(null);

    const [showQueryView, setShowQueryView] = useState(false)

    const [autoplayAudio, setAutoplayAudio] = useState(false)
    const [autoProgress, setAutoProgress] = useState(props.AutoProgress ?? false)

    useEffect(() => {
        if (activeSentenceRef.current) {
            activeSentenceRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [activeParagraph, activeSentence]);

    const checkFinalPosition = () => {
        if (activeParagraph == paragraphs.length - 1) {
            if (activeSentence == paragraphs[activeParagraph].Sentences.length - 1) {
                return true
            }
        }
        return false
    }

    const checkFirstPosition = () => {
        if (activeParagraph == 0 && activeSentence == 0) {
            return true
        }
        return false
    }

    const NextSentence = () => {
        if (activeSentence < paragraphs[activeParagraph].Sentences.length - 1) {
            setActiveSentence(activeSentence + 1)
        } else {
            setActiveParagraph(activeParagraph + 1)
            setActiveSentence(0)
        }
    }

    const PrevSentence = () => {
        if (activeParagraph == 0 && activeSentence == 0) {

        } else {
            if (activeSentence > 0) {
                setActiveSentence(activeSentence - 1)
            } else {
                setActiveParagraph(activeParagraph - 1)
                setActiveSentence(paragraphs[activeParagraph].Sentences.length - 2)
            }
        }
    }

    const OnAudioFinish = () => {
        if (autoProgress) {
            if (!checkFinalPosition()) {
                NextSentence()
            } else {
                props.Close()
            }
        }
    }

    return <>
        {showReaderOptionsModal && <>

            {/* <TextHelperModal OnClose={() => { setShowReaderOptionsModal(false) }} Ref={paragraphs[activeParagraph].Sentences[activeSentence].Ref} Text={paragraphs[activeParagraph].Sentences[activeSentence].Text} /> */}
            <Modal OnClose={() => { setShowReaderOptionsModal(false) }}>
                <ContentTile>
                    <ColumnLayout>
                        <TextStyle weight={'bold'}>Text stats</TextStyle>
                        <RowLayout>
                            <TextProgressDisplay Ref={props.TextRef} />
                            <TextCalculatedGradeDisplay Ref={props.TextRef} />
                        </RowLayout>
                        <TextStyle weight={'bold'}>Reader options</TextStyle>
                        <RowLayout>
                            <TextStyle>Autoplay audio</TextStyle>
                            <input type="checkbox" checked={autoplayAudio} onChange={() => { setAutoplayAudio(!autoplayAudio) }} />
                        </RowLayout>
                        <RowLayout>
                            <TextStyle>Auto progress</TextStyle>
                            <input type="checkbox" checked={autoProgress} onChange={() => { setAutoProgress(!autoProgress) }} />
                        </RowLayout>
                    </ColumnLayout>
                </ContentTile>
            </Modal>
        </>}
        {showTextOptionsModal && <>
            <TextHelperModal OnClose={() => { setShowTextOptionsModal(false) }} Ref={paragraphs[activeParagraph].Sentences[activeSentence].Ref} Text={paragraphs[activeParagraph].Sentences[activeSentence].Text} />
        </>}
        {showQueryView && <Modal OnClose={() => setShowQueryView(false)}>
            <LoadDictionaryQuery query={paragraphs[activeParagraph].Sentences[activeSentence].Text} />
        </Modal>}
        <ContentTile>

            <RowLayout style={{
                backgroundColor: '#ffffff',
                width: 'calc(100% - 96px)',
                position: 'fixed',
                bottom: 124,
                left: 24,
                zIndex: 50,
                paddingTop: 12,
                paddingLeft: 24,
                paddingRight: 24,
                paddingBottom: 12,
                boxShadow: '0px -4px 0px 0px rgba(0, 0, 0, 0.1)',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div style={{ width: 'calc(100% - 48px)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 12 }}>
                    {!checkFirstPosition() && <StyledButton onClick={PrevSentence}>Previous</StyledButton>}
                    {!checkFinalPosition() && <StyledButton onClick={NextSentence}>Next</StyledButton>}
                    <StyledButton onClick={props.Close}>Close</StyledButton>
                </div>
                <div onClick={() => { setShowReaderOptionsModal(true) }} style={{ width: 24, marginLeft: 'auto' }}>
                    <OptionsIcon />
                </div>
            </RowLayout>

            <ColumnLayout style={{ paddingBottom: 100 }}>
                {paragraphs[activeParagraph].Sentences.map((s, i) => {
                    return <>
                        <ColumnLayout>
                            <RowLayout ref={i === activeSentence ? activeSentenceRef : null}
                                key={`${activeParagraph}-${i}`}>
                                <AnnotatedTextDisplay Active={i == activeSentence} Text={s.Text} Segments={s.Segments} />
                                {/* <TextStyle size={2} weight={i == activeSentence ? 'bold' : 'normal'}>{s.Text}</TextStyle> */}
                                {
                                    // s.Segments.map((sg, i) => {
                                    //     return <span onClick={() => { }} key={i} style={{ cursor: 'pointer' }}>{sg}</span>
                                    // })
                                }


                            </RowLayout >
                            <RowLayout style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                                {i != activeSentence && <div onClick={() => { setActiveSentence(i) }} style={{ marginRight: 'auto' }} >
                                    <NavigateIcon />
                                </div>}
                                {i == activeSentence && <PlayTextAudio AutoPlay={props.AutoProgress ?? autoplayAudio} AutoProgress={autoProgress} OnAudioFinish={OnAudioFinish} Text={s.Text} />}
                                {i != activeSentence && <PlayTextAudio AutoPlay={false} Text={s.Text} />}
                                <div onClick={() => setShowQueryView(!showQueryView)}>
                                    <BreakIcon />
                                </div>
                                <div onClick={() => { setShowTextOptionsModal(true) }} style={{ width: 24 }}>
                                    <OptionsIcon />
                                </div>
                            </RowLayout>

                        </ColumnLayout >
                    </>
                })}
            </ColumnLayout >

        </ContentTile >
    </>
}

export const SegmentAnnotatedTextSlice: React.FC<{ Text: string }> = props => {
    const [showDictionary, setShowDictionary] = useState(false)

    return <>
        <span onClick={() => { setShowDictionary(true) }}>{props.Text}</span>
        {showDictionary && <DictionaryModal OnClose={() => { setShowDictionary(false) }} Query={props.Text} />}
    </>
}

export const OnSelectionPopup: React.FC<{ Close: () => any, Text: string, top: number, left: number }> = props => {

    const [showQueryView, setShowQueryView] = useState(false)
    const [showTextHelperView, setShowTextHelperView] = useState(false)

    return <>
        <div onSelect={() => props.Close()} style={{ position: 'absolute', top: props.top + 24, left: props.left }}>
            <RowLayout style={{ alignItems: 'center' }}>
                <StyledButton onClick={() => setShowQueryView(!showQueryView)}>Query</StyledButton>
                <StyledButton onClick={() => setShowTextHelperView(!showTextHelperView)}>TextHelper</StyledButton>
                <div onClick={props.Close} style={{ marginLeft: 'auto' }}>
                    <CloseIcon />
                </div>
            </RowLayout>
        </div>
        {showQueryView && <Modal OnClose={() => setShowQueryView(false)}>
            <LoadDictionaryQuery query={props.Text} />
        </Modal>}
        {showTextHelperView && <Modal OnClose={() => setShowTextHelperView(false)}>
            <TextHelperModal Ref={''} Text={props.Text} OnClose={() => setShowTextHelperView(false)} />
        </Modal>}
    </>
}

export const AnnotatedTextDisplay: React.FC<{ Active: boolean, Text: string, Segments: string[] }> = props => {


    var currentPosition = 0

    const [selectionObject, setSelectionObject] = useState<{ text: string, top: number, left: number } | null>(null)

    const OnSelection = () => {
        const selection = window.getSelection()
        document.onselectionchange = () => {
            setSelectionObject(null)
        }

        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            console.log(range)
            if (selection.toString() == "") {
                setSelectionObject(null)
            } else {
                console.log({
                    text: selection.toString(),
                    top: range.startContainer.parentElement?.offsetTop,
                    left: rect.left
                });
                setSelectionObject({
                    text: selection.toString(),
                    top: 36 + (range.startContainer.parentElement?.offsetTop ?? 0),
                    left: rect.left
                })

            }
        }
    }

    return <>
        {selectionObject && <OnSelectionPopup Close={() => setSelectionObject(null)} Text={selectionObject.text} top={selectionObject.top} left={selectionObject.left} />}
        <TextStyle onTouchEnd={OnSelection} onMouseUp={OnSelection} size={2} weight={props.Active ? 'bold' : 'normal'}>
            {props.Segments.map((s, i) => {
                // find starting index of first instance of segment in text
                var index = props.Text.indexOf(s, currentPosition)
                var segmentLength = s.length

                if ((index + segmentLength) > currentPosition + segmentLength) {
                    const oldCurrentPosition = currentPosition
                    if (index != -1) {
                        currentPosition = index + segmentLength
                    }
                    return <>
                        <span>{props.Text.substring(oldCurrentPosition, index)}</span>
                        <SegmentAnnotatedTextSlice key={index} Text={s} />
                    </>
                }

                if (index != -1) {
                    currentPosition = index + segmentLength
                }

                if (i == props.Segments.length - 1) {
                    return <>
                        <SegmentAnnotatedTextSlice key={index} Text={s} />
                        <span>{props.Text.substring(currentPosition)}</span>
                    </>
                }

                return <SegmentAnnotatedTextSlice key={index} Text={s} />



            })}
        </TextStyle>
    </>

}

export const TextCharacterCountCalcluatedDisplay: React.FC<{ structure: TextStructure }> = props => {

    const uniqueCharacters: Record<string, true> = {}
    props.structure.Chapters.map(c => c.Paragraphs.map(p => p.Sentences.map(s => s.Segments.map(sg => sg.split("").forEach(a => { uniqueCharacters[a] = true })))))
    return <>
        <RowLayout>
            <TextStyle size={0.75} weight={'bold'}>Hanzi#</TextStyle>
            <TextStyle size={0.75}>{Object.keys(uniqueCharacters).length}</TextStyle>
        </RowLayout>
    </>
}

export const TextWordCountCalcluatedDisplay: React.FC<{ structure: TextStructure }> = props => {


    const uniqueWords: Record<string, true> = {}
    props.structure.Chapters.map(c => c.Paragraphs.map(p => p.Sentences.map(s => s.Segments.forEach(sg => { uniqueWords[sg] = true }))))
    return <>
        <RowLayout>
            <TextStyle size={0.75} weight={'bold'}>Word#</TextStyle>
            <TextStyle size={0.75}>{Object.keys(uniqueWords).length}</TextStyle>
        </RowLayout>
    </>
}

export const TextProgressDisplay: React.FC<{ Ref: string }> = props => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['text/progress/' + props.Ref],
        queryFn: () => api.CalculateTextProgress(props.Ref).then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    return <>
        <ColumnLayout>
            <RowLayout>
                <TextStyle size={0.75} weight={'bold'}>Characters</TextStyle>
                <TextStyle size={0.75}>{data.characters.known}/{data.characters.total} ({data.characters.percentage.toFixed(0)}%)</TextStyle>
            </RowLayout>
            <RowLayout>
                <TextStyle size={0.75} weight={'bold'}>Words</TextStyle>
                <TextStyle size={0.75}>{data.words.known}/{data.words.total} ({data.words.percentage.toFixed(0)}%)</TextStyle>
            </RowLayout>
        </ColumnLayout>

    </>
}

export const TextCalculatedGradeDisplay: React.FC<{ Ref: string }> = props => {

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['text/grade/' + props.Ref],
        queryFn: () => api.GradeText(props.Ref).then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    var grades = Object.keys(data)

    var l: string[] = []

    grades.map(g => {
        l.push(g)
    })

    var percentile25grade = l[Math.floor(l.length * 0.25)]
    var percentile50grade = l[Math.floor(l.length * 0.5)]
    var percentile75grade = l[Math.floor(l.length * 0.75)]
    var percentile100grade = l[Math.floor(l.length) - 1]

    return <>
        <RowLayout style={{ alignItems: 'center' }}>
            <TextStyle size={0.75} weight={'bold'}>Difficulty%ile</TextStyle>
            <ColumnLayout style={{ width: 'auto', gap: 6 }}>
                <TextStyle size={0.75} _style={'italic'}>25th</TextStyle>
                <TextStyle size={0.75}>{percentile25grade}</TextStyle>
            </ColumnLayout>
            <ColumnLayout style={{ width: 'auto', gap: 6 }}>
                <TextStyle size={0.75} _style={'italic'}>50th</TextStyle>
                <TextStyle size={0.75}>{percentile50grade}</TextStyle>
            </ColumnLayout>
            <ColumnLayout style={{ width: 'auto', gap: 6 }}>
                <TextStyle size={0.75} _style={'italic'}>75th</TextStyle>
                <TextStyle size={0.75}>{percentile75grade}</TextStyle>
            </ColumnLayout>
            <ColumnLayout style={{ width: 'auto', gap: 6 }}>
                <TextStyle size={0.75} _style={'italic'}>100th</TextStyle>
                <TextStyle size={0.75}>{percentile100grade}</TextStyle>
            </ColumnLayout>
        </RowLayout>
    </>
}

export const TextStructureDisplay: React.FC<{ Label: string, Ref: string }> = props => {

    const [toggleView, setToggleView] = useState(false)
    const [studyView, setStudyView] = useState(false)
    const [displayView, setDisplayView] = useState(false)
    const [readView, setReadView] = useState(false)
    const [listenView, setListenView] = useState(false)

    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false)
    const [showOptionsModal, setShowOptionsModal] = useState(false)

    const api = useContext(ApiContext)

    const { isPending, error, data } = useQuery({
        queryKey: ['text', props.Ref],
        queryFn: () => api.GetText(props.Ref).then(UnwrapApiCall),
        gcTime: 0,
        staleTime: Infinity
    })

    if (isPending) return <>Loading...</>
    if (error) return <>An error has occurred: {error.message}</>
    if (data == undefined) return <>An error has occurred: { }</>

    var fullText = data.chapters.map(c => c.paragraphs.map(p => p.sentences.map(s => s.text).join("\n")).join("\n\n")).join("")

    const textStructure = {
        Label: props.Label,
        Ref: props.Ref,
        FullText: data.fullText,
        Chapters: data.chapters.map(c => {
            return {
                Ref: c.ref,
                FullText: c.fullText,
                Label: c.label,
                Paragraphs: c.paragraphs.map(p => {
                    return {
                        Ref: p.ref,
                        FullText: p.fullText,
                        Sentences: p.sentences.map(s => {
                            return {
                                Ref: s.ref,
                                Text: s.text,
                                PrimaryMeaning: s.primaryMeaning,
                                Segments: s.segments
                            }
                        })
                    }
                })
            }
        })
    }
    return <>
        {showOptionsModal && <>
            <Modal OnClose={() => { setShowOptionsModal(false) }}>
                <ContentTile>
                    <ColumnLayout>
                        <StyledButton disabled={deleteButtonDisabled} onClick={() => { api.DeleteText(props.Ref).then(() => setDeleteButtonDisabled(true)) }}>Delete</StyledButton>
                    </ColumnLayout>
                </ContentTile>
            </Modal>
        </>}

        {
            displayView && <>
                <Modal OnClose={() => { setDisplayView(false) }}>
                    <TextStructureListenView TextRef={props.Ref} Close={() => { setDisplayView(false) }} Structure={textStructure.Chapters} />
                    {/* <ContentTile>
                        <ColumnLayout>
                            {fullText?.split("\n").map(c => { return <TextStyle size={1.25}>{c}</TextStyle> })}
                        </ColumnLayout>
                    </ContentTile>
 */}
                </Modal>
            </>
        }
        {studyView && <>
            <TextStructureStudyView Mode={'FromSingleText'} TextRef={props.Ref} OnClose={() => setStudyView(false)} />
        </>
        }
        <ColumnLayout style={{ paddingBottom: 24, marginBottom: 24, borderBottom: '1px solid #0000003f' }}>
            <TextStyle align='left' size={2} weight={'bold'}>{props.Label}</TextStyle>
            <TextProgressDisplay Ref={props.Ref} />
            <TextCalculatedGradeDisplay Ref={props.Ref} />
            <TextStyle align='left' size={1}>{fullText.substring(0, 30)}...</TextStyle>
            <RowLayout>
                <StyledButton onClick={() => { setDisplayView(!displayView) }}>Read</StyledButton>
                <StyledButton onClick={() => { setStudyView(!studyView) }}>Study</StyledButton>
                <div onClick={() => { setShowOptionsModal(true) }} style={{ width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 'auto' }}>
                    <OptionsIcon />
                </div>
            </RowLayout>
        </ColumnLayout>

        {/* <Collapse Label={props.Label} Collapsed={true}>

            <RowLayout>
                {/* {!props.Text.Focused && <StyledButton disabled={focusButttonDisabled}  onClick={() => db.Texts.update(props.Text.Id, { "Focused": true }).then(()=>setFocusButtonDisabled(true))} >Focus</StyledButton>}
                {props.Text.Focused && <StyledButton disabled={focusButttonDisabled} onClick={() => db.Texts.update(props.Text.Id, { "Focused": false }).then(()=>setFocusButtonDisabled(true))} >Unfocus</StyledButton>} }
        <StyledButton onClick={() => { setDisplayView(!displayView) }}>Display</StyledButton>
        <StyledButton onClick={() => { setReadView(!readView) }}>Assisted Read</StyledButton>
        <StyledButton onClick={() => { setListenView(!listenView) }}>Listen</StyledButton>
        <StyledButton onClick={() => { setStudyView(!studyView) }}>Study</StyledButton>
        <StyledButton onClick={() => { setToggleView(!toggleView) }}>View</StyledButton>
        <StyledButton disabled={deleteButtonDisabled} onClick={() => { api.DeleteText(props.Ref).then(() => setDeleteButtonDisabled(true)) }}>Delete</StyledButton>

    </RowLayout >

        { studyView && <>
            <TextStructureStudyView Mode={'FromSingleText'} TextRef={props.Ref} OnClose={() => setStudyView(false)} />
        </>
}

{
    readView && <>
        <Modal OnClose={() => setReadView(false)} >
            <AssistedReadView Close={() => setReadView(false)} Structure={textStructure.Chapters} />
        </Modal>
    </>

}

{
    listenView && <>
        <Modal OnClose={() => setListenView(false)}>


            <TextStructureListenView Close={() => setListenView(false)} Structure={textStructure.Chapters} />
        </Modal>
    </>
}
{
    displayView && <>
        <Modal OnClose={() => { setDisplayView(false) }}>
            <ContentTile>
                <ColumnLayout>
                    {fullText?.split("\n").map(c => { return <TextStyle size={1.25}>{c}</TextStyle> })}
                </ColumnLayout>
            </ContentTile>

        </Modal>
    </>
}

{
    toggleView && <>
        {textStructure.Chapters.map(c => {
            return <>
                {c.Paragraphs.map(p => {
                    return <Collapse Label={"Paragraph: " + p.FullText} Collapsed={true}>
                        {p.Sentences.map(s => {
                            return <>
                                <SentenceDisplay Sentence={s} />
                            </>
                        })}
                    </Collapse>
                })}
            </>
        })}
    </>
}

        </Collapse > */}

    </>
}

export const TextStructureStudyView: React.FC<{ Mode: 'FromSingleText' | 'SentenceBreakdown', OnClose: () => any, TextRef: string }> = props => {

    const [startStudy, setStartStudy] = useState(false)
    const [roundParams, setRoundParams] = useState<RoundControllerParams>(DefaultUnitControllerParams())
    const [parameters, setParameters] = useState<SelectUnitParameters>(new SelectUnitParameters())

    const whichUnit = props.Mode == 'FromSingleText' ? ZhLanguageUnitTypes.FromSingleText : ZhLanguageUnitTypes.SentenceBreakdown

    const StartStudy = () => {

        var x = JSON.parse(localStorage.getItem("selectItemParameters_" + props.Mode)!!)
        console.log(x)
        parameters.parameters = x;
        parameters.textRef = props.TextRef;
        console.log(parameters)
        setParameters(parameters)

        const settings: RoundControllerParams = {
            IntroduceNew: true,
            IntroduceCount: 10,
            RoundSize: 20,
            PromoteOnNth: 1,
            FailOnNth: 1,
            ShowUntilCorrect: true,
            ShuffleOnIncorrect: false,
            SaveResults: true,
            TimeLimit: 0,
            TestDefinitions: [],
            OrderRound: (item) => item
        }
        setRoundParams(settings)
        setStartStudy(true)
    }

    return <Modal OnClose={() => { props.OnClose() }}>
        <ContentTile>
            {!startStudy && <>

                <ColumnLayout>
                    <TextStyle>Settings</TextStyle>

                    <SelectItemParametersView
                        SetData={(x) => { localStorage.setItem("selectItemParameters_" + props.Mode, JSON.stringify(x)) }}
                        Data={{ ContextKey: "fromText", UnitTypes: [whichUnit], LanguageDefinition: ZhLanguageDefinition }} />

                    <StyledButton onClick={StartStudy}>Start</StyledButton>

                </ColumnLayout>
            </>}
            {startStudy && <>
                <LearnViewLoader
                    Data={{ ...roundParams, parameters: parameters }}
                    SetMenu={() => { }}
                    OnExit={() => { props.OnClose() }}
                />
            </>}
        </ContentTile>
    </Modal>
}

// export const LoadStudyUnitsView: React.FC<{ Params: RoundControllerParams, OnClose: () => any }> = props => {

//     const api = useContext(ApiContext)

//     const [nextUnits, setNextUnits] = useState<NextItemContainer | undefined>(undefined);
//     const [extraLdu, setExtraLdu] = useState<any | undefined>(undefined)
//     const [textRefMap, setTextRefMap] = useState<TextRefMap>({})

//     const [isSet, setIsSet] = useState(false)
//     useEffect(() => {
//         // if (!isSet) {

//         //     (() => { return api.AppUnits() })()
//         //         .then((resp) => {
//         //             if (resp.responseType == ResponseType.Success) {
//         //                 setExtraLdu(resp.object.units);
//         //                 UpdateUnitState(ZhLanguageConfiguration, resp.object.units)
//         //             }
//         //         })
//         //         .then(async () => {
//         //             const textRefs = await db.TextRefStore.toArray()
//         //             const TextRefMap: TextRefMap = {}
//         //             textRefs.map(tr => TextRefMap[tr.Ref] = tr)
//         //             setTextRefMap(TextRefMap)
//         //         })
//         //         .then(() => {
//         //             return ((text: TextRefType) => {
//         //                 var count = 0;
//         //                 ResolveSegments(text).map(s => {
//         //                     count += 1 // add the segment
//         //                     count += s.split("").length // add the number of base units
//         //                 })

//         //                 return ItemHistoryFromTextRefStructure(text, {
//         //                     Counts: {
//         //                         New: { 1: props.Params.IntroduceCount, 2: props.Params.IntroduceCount },
//         //                         Review: props.Params.RoundSize
//         //                     },
//         //                     OrderBy: OrderSelectedUnits.Frequency,
//         //                     TestDefinitions: props.Params.TestDefinitions
//         //                 })

//         //             })(props.Text)
//         //         })

//         //         .then(setNextUnits)
//         //         .then(() => setIsSet(true))
//         // }

//     }, [isSet, props.Text, nextUnits]);

//     return <>
//         {!isSet && <TextStyle>Loading...</TextStyle>}
//         {isSet && <LearnView
//             OnExit={() => { props.OnClose(); }}
//             Data={{
//                 Params: props.Params,
//                 NextUnits: nextUnits!!,
//                 LanguageConfiguration: ZhLanguageConfiguration,
//                 ExtraLanguageDefinition: extraLdu,
//                 TextRefMap: textRefMap,
//                 RoundRecords: []
//             }}
//             State={{
//                 UseSubUnit: false,
//                 SubUnitId: 0,
//                 RetryRound: false,
//                 RoundRecordId: 0
//             }}
//             SetMenu={(a: any) => { }}
//             Retry={(r) => { }}
//         />}
//     </>
// }
