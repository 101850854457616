import { Select } from "antd";
import { AppConfig } from "../../Types/AppConfig";
import { ApiException, DefaultApi, DictionaryQueryResult, HttpFile, IsomorphicFetchHttpLibrary, Messages, Query, SegmentSentenceRequest, SentenceSegmentationResult, ServerConfiguration, createConfiguration, KeyContainer, ApiKeyAuthentication, ProcessTextRequest, ProcessedTextStructure, GenerateTextRequest, GenerateAudioResponse, GenerateAudioRequest, UserGradeState, SelectedItems, SelectReviewByOption, SelectUnitParameters, ApiRoundRecord, BasicStatsResponse, BasicStatsQuery, DashboardResponse, TextEntry, GetSentencesWithWord200ResponseInner, EvaluateTranslationRequest2, TextProgressSummary, EvaluateMeaningRequest, TranslateSentenceRequest, ItemHistory, PairedItem, SentencePageQueryResponse, ProcessTextResponse, TextCategoryLink, UnifiedLookupQueryResponse, LookupQuery, SentenceResult, SentenceSelectionQuery, ScoreCard, ActionRecord, SentenceSelectionResult, BreakdownTextRequest, KeyRecord, KeyRecordStats, SentenceSearchKey, SentenceKeyLastSeen, RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats, SelectMistakesParameters, SelectLearningParameters, SentenceConstructionHelpRequest, CalculateSentenceDifficultyRequest, GetActionRecordRequest, Note, NoteRequest, GetNotesRequest, LibraryDashboardResponse } from "../../api";
import { ApiErrorType, ApiRequestStatus, ApiCall, ResponseType, RootApiContext, ApiEither, ApiError } from "./RootApiContext";


export async function ExecuteApi<ResultType>(call: (...params: any) => Promise<ResultType | undefined>, ...params: any): Promise<ApiEither<ResultType>> {
    return WrapCallWithApiResult(call, ...params).then(resp => resp)
}


export function UnwrapApiCall<T>(call: ApiEither<T>): T | undefined {
    if (call.responseType == ResponseType.Success) {
        return call.object;
    }
    return undefined;
}

export async function WrapCallWithApiResult<ResultType>(call: (...params: any) => Promise<ResultType | undefined>, ...params: any): Promise<ApiCall<ResultType>> {

    try {
        const response = await call(...params)

        console.log(response)

        if (response == undefined) {
            return {
                error: ApiErrorType.HttpOther,
                status: ApiRequestStatus.Error,
                responseType: ResponseType.Error
            }
        }

        return {
            object: response!!,
            status: ApiRequestStatus.Success,
            responseType: ResponseType.Success
        }

    } catch (e: any) {
        return {
            error: ApiErrorType.HttpOther,
            status: ApiRequestStatus.Error,
            responseType: ResponseType.Error
        }
    }

}

export class DefaultApiContext implements RootApiContext {

    Settings?: AppConfig

    Api?: DefaultApi

    applySettings: (settings?: AppConfig) => void = (settings) => {
        this.Settings = settings


        const key = localStorage.getItem("key")
        const conf = {
            baseServer: new ServerConfiguration(this.Settings?.ApiServer!!, {}),
            authMethods: { "api_key": new ApiKeyAuthentication(key ? key : "") },
            middleware: [],
            httpApi: new IsomorphicFetchHttpLibrary()
        }

        this.Api = new DefaultApi(conf)
    }

    setApiKey: (key: string) => void = (key) => {

        const conf = {
            baseServer: new ServerConfiguration(this.Settings?.ApiServer!!, {}),
            authMethods: { "api_key": new ApiKeyAuthentication(key) },
            middleware: [],
            httpApi: new IsomorphicFetchHttpLibrary()
        }

        this.Api = new DefaultApi(conf)
    }

    CheckKey: (key: string) => ApiCall<boolean> = async (key) => {
        return await WrapCallWithApiResult(async (key: string) => {
            const keyContainer = new KeyContainer()
            keyContainer.key = key
            return await this.Api?.checkKey(keyContainer)
        }, key)
    }

    // AppUnits: () => ApiCall<AppUnitsResponse> = async () => {
    //     return await ExecuteApi(async () => {
    //         return await this.Api?.getUnits()
    //     })
    // }

    AssistantChat: (messages: Messages) => ApiCall<string> = async (messages) => {
        return await WrapCallWithApiResult(async (req: SegmentSentenceRequest) => {
            return await this.Api?.assistantChat(messages)
        }, messages)

    }

    Lookup: (request: Query) => ApiCall<DictionaryQueryResult> = async (request) => {
        return await WrapCallWithApiResult(async (req: Query) => { return await this.Api?.lookupQuery(req) }, request)
    }

    Translate: (request: Query) => ApiCall<string> = async (request) => {
        return await WrapCallWithApiResult(async (req: Query) => { return await this.Api?.assistTranslate(req) }, request)
    }

    Explain: (request: Query) => ApiCall<string> = async (request) => {
        return await WrapCallWithApiResult(async (req: Query) => { return await this.Api?.assistExplain(req) }, request)
    }

    ProcessText: (request: ProcessTextRequest) => ApiCall<ProcessTextResponse> = async (request) => {
        return await WrapCallWithApiResult(async (req: ProcessTextRequest) => { return await this.Api?.processText(req) }, request)
    }

    GenerateText: (request: GenerateTextRequest) => ApiCall<ProcessedTextStructure> = async (request) => {
        return await WrapCallWithApiResult(async (req: GenerateTextRequest) => { return await this.Api?.generateText(req) }, request)
    }

    SegmentSentences: (request: SegmentSentenceRequest) => ApiCall<SentenceSegmentationResult> = async (request) => {
        return await WrapCallWithApiResult(async (req: SegmentSentenceRequest) => { return await this.Api?.segmentSentences(req) }, request)
    }

    EvaluateImage: (image: HttpFile) => ApiCall<SentenceSegmentationResult> = async (image) => {
        return await WrapCallWithApiResult(async (image: HttpFile) => { return await this.Api?.evaluateImage(image) }, image)
    }

    EvaluatePronunciation: (id: string, blob: Blob) => ApiCall<any> = async (id, blob) => {
        return await WrapCallWithApiResult(async (id: string, blob: Blob) => {
            // @ts-expect-error
            return await this.Api?.evaluatePronunciation(id, blob)
        }, id, blob)
    }

    GenerateAudio: (text: string) => ApiCall<GenerateAudioResponse> = async (text: string) => {
        return await WrapCallWithApiResult(async (text: string) => {
            var request = new GenerateAudioRequest()
            request.text = text
            return await this.Api?.generateAudio(request)
        }, text)
    }

    GradeState: () => ApiCall<UserGradeState[]> = async () => {
        return await WrapCallWithApiResult(async () => {
            return await this.Api?.viewGrades()
        })
    }

    SelectItems: (parameters: SelectUnitParameters) => ApiCall<SelectedItems> = async (parameters) => {
        return await WrapCallWithApiResult(async (parameters: SelectUnitParameters) => {
            return await this.Api?.selectUnits(parameters)
        }, parameters)
    }

    FinishRound: (roundRecord: ApiRoundRecord) => ApiCall<ApiRoundRecord> = async (roundRecord: ApiRoundRecord) => {
        return await WrapCallWithApiResult(async (roundRecord: ApiRoundRecord) => {
            return await this.Api?.finishRound(roundRecord)
        }, roundRecord)
    }

    BasicStats: (StartDate: Date, EndDate: Date) => ApiCall<BasicStatsResponse> = async (StartDate: Date, EndDate: Date) => {
        return await WrapCallWithApiResult(async (StartDate: Date, EndDate: Date) => {

            const basicStatsRequest = new BasicStatsQuery()
            basicStatsRequest.startDate = StartDate
            basicStatsRequest.endDate = EndDate

            return await this.Api?.basicStats(basicStatsRequest)
        }, StartDate, EndDate)
    }

    Dashboard: () => ApiCall<DashboardResponse> = async () => {
        return await WrapCallWithApiResult(async () => {
            return await this.Api?.dashboard()
        })
    }

    GetTexts: () => ApiCall<TextEntry[]> = async () => {
        return await WrapCallWithApiResult(async () => { return await this.Api?.getTexts() })
    }

    GetText: (ref: string) => ApiCall<ProcessedTextStructure> = async (ref) => {
        return await WrapCallWithApiResult(async (ref: string) => { return await this.Api?.getText(ref) }, ref)
    }

    DeleteText: (ref: string) => ApiCall<void> = async (ref) => {
        return await WrapCallWithApiResult(async (ref: string) => { return await this.Api?.deleteText(ref) }, ref)
    }

    SentencesWithWord: (word: string) => ApiCall<Array<GetSentencesWithWord200ResponseInner>> = async (word) => {
        return await WrapCallWithApiResult(async (word: string) => { return await this.Api?.getSentencesWithWord(word) }, word)
    }

    EvaluateTranslation: (reference: string, translation: string) => ApiCall<number> = async (reference, translation) => {
        const request = new EvaluateTranslationRequest2()
        request.reference = reference
        request.translation = translation
        return await WrapCallWithApiResult(async (request: EvaluateTranslationRequest2) => { return await this.Api?.evaluateTranslation2(request) }, request)
    }

    ReviewsDueToday: () => ApiCall<Record<number, number>> = async () => {
        return await WrapCallWithApiResult(async () => { return await this.Api?.reviewsDueToday() })
    }

    GradeText: (ref: string) => ApiCall<Record<string, number>> = async (ref) => {
        return await WrapCallWithApiResult(async (ref: string) => { return await this.Api?.gradeText(ref) }, ref)
    }

    CalculateTextProgress: (ref: string) => ApiCall<TextProgressSummary> = async (ref) => {
        return await WrapCallWithApiResult(async (ref: string) => { return await this.Api?.calculateTextProgress(ref) }, ref)
    }

    EvaluateMeaning: (request: EvaluateMeaningRequest) => ApiCall<number> = async (request) => {
        return await WrapCallWithApiResult(async (request: EvaluateMeaningRequest) => { return await this.Api?.evaluateMeaning(request) }, request)
    }

    LoadSentencePrimaryText: (ref: string) => ApiCall<string> = async (ref) => {
        const request = new TranslateSentenceRequest()
        request.ref = ref
        return await WrapCallWithApiResult(async (request: TranslateSentenceRequest) => { return await this.Api?.assistTranslateSentence(request) }, request)
    }

    ViewItemByGrade: (options: { unitId: number, itemType: number, grade: number }) => ApiCall<Record<string, PairedItem>> = async (options) => {
        return await WrapCallWithApiResult(async (options: { unitId: number, itemType: number, grade: number }) => { return await this.Api?.itemViewByGrade(options.unitId, options.itemType, options.grade) }, options)
    }

    SentencePage: (query: { count: number, page: number }) => ApiCall<SentencePageQueryResponse> = async (query) => {
        return await WrapCallWithApiResult(async (query: { count: number, page: number }) => { return await this.Api?.getSentencesByUnitOrder(query.count, query.page) }, query)
    }

    SentenceSelection: (query: SentenceSelectionQuery) => ApiCall<SentenceSelectionResult> = async (query) => {
        return await WrapCallWithApiResult(async (query: SentenceSelectionQuery) => { return await this.Api?.getSentencesBySelection(query) }, query)
    }

    GetTextCategoryLinks: () => ApiCall<TextCategoryLink[]> = async () => {
        return await WrapCallWithApiResult(async () => { return await this.Api?.getTextCategoryLinks() })
    }

    GetTextCategories: () => ApiCall<string[]> = async () => {
        return await WrapCallWithApiResult(async () => { return await this.Api?.getTextCategories() })
    }

    UnifiedLookup: (query: string) => ApiCall<UnifiedLookupQueryResponse> = async (query) => {

        const request = new LookupQuery()
        request.query = query
        return await WrapCallWithApiResult(async (request: LookupQuery) => { return await this.Api?.unifiedLookup(request) }, request)
    }

    ScoreCard: () => ApiCall<ScoreCard> = async () => {
        return await WrapCallWithApiResult(async () => { return await this.Api?.calculateScoring() })
    }

    BreakdownText: (request: BreakdownTextRequest) => ApiCall<SentenceResult> = async (request) => {
        return await WrapCallWithApiResult(async (request: BreakdownTextRequest) => { return await this.Api?.breakdownText(request) }, request)
    }
    ProcessActionRecords: (records: ActionRecord[]) => ApiCall<boolean> = async (records) => {
        return await WrapCallWithApiResult(async (records: ActionRecord[]) => { return await this.Api?.processActionRecord(records) }, records)
    }

    SearchKeys: (query: SentenceSelectionQuery) => ApiCall<string[]> = async (query) => {
        return await WrapCallWithApiResult(async (query: SentenceSelectionQuery) => { return await this.Api?.getSearchKeys(query) }, query)
    }

    GradeSentenceConstruction: (reference: string, text: string) => ApiCall<number> = async (reference, text) => {
        const request = {
            reference: reference,
            text: text
        }
        return await WrapCallWithApiResult(async (request: { reference: string, text: string }) => { return await this.Api?.assistGradeSentenceConstruction(request) }, request)
    }

    KeyRecords: (query: SentenceSelectionQuery) => ApiCall<KeyRecord[]> = async (query) => {
        return await WrapCallWithApiResult(async (query: SentenceSelectionQuery) => { return await this.Api?.getKeyRecords(query) }, query)
    }

    SentenceKeyRecordStats: () => ApiCall<RecordSentenceSearchKeyRecordSentenceKeyLastSeenKeyRecordStats> = async () => {
        return await WrapCallWithApiResult(async () => { return await this.Api?.sentenceKeyRecordStats() })
    }

    SelectMistakes: (parameters: SelectMistakesParameters) => ApiCall<SelectedItems> = async (parameters) => {
        return await WrapCallWithApiResult(async (parameters: SelectMistakesParameters) => { return await this.Api?.getMistakes(parameters) }, parameters)
    }

    SelectCurrentLearning: (parameters: SelectLearningParameters) => ApiCall<SelectedItems> = async (parameters) => {
        return await WrapCallWithApiResult(async (parameters: SelectLearningParameters) => { return await this.Api?.selectCurrentLearning(parameters) }, parameters)
    }

    SentenceConstructionHelp: (sentence: string, intention: string) => ApiCall<string> = async (sentence, intention) => {
        const request = new SentenceConstructionHelpRequest()
        request.sentence = sentence
        request.intention = intention
        return await WrapCallWithApiResult(async (request: SentenceConstructionHelpRequest) => { return await this.Api?.assistSentenceConstructionHelp(request) }, request)
    }

    CalculateSentenceDifficulty: (sentence: string) => ApiCall<number> = async (sentence) => {
        const request = new CalculateSentenceDifficultyRequest()
        request.sentence = sentence
        return await WrapCallWithApiResult(async (request: CalculateSentenceDifficultyRequest) => { return await this.Api?.calculateSentenceDifficulty(request) }, request)
    }

    GetActionRecords: (request: GetActionRecordRequest) => ApiCall<ActionRecord[]> = async (request) => {
        return await WrapCallWithApiResult(async (request: GetActionRecordRequest) => { return await this.Api?.getActionRecords(request) }, request)
    }

    AddNote: (note: NoteRequest) => ApiCall<boolean> = async (note) => {
        return await WrapCallWithApiResult(async (note: NoteRequest) => { return await this.Api?.addNote(note) }, note)
    }

    GetNotes: (key: string) => ApiCall<Note[]> = async (key) => {

        const request = new GetNotesRequest()
        request.key = key
        return await WrapCallWithApiResult(async (request: GetNotesRequest) => { return await this.Api?.getNotes(request) }, request)
    }

    LibraryDashboard: () => ApiCall<LibraryDashboardResponse> = async () => {
        return await WrapCallWithApiResult(async () => { return await this.Api?.libraryDashboard() })
    }

    AllNotes: () => ApiCall<Note[]> = async () => {
        return await WrapCallWithApiResult(async () => { return await this.Api?.allNotes() })
    }

    GetItemHistory: (id: number) => ApiCall<ItemHistory> = async (id) => {
        return await WrapCallWithApiResult(async (id: number) => { return await this.Api?.getItemHistory(id) }, id)
    }
}