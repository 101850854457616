/**
 * main-srv
 * primary server for app
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class SelectLearningParameters {
    'testDefinitions': Array<number>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "testDefinitions",
            "baseName": "TestDefinitions",
            "type": "Array<number>",
            "format": "double"
        }    ];

    static getAttributeTypeMap() {
        return SelectLearningParameters.attributeTypeMap;
    }

    public constructor() {
    }
}

